import React, {useState} from "react";
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { DateRange } from 'react-date-range';
import TranslateComponent from "../TranslateComponent";
const PopUpDate = ({
type,
setState,
state,
setSelectedMonthCount,
selectedMonthCount,
setStartDate,
StartDate
}) =>{

    const MonthNumbers=12;
    const MonthArray = Array.from({ length: MonthNumbers }, (_, i) => i + 1);
    const DaysArray = Array.from({ length: 28 }, (_, i) => i + 1);
    const [ForMothnNmber,setForMothnNmber]=useState(false);
    return (
        <div className=' flex flex-col items-center'>
            <div className="grid grid-cols-1">
                            <div className=" lg:col-span-1 mt-5 " dir={`${type ==='daily' ?'ltr':''}`}>
                                {type === 'daily' && (
                                    <DateRange
                                        minDate={new Date()}
                                        editableDateInputs={true}
                                        onChange={item => setState([item.selection])}
                                        moveRangeOnFirstSelection={false}
                                        ranges={state}
                                    />
                                )}
                                {type === 'monthly' && (
                                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                                        <div className="lg:col-span-1 mt-auto">
                                            <label className="my-2"> <TranslateComponent word={'Choose a start date'}/> </label>
                                            <select className="select select-bordered w-full max-w-xs"
                                                    value={StartDate}
                                                    onChange={e => setStartDate(e.target.value)}
                                            >
                                                <option>

                                                <TranslateComponent word={'Choose a start date'}/>
                                                </option>
                                                {DaysArray.map(day => (
                                                    <option key={day} value={day} className="bg-white hover:bg-gray-100 rounded-md transition duration-300">
                                                        <div >
                                                            <div >
                                                                {day}
                                                                 <TranslateComponent word={'From every month'}/>

                                                            </div>
                                                        </div>
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="col-span-1">
                                            <div>
                                                <label
                                                    className="inline-block pl-[0.15rem] hover:cursor-pointer my-2"
                                                    htmlFor="flexSwitchCheckedSms"
                                                >
                                                                 <TranslateComponent word={'Choose the number of months'}/>


                                                </label>
                                                <input
                                                    type="checkbox"
                                                    checked={ForMothnNmber}
                                                    onChange={(e) => {
                                                        setForMothnNmber(e.target.checked);
                                                        setSelectedMonthCount('');
                                                    }}
                                                    className="checkbox checkbox-primary mx-2"
                                                    id="flexSwitchCheckedSms"
                                                />
                                            </div>
                                                <select className="select select-bordered w-full max-w-xs"
                                                        value={selectedMonthCount}
                                                        onChange={e => setSelectedMonthCount(e.target.value)}
                                                        disabled={!ForMothnNmber}
                                                >
                                                    <option>
                                                    <TranslateComponent word={'Choose the number of months'}/>

                                                    </option>
                                                    {MonthArray.map(day => (
                                                        <option key={day} value={day}> {day}</option>
                                                    ))}
                                                </select>
                                        </div>
                                    </div>
                                )}
                            </div>
        </div>
        </div>
    );
}
export default PopUpDate;