
import { Helmet } from 'react-helmet';
import axiosClient, { getLocalLang } from '../axios-client';
import { useState,useEffect } from 'react';
import {useSelector} from "react-redux";
import { translateText } from './TranslateComponent';
const SEOComponent=({
  title,
  description,
  image,
})=>{
  const translations = useSelector((state) => state.Storetranslate.translations);
  const [country, setCountry] = useState('');
  const footerData  =useSelector(state=>state.StoreFooter.data);
  const haderData = useSelector(state=>state.StoreHeader.Headers);
  // const currentUrl = decodeURIComponent(window.location.href);
  const currentUrl = window.location.href;
  const currentYear = new Date().getFullYear();

  const fetchCountry = async () => {
    try {
      const response = await axiosClient.get('https://ipapi.co/json/');
      setCountry(response.data.country_name);
    } catch (err) {
        console.log(err);
    }
  };
  useEffect(() => {
    fetchCountry();
  }, []);
  return(
      <Helmet>
        <html lang={getLocalLang()&&getLocalLang().code} />
        <title>{title==='الخير'?title :title?'الخير'+' | '+title:translateText('Al Khair Foundation',translations)}</title>
        <meta charset='UTF-8'/>
        <link rel="canonical" href={currentUrl} />
        <meta name="description" content={description??translateText('Al-Khair Voluntary Foundation',translations) }/>
        <meta property="og:image" content={image??haderData.logo_header} />
        <meta name="twitter:title" content={title?title:translateText('Al Khair Foundation',translations)} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={haderData.logo_header} />
        <meta name='subject' content={title?title:translateText('Al Khair Foundation',translations)}/>
        <meta name='copyright' content={currentYear}/>
        <meta name='language' content={getLocalLang()?getLocalLang().code.toUpperCase():'AR'}/>
        <meta name='url' content={currentUrl}/>
        <meta itemprop='name' content={haderData?.og_site_name??translateText('Al Khair Foundation',translations)}/>
        <meta property="og:fb" content={title?title:translateText('Al Khair Foundation',translations)} />
        <meta property="og:title" content={title?title:translateText('Al Khair Foundation',translations)} />
        <meta property="og:type" content={title?title:translateText('Al Khair Foundation',translations)} />
        <meta property="og:description" content={description??translateText('Al-Khair Voluntary Foundation',translations)} />
        <meta property="og:url" content={currentUrl} />
        <meta name='og:site_name' content={haderData?.og_site_name??translateText('Al Khair Foundation',translations)}/>
        <meta name='fb:page_id' content={haderData?.fb_page_id}/>
        <meta name='application-name' content={haderData?.og_site_name??translateText('Al-Khair Voluntary Foundation',translations)}/>
        <meta name='og:email' content={footerData.data?.email}/>
        <meta name='og:phone_number' content={footerData.data?.phone}/>
        <meta name='og:country-name' content={country}/>

  {/* <meta itemprop="name" content="istanbbule düğün ve nişan elbiseleri - Şirketsepeti"/>
  <meta itemprop="description" content="istanbbule, Türk tasarımı düğün ve nişan elbiseleri satışında uzmandır ve tüm dünyaya kargo ve teslimat hizmeti sunar - Şirketsepeti "/>
  <meta itemprop="image" content="https://www.sirketsepeti.net/client/2/istanbbule.png"/>

  <meta name="twitter:site" content="@publisher_handle"/>
  <meta name="twitter:title" content="istanbbule düğün ve nişan elbiseleri - Şirketsepeti"/>
  <meta name="twitter:description" content="istanbbule, Türk tasarımı düğün ve nişan elbiseleri satışında uzmandır ve tüm dünyaya kargo ve teslimat hizmeti sunar - Şirketsepeti"/>
  <meta name="twitter:creator" content="@author_handle"/>
  <meta name="twitter:image" content="https://www.sirketsepeti.net/client/2/istanbbule.png"/>


  <meta property="og:title" content="istanbbule düğün ve nişan elbiseleri - Şirketsepeti"/>
  <meta property="og:url" content="https://www.sirketsepeti.net/tr/client/istanbbule%20d%C3%BC%C4%9F%C3%BCn%20ve%20ni%C5%9Fan%20elbiseleri - Şirketsepeti"/>
  <meta property="og:image" content="https://www.sirketsepeti.net/client/2/istanbbule.png"/>
  <meta property="og:description" content="istanbbule, Türk tasarımı düğün ve nişan elbiseleri satışında uzmandır ve tüm dünyaya kargo ve teslimat hizmeti sunar - Şirketsepeti"/>
  <meta property="og:site_name" content="Şirketsepeti - Türkiye Şirket Rehberi"/>
    <meta property="article:modified_time" content="2024-06-13 07:47:07"/> */}

      </Helmet>
  )
}
export default SEOComponent;