import PopupCopmponent from "./PaymentSteps/PopupCopmponent";
import React, {useState} from "react";
import {getCurrencyLocal} from "../axios-client";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";
import TranslateComponent, { translateText } from "./TranslateComponent";
import {useSelector} from "react-redux";
const HamleComponent = ({orphan,index}) =>{
    let priceOrphan=50*getCurrencyLocal()?.value??1

    const translations = useSelector((state) => state.Storetranslate.translations);
    const [price ,setPrice]=useState('');
    const [showPopup , setShowPopup] = useState(false);
    const [pricePay,setPricePay]=useState(priceOrphan);
    const [cardStatus,setCardStatus]=useState(false);

    const handelChangePrice = (e)=>{
        const {value} = e.target;
        setPrice(value);
        if(value>=50){
            setShowPopup(true);
            localStorage.setItem('price',value);
            dispatchEvent(new Event('priceChanged'));
       }
    }
    const forPay=(pricePay)=>{
        if(pricePay){
            if(pricePay<50){
                toast.error(translateText('The minimum donation is 50',translations));
                return;
            }
            setPricePay(pricePay)
        }else{
            toast.error(translateText('Please enter the donation amount',translations));
        }
    }
    window.addEventListener('removeForPayDirect',()=>{
        setPrice('');
    })
    const handelActive =()=>{
        setCardStatus(!cardStatus);
    }
    return (
        <>


        <div
            key={index}
            className={`CardOrphan ${cardStatus === true ? 'active' : ''}`}>
            <div className={'col-span-1'}>
                <div className={'slide slide1'} role={'button'} onClick={handelActive}>
                    <div className={'content'}>
                        <div
                            role={'button'}
                            onClick={handelActive}
                            key={index}
                            className=" col-span-1 overflow-x-auto h-[27.25rem] p-5 max-w-sm rounded-2xl bg-neutral-50">

                            <div className="mt-5 text-2xl font-bold text-center text-neutral-900">
                                {orphan.name}
                            </div>
                            <div className="mt-5 text-sm text-center text-zinc-600">
                                <div dangerouslySetInnerHTML={{__html: orphan.description}}/>
                            </div>
                            <img
                                // role={'button'}
                                // onClick={toDetails}
                                alt= {orphan.name}
                                loading="lazy"
                                srcSet={orphan.image}
                                className="mt-5 w-full blur aspect-[1.37]"
                            />

                            <div className="self-center mt-5 text-base font-bold text-right text-stone-900">
                                {orphan.age} <TranslateComponent word={'the age'}/>
                            </div>
                            {cardStatus ? (
                                <div className="mt-5 text-base font-bold text-right text-stone-900">
                                    <TranslateComponent word={'Click to close details'}/>
                                </div>
                            ): (
                                <div className="mt-5 text-base font-bold text-right text-stone-900">
                                    <TranslateComponent word={'Click for more details'}/>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className={'col-span-1'}>
                <div className="slide slide2 ">
                <div className="content">
                        <div
                            role={'button'}
                            onClick={handelActive}>
                            <div className="self-center text-sm text-center text-blue-900">
                                {orphan.country}
                            </div>
                            <div className="mt-5 text-2xl font-bold text-center text-neutral-900">
                                {orphan.name}
                            </div>
                            <div className="mt-5 text-sm text-center text-zinc-600">
                                <div dangerouslySetInnerHTML={{__html: orphan.description}}/>
                            </div>
                            <img
                                // role={'button'}
                                // onClick={toDetails}
                                loading="lazy"
                                srcSet={orphan.image}
                                className="mt-5 w-full blur aspect-[1.37]"
                            />

                            <div className="self-center mt-5 text-base font-bold text-right text-stone-900">
                                {orphan.age} <TranslateComponent word={'the age'}/>
                            </div>

                        </div>

                        <div className="flex gap-2 mt-3.5">
                            <div
                                className="flex flex-1 gap-2 justify-between py-1 pr-2 pl-1 whitespace-nowrap bg-white rounded-lg border border-gray-300 border-solid">
                                <div
                                    role="button"
                                    onClick={() => {
                                        showPopup && (document.getElementById('my_modal_4_orphan').showModal())
                                        forPay(price)
                                    }}
                                    className="justify-center px-3.5 py-2 text-sm font-semibold leading-5 text-white bg-blue-600 rounded-lg border border-blue-500 border-solid shadow-sm">


                                    <TranslateComponent word={'Sponsor me'}/></div>
                                <div className="my-auto text-base leading-6 text-right text-gray-500">
                                    <input
                                        disabled={true}
                                        min={priceOrphan}
                                        className=" w-full outline-none text-lg indent-1 m-1"
                                        value={priceOrphan}
                                        type="number" placeholder={translateText("The value of the current",translations)} onChange={handelChangePrice}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <></>
                </div>
            </div>
            {showPopup && (
                <PopupCopmponent type={'orphan'} setShowPopup={setShowPopup} projectId={orphan.id} price={pricePay}
                                 symbol={getCurrencyLocal().name} handelChangePrice={handelChangePrice}/>
            )}
        </div>
        </>


    )
}
export default HamleComponent;