import React, {useEffect, useState} from 'react';
import axiosClient, {getCurrencyLocal, getToken} from "../../axios-client";
import {CartIcon, GreenTrue} from "../../Images/svgs";
import PopupCopmponent from "../PaymentSteps/PopupCopmponent";
import {toast} from "react-toastify";
import {fetchCreateCart} from "../Cart/Cart";
import {useDispatch} from "react-redux";
import LocalCart from "../Cart/LocalCart";
import {LazyLoadImage} from "react-lazy-load-image-component";
import TranslateComponent, { translateText } from '../TranslateComponent';
import { useSelector } from 'react-redux';
const PublicProjectOrphans = () => {
    const translations = useSelector((state) => state.Storetranslate.translations);
    const [taps, setTaaps] = useState([]);
    const donationAmounts = [50, 100, 200, 400, '$'];
    const [activeDonation, setActiveDonation] = useState(null);
    const [donation, setDonation] = useState();
    const getTaps = () => {
        axiosClient.get('/orphans/public-project').then(response => {
            setTaaps(response.data.data)
            setCoosedTab(response.data.data[0]);
        })
            .catch(error => {
                console.log(error)
            })
    }
    useEffect(() => {
        getTaps();
    }, []);
    const [chosedTab, setCoosedTab] = useState('');
    const choosetap = (tap) => {
        setCoosedTab(taps.find((item) => item.id === tap));
    }
    const [price, setPrice] = useState('');
    const [showPopup, setShowPopup] = useState(false);
    const handelChangePrice = (e) => {
        const {value} = e.target;
        setPrice(value);
        setShowPopup(true);
        setType('publicProjects');

        localStorage.setItem('price', value);
        dispatchEvent(new Event('priceChanged'));
    }

    const handleDonation = (value) => {
        setDonation(value)
        setActiveDonation(value);
        setPrice(value);
        setShowPopup(true);

    }

    const [pricePay, setPricePay] = useState('');
    const [type, setType] = useState('');
    const forPay = (pricePay) => {
        // eslint-disable-next-line no-mixed-operators
        if (getToken() && getToken() !== '' || getToken() !== null) {
            showPopup && (document.getElementById('my_modal_4_publicProjects').showModal())
            if (pricePay) {
                setPricePay(pricePay)
            } else {
                toast.error(translateText('Please enter the donation amount',translations));
            }
        } else {
            toast.info(translateText('You must be logged in',translations))
        }
    }
    window.addEventListener('removeForPayDirect', () => {
        setPrice('');
    })
    const {createCart} = LocalCart();
    const dispatch = useDispatch();
    const handelCreateCart = async (type, cart_id, price) => {
        if (price) {
            await dispatch(fetchCreateCart(type, cart_id, price));
        } else {
            toast.error('Enter the amount to donate')
        }
    }
    const handelCreateCartLocal = (type, cart_id, price, symbol, image) => {
        if (price) {
            createCart(type, cart_id, price, symbol, image);
        } else {
            toast.error('Enter the amount to donate')
        }
    }
    return (
        <>
            <div>
                <div className="container mx-auto py-5 px-2">

                    <div
                        className="flex justify-center items-center px-16 py-12 mt-16 w-full bg-blue-900 rounded-2xl border-white border-solid border-[13px] max-md:px-5 max-md:mt-10 max-md:max-w-full">
                        <div className="justify-center w-full max-w-[980px] max-md:max-w-full">

                            <ul className={`grid  justify-center mb-3 md:grid-cols-${taps.length} `}
                                role="tablist">
                                {taps.map(tab => (
                                    <li key={tab.id}
                                        className={  `nav-item   ${chosedTab?.id === tab.id ? 'bg-amber-300 border-b-[5px] col-span-1 border-b-yellow-600 text-white ' : 'border border-blue-600 bg-white  border-b-[5px] col-span-1  text-black'} rounded-2xl font-medium p-1 mx-1 my-2   flex flex-col items-center justify-center lg:w-[10rem]`
                                        }
                                        role="presentation"
                                    >
                                        <button
                                            className="nav-link flex flex-col items-center"
                                            data-bs-toggle="tab"
                                            data-bs-target="#project-tab3"
                                            aria-selected="false"
                                            role="tab"
                                            onClick={() => choosetap(tab.id)}
                                            tabIndex={-1}
                                        >
                                            <div className="icon mb-3">
                                                <LazyLoadImage
                                                    src={tab.mainImage}
                                                    alt={tab.subName}
                                                    className="w-20 md:w-24 h-24 object-cover"
                                                />
                                            </div>
                                            <h4 className=" text-center font-bold">{tab.name}</h4>
                                        </button>
                                    </li>
                                ))}
                            </ul>

                            <div className="flex gap-5 max-md:flex-col max-md:gap-0 max-md:">
                                <div className="flex flex-col ml-5 w-3/5 max-md:ml-0 max-md:w-full">
                                    <div
                                        className="flex flex-col grow justify-center items-center self-stretch px-20 py-7 text-white max-md:px-5 max-md:mt-5 max-md:max-w-full">
                                        <LazyLoadImage
                                            loading="lazy"
                                            src={chosedTab?.subImage}
                                            className="w-44 max-w-full aspect-[0.87]"
                                             alt={chosedTab?.subName}
                                        />
                                        <div className="mt-6 text-5xl font-bold text-right whitespace-nowrap">
                                            {chosedTab?.subName}
                                        </div>
                                        <div
                                            className="self-stretch mt-6 text-base leading-7 text-center max-md:max-w-full">
                                            <div dangerouslySetInnerHTML={{__html: chosedTab.description}}/>

                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-col w-2/5 max-md:ml-0 max-md:w-full">
                                    <div
                                        className="flex flex-col grow items-start p-10 mx-auto w-full text-sm font-medium bg-white rounded-2xl max-md:px-5 max-md:mt-5">
                                        <div className="text-5xl font-bold text-right whitespace-nowrap text-zinc-900">
                                          <TranslateComponent word={'donation'}/>
                                        </div>
                                        <div className="mt-6 text-base whitespace-nowrap text-slate-700">

                                          <TranslateComponent word={'Choose the amount'}/>
                                        </div>
                                        <div
                                            className="flex gap-2.5 justify-start self-stretch mt-4 font-bold text-right whitespace-nowrap text-neutral-900">
                                            {donationAmounts.map((amount, index) => (
                                                <button
                                                    key={index}
                                                    onClick={() => handleDonation(amount)}
                                                    className={`justify-center px-${amount === '$' ? '6' : '3.5'} py-2.5 first:px-4 ${amount === activeDonation ? 'text-white bg-blue-900' : 'bg-white'
                                                    } rounded-lg border-solid aspect-[1.3] border-[0.8px] border-[color:var(--Primary-color,#274182)]`}
                                                >
                                                    <span className="relative z-10">{amount}</span>

                                                </button>
                                            ))}
                                        </div>
                                        <div className="mt-6 text-base whitespace-nowrap text-slate-700">
                                             <TranslateComponent word={'Enter the donation amount'}/>
                                        </div>
                                        <div
                                            className="flex gap-5 justify-between self-stretch px-3.5 py-2.5 mt-1.5 text-right bg-white rounded-lg border border-gray-300 border-solid shadow-sm">
                                            <input
                                                className="text-black outline-none"
                                                value={price}
                                                type="number"
                                                pattern="[0-9]*"
                                                inputMode="numeric"
                                                placeholder="00"
                                                onChange={handelChangePrice}
                                            />
                                            <div className="text-zinc-800">$</div>
                                        </div>
                                        <div className="flex ">
                                            <button
                                                onClick={() => {

                                                    forPay(price)
                                                }}
                                                className="justify-center px-8 py-2 mt-6 whitespace-nowrap bg-amber-300 rounded-lg shadow-sm leading-[143%] text-neutral-900 max-md:px-5">
                                               <TranslateComponent word={'Send your donation'}/>
                                            </button>
                                            <div data-ripple-dark="true" data-popover-target="profile-info-popover"
                                                 className="flex justify-center items-center mx-2 mt-6 px-3.5 py-2 bg-white rounded-lg border border-gray-300 shadow-sm cursor-pointer"
                                                 onClick={() => {
                                                     if (getToken()) {
                                                         handelCreateCart('publicProjects', chosedTab?.id, price);
                                                     } else {
                                                         handelCreateCartLocal('publicProjects', chosedTab?.id, price, getCurrencyLocal().name, chosedTab?.mainImage);
                                                     }
                                                 }}
                                            >
                                                <CartIcon/>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            {showPopup && (
                <PopupCopmponent type={type} setShowPopup={setShowPopup} projectId={chosedTab.id} price={pricePay}
                                 symbol={chosedTab.symbol} handelChangePrice={handelChangePrice}/>
            )}

        </>
    );


}

export default PublicProjectOrphans;