import React, {useEffect, useState} from "react";
import { LazyLoadImage } from 'react-lazy-load-image-component'
import GalleryDeatailsPopup from "./GalleryDeatailsPopup";
import axiosClient from "../../axios-client";
import TranslateComponent from "../TranslateComponent";
import { useNavigate } from "react-router-dom";

const Galleries =({
  data,
  firstThree,
  remaining,
  loadData,
  setFirstThree,
  setRemaining,
  setLoadData,
  setLoader,
  getGalleries,
  activeSection,
  lastGalley,
  isPageHome
})=>{

const [OpenStatus,setOpenStatus]=useState(false);
const [gallery,setSinglegallery]=useState('');
const [loader,setLodaer]=useState(false);

    useEffect(() => {
        if (data.length > 0) {
            setFirstThree(data.slice(0, 3));
            const remainingData = data.slice(3);
            setRemaining(remainingData);
            setLoader(false);
        } else {
            if (loadData) {
                getGalleries(1);
                setLoadData(false);
            }
        }
    }, [loadData,data,activeSection]);


    const ChangePopupStatus=()=>{
        setOpenStatus(!OpenStatus)
    }
    const getSingleGallery=(id)=>{
        setLodaer(true);
        axiosClient.get(`gallery/${id}`)
            .then(response=>{
                setSinglegallery(response.data.data.attachment)
                setLodaer(false);
            })
            .catch(error=>{
                setLodaer(false);
                console.log(error)
            })
    }
    const navigate =useNavigate();
    const toMore=()=>{
        navigate('more-gallery')
    }
    return (
        <div className={'my-5'}>
            {firstThree.length > 0 && (
                <div className={'grid grid-cols-1 lg:grid-cols-3 gap-4 my-2'}>
                    {firstThree && firstThree.map((item, index) => (
                        <div key={index} className="col-span-1  h-[300px]">
                            <div
                                className="flex overflow-hidden relative flex-col grow justify-center items-center h-[300px] max-md:px-5 max-md:pt-10 max-md:mt-5">
                                <LazyLoadImage
                                    loading="lazy"
                                    srcSet={item.main_imag}
                                    className="object-cover absolute rounded-md h-[300px] w-full inset-0"
                                    alt={'main_imag'}
                                />
                                <div
                                    role={'button'}
                                    onClick={()=> {
                                        ChangePopupStatus();
                                        getSingleGallery(item.id)
                                    }}
                                    className={'mt-4 relative h-[100%] lg:w-[100%] w-[115%] text-white font-bold flex items-end justify-center pb-[30px] rounded-md bg-gradient-to-b from-transparent to-[#000000ab] hover:bg-gradient-to-b hover:from-transparent hover:to-[#051a46]  '}>
                                    {item.translate.name}

                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            )}
            {/*for reminig*/}
            {remaining.length > 0 && (
                <div className={'grid grid-cols-2 lg:grid-cols-4 gap-3 my-2'}>
                    {remaining.map((item, index) => (
                        <div key={index} className="col-span-1  h-[300px]">
                            <div
                                className="flex overflow-hidden relative flex-col grow justify-center items-center h-[300px] max-md:px-5 max-md:pt-10 max-md:mt-5">
                                <LazyLoadImage
                                    loading="lazy"
                                    srcSet={item.main_imag}
                                    className="object-cover absolute rounded-md h-[300px] w-full inset-0"
                                    alt={'main_imag'}
                                />

                                <div
                                    role={'button'}
                                    onClick={()=> {
                                        ChangePopupStatus();
                                        getSingleGallery(item.id)
                                    }}
                                    className={'mt-4 relative h-[100%] lg:w-[100%] w-[135%] text-white font-bold flex items-end justify-center pb-[30px] rounded-md bg-gradient-to-b from-transparent to-[#000000ab] hover:bg-gradient-to-b hover:from-transparent hover:to-[#051a46]  '}>
                                    {item.translate.name}

                                </div>
                            </div>
                        </div>
                    ))}
                    {!isPageHome &&(
                        <div className="col-span-1  h-[300px]">
                            <div
                                className="flex overflow-hidden relative flex-col grow justify-center items-center h-[300px] max-md:px-5 max-md:pt-10 max-md:mt-5">
                                <LazyLoadImage
                                    loading="lazy"
                                    srcSet={lastGalley.main_imag}
                                    className="object-cover absolute rounded-md h-[300px] w-full inset-0"
                                    alt={'main_imag'}
                                />

                                <div
                                    role={'button'}
                                    onClick={toMore}
                                    className={'mt-4 relative h-[100%] lg:w-[100%] w-[135%] text-white font-bold flex items-end justify-center pb-[30px] rounded-md bg-gradient-to-b from-transparent to-[#000000ab] hover:bg-gradient-to-b hover:from-transparent hover:to-[#051a46]  '}>
                                    <TranslateComponent word={'Show more'}/>

                                </div>
                            </div>
                        </div>
                    )}
                </div>
            )}

            {OpenStatus&&(
                <GalleryDeatailsPopup
                    loader={loader}
                    gallery={gallery}
                    ChangePopupStatus={ChangePopupStatus}
                />
            )}
        </div>

    )
}
export default Galleries;