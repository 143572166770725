import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { WebsiteLogo, SearchIcon, Calculator } from '../../Images/svgs';
import axiosClient, { truncateText } from '../../axios-client';
import { useDispatch, useSelector } from 'react-redux';
import DropDown2 from '../Partials/DropDown2';
import { feachSerch, handelSerchStatus } from '../../Managment/SerchResult';
import ButtonLangCurrency from '../../Managment/ButtonLangCurrency';
import defult_user from "../../Images/defult_user.png";
import { getToken, getUser } from '../../axios-client';
import TranslateComponent, { translateText } from '../TranslateComponent';
import SearchComponent from "../other/SearchComponent";
import DropdownMenu from "../other/DropdownMenu";


function SliderPhone({header}) {
    const location = useLocation();




    const showDropdown = useSelector(state => state.StoreSerch.ShowDropdown);
    const RefForSerchDrop = useRef(null);
    const handleOptionClick = (option) => {
        dispatch(handelSerchStatus(false));
    };
    const dispatch = useDispatch();








    useEffect(() => {
        const handelCloseSercDrop = (event) => {
            if (RefForSerchDrop.current && !RefForSerchDrop.current.contains(event.target)) {
                dispatch(handelSerchStatus(false));
            }
        }

        window.addEventListener('mousedown', handelCloseSercDrop);
        return () => {
            window.removeEventListener('mousedown', handelCloseSercDrop)
        }
    }, [dispatch]);

    return (
       <>
            <div className={'col-span-1'}>
               <div className=" p-4 main-menu-phone ">

                   <DropdownMenu menu={header?.header} />


               </div>


               <div className=" p-4 border-t border-gray-200">

                   <SearchComponent phone={true}/>

               </div>


               <div className={'px-4'}>
                   <ButtonLangCurrency/>
               </div>

               <div className={' mt-3 mb-8'}>
                   <ul className="flex items-center justify-center gap-3  w-full px-2">
                       <li className={' text-white px-4 py-2 bg-black rounded-md font-semibold'} href="#">
                           <TranslateComponent word={'join us'}/>
                       </li>
                       <li>
                           {getToken() ? (
                               <Link
                                   to={'/profile'}
                                   className="flex   gap-2 px-1.5 py-1 text-sm font-semibold leading-5 text-right text-black rounded-lg border border-solid shadow-sm bg-zinc-100 border-zinc-400 max-md:pl-5">
                                   <img
                                       loading="lazy"
                                       srcSet={getUser().avatar ?? defult_user}
                                       className="shrink-0 aspect-square  w-[30px] mask mask-squircle object-cover"
                                       alt={'avatar'}
                                   />
                                   <div className="my-auto text-ellipsis">{getUser().name}</div>

                               </Link>
                           ) : (

                               <Link to="/login"
                                     className="  text-white px-4 py-2 bg-blue-700 rounded-md font-semibold">
                                   <TranslateComponent word={'registration'}/>
                               </Link>

                           )}
                       </li>
                   </ul>
               </div>

            </div>
       </>
    )
}


export default SliderPhone;
