import React,{useState} from 'react';
import GiftPopup from '../Gifts/GiftPopup';
import { useNavigate } from 'react-router-dom';
import TranslateComponent, { translateText } from "../TranslateComponent";
import { useSelector } from 'react-redux';
const JoinUsSection = ({text}) => {
        const translations = useSelector((state) => state.Storetranslate.translations);
    const [openGiftDrop,setOpenGiftDrop]=useState(false);
    const changeGiftDropStatus=()=>{
        setOpenGiftDrop(!openGiftDrop);
    }
    window.addEventListener('removeForPayDirect',()=>{
        setOpenGiftDrop(false);
    })
    const navigate =useNavigate();
    const gotToVolunteerForm=()=>{
        navigate('/volunteer-form');
    }
    return (
        <section className="join-us  container mx-auto py-5 px-2">
            <div className="join-us-container grid grid-cols-1 lg:grid-cols-3 ">
                <div className="join-us-card cursor-pointer  rounded-r-xl"
                    data-bs-toggle="modal"
                    data-bs-target="#send-gift-modal"
                >
                    <img
                        src="https://alkhaircharity.org/orphan_asset/img/pic-1.png"
                        className="main-pic w-full"
                        alt={translateText("Send a gift to the orphan",translations)}
                    />
                    <div className="content" role='button' onClick={changeGiftDropStatus}>
                        <img
                            src="https://alkhaircharity.org/orphan_asset/img/icon-pic-1.svg"
                            className="icon mx-auto mb-4"
                            alt={translateText("Send a gift to the orphan",translations)}
                            />
                        <h2 className="title text-xl font-bold mb-4"><TranslateComponent word={"Send a gift to the orphan"}/></h2>
                        <a href="#!" className="btn-more text-blue-600">
                            <span className="icon text-2xl"><TranslateComponent word={'+'}/></span>
                        </a>
                    </div>
                </div>
                <div
                    className="join-us-card cursor-pointer"
                    data-bs-toggle="modal"
                    data-bs-target="#joinUs-modal"
                    role="button"
                    onClick={gotToVolunteerForm}
                >
                    <img
                        src="https://alkhaircharity.org/orphan_asset/img/pic-2.png"
                        className="main-pic w-full"
                        alt={translateText("Join the volunteers",translations)}
                    />
                    <div className="content ">
                        <img
                            src="https://alkhaircharity.org/orphan_asset/img/icon-pic-2.svg"
                            className="icon mx-auto mb-4"
                            alt={translateText("Join the volunteers",translations)}
                            />
                        <h2 className="title text-xl font-bold mb-4"><TranslateComponent word={"Join the volunteers"}/></h2>
                        <a href="#!" className="btn-more text-blue-600">
                            <span className="icon text-2xl"><TranslateComponent word={'+'}/></span>
                        </a>
                    </div>
                </div>
                <div className="join-us-card rounded-l-xl">
                    <div className="content text-center pr-[7rem]">
                        <h2 className="title text-xl font-bold mb-4">
                            {text}
                        </h2>
                    </div>
                </div>
            </div>
            {openGiftDrop&&(
                <GiftPopup changeGiftDropStatus={changeGiftDropStatus} />
            )}
        </section>
    );
};

export default JoinUsSection;
