import { createSlice } from '@reduxjs/toolkit';
import axiosClient, { getLocalLang } from '../axios-client';

export const initialState = {
    translations: {},
};

export const Translate = createSlice({
    name: 'Storetranslate',
    initialState,
    reducers: {
        setTranslations: (state, action) => {
            state.translations = action.payload;
        },
    },
});

export const { setTranslations } = Translate.actions;

export const fetchTranslations = () => async (dispatch) => {
    try {
        const response = await axiosClient.get('/translate', {
            params: {
                lang: localStorage.getItem('lang'),
            },
        });
        const translationData = response.data.data;

        const newTranslations = {};
        translationData.forEach((translation) => {
            if (!newTranslations[translation.lang]) {
                newTranslations[translation.lang] = {};
            }
            newTranslations[translation.lang][translation.lang_key] = translation.lang_value;
        });

        dispatch(setTranslations(newTranslations));
    } catch (error) {
        console.error('Error fetching translations:', error);
    }
};

export const translateur = (key) => (state) => {
    const langKey = key.replace(/[^A-Za-z0-9_\sءاأإآؤئبتثجحخدذرزسشصضطظعغفقكلمنهويةى]/gu, '').replace(/[\s_]+/g, '_');
    const lang = getLocalLang()?.code??'ar';



    if (!state || Object.keys(state).length === 0) {
        // const existingKeys = JSON.parse(localStorage.getItem('translations')) || {};
        // if (!existingKeys.hasOwnProperty(langKey)) {
        //
        //     existingKeys[langKey] = key;
        //     localStorage.setItem('translations', JSON.stringify(existingKeys));
        // }


        return key;
    } else {
      const languageTranslations = state[lang];


        if (languageTranslations) {
            if (languageTranslations[langKey]) {
                return languageTranslations[langKey];
            }else {
                sendTranslationRequest(key ,langKey,lang)
            }
        }
    }
    return key;
};


const sendTranslationRequest = async (key, langKey, lang) => {
    try {
        await axiosClient.post('/save/translate', {
            key: key,
            lang_key: langKey,
            lang: lang,
        });
    } catch (error) {
        console.error('Error sending translation request:', error);
    }
};
export default Translate.reducer;
