import { useEffect } from "react";
import TranslateComponent, { translateText } from "./TranslateComponent";
import { useSelector,useDispatch } from 'react-redux';
import { featchFooter } from "../Managment/Footer";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { getLocalLang } from "../axios-client";
const ContactDetails = () =>{
    const translations = useSelector((state) => state.Storetranslate.translations);
    const footerData = useSelector(state=>state.StoreFooter.data);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(featchFooter);
    }, []);
    return(
        <>
            <Helmet>
                <title>{translateText('Al Khair Foundation',translations)} | {translateText('Contact Details',translations)} </title>
            </Helmet>
            <div
                dir={getLocalLang().code==='ar'?'ltr':'rtl'}
                className="flex flex-col justify-center px-5 py-8 mt-5 rounded-xl border border-solid backdrop-blur-[10px] bg-zinc-50 border-zinc-300 max-md:max-w-full">
                <div className="flex flex-col items-end pl-20 max-md:pl-5 max-md:max-w-full">
                    <div className="self-end text-xl font-bold text-start text-black"><TranslateComponent word={'Our accounts on social networks'}/></div>
                    <div className="flex gap-5 self-start px-0.5 mt-5 ml-3 max-md:ml-2.5">
                        {footerData&& footerData.data?.social.map((item,index)=>(
                            <Link to={item.link} target={'_blank'}>
                            <img
                                key={index}
                                loading="lazy"
                                src={item.icon}
                                className="shrink-0 w-9 aspect-square"
                                alt={item.link}
                            />
                            </Link>
                        ))}
                    </div>
                </div>
            </div>
            <div
                className="flex flex-col justify-center px-5 py-8 mt-5 text-xl font-bold text-start text-black rounded-xl border border-solid backdrop-blur-[10px] bg-zinc-50 border-zinc-300 max-md:max-w-full">
                <div className="flex gap-2 justify-between max-md:flex-wrap max-md:max-w-full">
                    <img
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/03ffb6bfa8845e1ece927c7f3ed04ec7434a9085b1a1f534a0a4314f9aebbd32?apiKey=0ea99ad6805e4b00a629c3d17bcd7717&"
                        className="shrink-0 self-start aspect-square w-[72px]"
                        alt={'contact_details'}
                    />
                    <div>
                        <span className="leading-6"><TranslateComponent word={'Contact us via'}/></span>
                        <br/>
                        <span className="text-4xl leading-10"><a target={'_blank'} href={`//api.whatsapp.com/send?phone=${footerData.data?.phone}`} ><TranslateComponent word={'Whatsapp'}/> </a></span>
                    </div>
                </div>
            </div>
            <div
                className="flex flex-col justify-center px-5 py-8 mt-5 text-black rounded-xl border border-solid backdrop-blur-[10px] bg-zinc-50 border-zinc-300 max-md:max-w-full">
                <div
                    className="flex flex-col  max-w-full text-xl font-bold text-start w-[132px]">
                    <span className={'text-2xl'}>@</span>
                    <div className="mt-2"><TranslateComponent word={'E-mail'}/></div>
                </div>
                <div className="mt-2  ">
                <TranslateComponent word={'Send us a message at'}/>

                    <a
                        href={`mailto:${footerData.data?.email}`}
                        className="font-semibold text-black underline"
                        target="_blank"
                    >
                        {footerData.data?.email}
                    </a>
                    <br/>
                    <TranslateComponent word={'Or use the contact form to the right!'}/>
                </div>
            </div>
        </>
    )
}
export default ContactDetails;