import TranslateComponent, {translateText} from "../TranslateComponent";
import {Link} from "react-router-dom";
import {ErrorIcon} from "../../Images/svgs";
import SEOComponent from "../SEOComponent";

const ErrorResultDirect = () =>{
    return(

        <>
            <SEOComponent
                title={'Error Result'}
            />
            <div className="flex flex-col items-center justify-center login-y">
                <div className="flex flex-col p-16 mx-auto w-full bg-white rounded-3xl border border-solid border-neutral-200 max-w-[480px] max-md:px-5">
                    <div className="flex flex-col justify-center px-10 text-sm text-black bg-white rounded-xl max-w-[486px] max-md:px-5">
                        <div className={'mx-auto'}>
                            <ErrorIcon width={100}/>
                        </div>
                        <div className="flex flex-col">
                            <div className="mt-6 text-2xl font-bold text-center">
                                <TranslateComponent word={'Payment Failed'}/>
                            </div>
                            <div className="mt-2 font-light text-center">
                                <TranslateComponent word={'The Purchase Failed'}/>
                            </div>

                                <Link to={'/'}>
                                    <div className="flex flex-col justify-center items-center px-3.5 py-2 mt-6 font-semibold text-white bg-blue-600 rounded-lg border border-blue-500 border-solid shadow-sm leading-[143%] max-md:px-5">
                                        {translateText('Back To Home')}
                                    </div>
                                </Link>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default ErrorResultDirect;