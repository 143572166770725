import { LazyLoadImage } from 'react-lazy-load-image-component'
import React from "react";
const GalleriesForSingle =({gallery,loader})=>{
    return (
        <div className="carousel w-full">
            {gallery && gallery?.map((item,index)=> (
                <div id={`slide${index}`} className="carousel-item relative w-full  flex justify-center">
                    {loader? (
                        <div className="flex justify-center items-center">
                            <span className="text-white loading loading-dots loading-lg my-24"></span>
                        </div>
                    ):(
                        <>
                            <LazyLoadImage
                                src={item}
                                className="w-auto h-auto"
                                alt={'gallery'}
                            />
                            <div className="absolute flex justify-between transform -translate-y-1/2 left-5 right-5 top-1/2">
                                <a href={`#slide${index - 1}`}
                                   className={`btn btn-circle ${index === 0 ? 'display:none border-gray-600 bg-gray-600' : ''}`}>❮</a>
                                <a href={`#slide${index + 1}`}
                                   className={`btn btn-circle ${index === gallery.length - 1 ? 'display:none border-gray-600 bg-gray-600' : ''}`}>❯</a>
                            </div>
                        </>
                    )}
                </div>
            ))}
        </div>
    )
}
export default GalleriesForSingle;