import React ,{useState} from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import SubscriptionOrphan from "./tables/SubscriptionOrphan";
import OtherPayOrphan from "./tables/OtherPayOrphan";
import ReportOrphan from "./tables/ReportOrphan";
import {useDispatch,useSelector} from "react-redux";
import { CancelSubAction } from "../../Managment/CancelSub";
import GiftPopup from "../Gifts/GiftPopup";
import TranslateComponent from "../TranslateComponent";
import SEOComponent from "../SEOComponent";
import { truncateText } from "../../axios-client";

const OrphanDetails = ({fromProfile,ChangeOpenstatus,orphanData,loaderOphanDetails})=>{
    const SenCancelStatus =useSelector(state=>state.StoreCancelSub.cancelSub);
    const [OpenPage,setOpenPage]=useState('subscription');

    const handeOpenPage=(pageName)=>{
        setOpenPage(pageName)
    }

    const [openGiftDrop,setOpenGiftDrop]=useState(false);
    const changeGiftDropStatus=()=>{
        setOpenGiftDrop(!openGiftDrop);
    }
    window.addEventListener('removeForPayDirect',()=>{
        setOpenGiftDrop(false);
    })


    const dispatch = useDispatch()
    const setDataCancelSub =(payment_option,subscription_id,order_detail_id,type)=>{
        dispatch(CancelSubAction(payment_option,subscription_id,order_detail_id,type));
    }
    return (
        <>
            <SEOComponent
                title={orphanData.seo.meta_title}
                description={truncateText(orphanData.seo.meta_description,169)}
                image={orphanData.main_image}
            />
            <div className={`${!fromProfile&&'p-16'}`}>
                <div className="p-8 bg-white shadow mt-24">
                    {loaderOphanDetails?(
                        <div className="flex justify-center items-center">
                            <span className="text-black loading loading-dots loading-lg"><TranslateComponent word={''}/></span>
                        </div>
                    ):(
                        <>
                            <div className="grid grid-cols-1 md:grid-cols-3">
                                <div className="flex justify-center items-center text-center gap-11 order-last md:order-first  mt-2 md:mt-0">
                                    <div>
                                        <h3 className={`badge
                                        ${orphanData?.subscription?.status==='ACTIVE'&&'bg-success'}
                                        ${orphanData?.ubscription?.status==='CANSELED'&&'bg-gray-400 text-black'}
                                        ${orphanData?.ubscription?.status==='EXPIRED'&&'bg-gray-400 text-black'}
                                        text-white`}>
                                        {orphanData?.subscription?.status}
                                        </h3>
                                    </div>
                                </div>
                                <div className="relative">
                                    {orphanData?(
                                        <div >
                                        <LazyLoadImage
                                            className="w-48 h-48 bg-indigo-100 mx-auto rounded-full shadow-2xl absolute inset-x-0 top-0 -mt-24 flex items-center justify-center text-indigo-500"
                                            src={orphanData.image}
                                            alt={'orphan'}

                                        />
                                        </div>

                                    ):(
                                        <div className="w-48 h-48 bg-indigo-100 mx-auto rounded-full shadow-2xl absolute inset-x-0 top-0 -mt-24 flex items-center justify-center text-indigo-500">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-24 w-24" viewBox="0 0 20 20" fill="currentColor">
                                                <path fillRule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clipRule="evenodd" />
                                            </svg>
                                        </div>
                                    )}
                                </div>
                                <div className="flex justify-center  mt-32 md:mt-0 md:justify-center">
                                    <button onClick={changeGiftDropStatus} className="py-2 mx-[0.1rem]  w-[100px] rounded-lg ml-1 font-bold text-white bg-blue-700 h-[50px]  hover:bg-yellow-400 hover:text-black text-sm p-2 whitespace-nowrap">
                                        <TranslateComponent word={'Send a gift'}/>
                                    </button>
                                    {SenCancelStatus?(
                                        <button  className="py-2 mx-[0.1rem]  w-[100px] rounded-lg ml-1 font-bold text-white bg-blue-700 h-[50px]  hover:bg-yellow-400 hover:text-black text-sm p-2 whitespace-nowrap">
                                            <div className="flex justify-center items-center">
                                                <span className="text-white loading loading-dots loading-lg"><TranslateComponent word={''}/></span>
                                            </div>
                                        </button>
                                    ):(
                                        <button onClick={()=>{setDataCancelSub('stripe',orphanData.subscription_id,orphanData.order_detail_id,'orphan')}} className="py-2 mx-[0.1rem]  w-[100px] rounded-lg ml-1 font-bold text-white bg-blue-700 h-[50px]  hover:bg-yellow-400 hover:text-black text-sm p-2 whitespace-nowrap">
                                            <TranslateComponent word={'Cancellation of sponsorship'}/>

                                        </button>
                                    )}
                                </div>
                            </div>

                            <div className="mt-20 text-center border-b pb-12">
                                <h1 className="text-4xl font-medium text-gray-700">
                                    {orphanData.name}, <span className="font-light text-gray-500">{orphanData.age}</span>
                                </h1>
                                <p className="font-light text-gray-600 mt-3">{orphanData.country}</p>

                                <p className="mt-8 text-gray-500"><TranslateComponent word={'educational level'}/></p>
                                <p className="mt-2 text-gray-500">{orphanData.classroom}</p>
                            </div>

                            <div className="mt-12 flex flex-col justify-center">
                                <p className="text-gray-600 text-center font-light lg:px-16">
                                    <div dangerouslySetInnerHTML={{__html:orphanData?.description}}/>
                                </p>
                            </div>

                            <div className="flex mt-11 justify-center items-center">
                                <button onClick={()=>handeOpenPage('subscription')} className={`mx-5 py-2 w-[100px] rounded-lg font-bold ${OpenPage==='subscription'?'bg-yellow-400 text-black':'bg-blue-700 text-white '}  h-[50px]  hover:bg-yellow-400 hover:text-black text-sm p-2 whitespace-nowrap`}>
                                    subscription
                                </button>
                                <button onClick={()=>handeOpenPage('other_pay')}  className={`mx-5 py-2 w-[100px] rounded-lg font-bold  ${OpenPage==='other_pay'?'bg-yellow-400 text-black':'bg-blue-700 text-white'} h-[50px]  hover:bg-yellow-400 hover:text-black text-sm p-2 whitespace-nowrap`}>
                                    other_pay
                                </button>
                                <button onClick={()=>handeOpenPage('report')}   className={`mx-5 py-2 w-[100px] rounded-lg font-bold  ${OpenPage==='report'?'bg-yellow-400 text-black':'bg-blue-700 text-white'} h-[50px]  hover:bg-yellow-400 hover:text-black text-sm p-2 whitespace-nowrap`}>
                                    report
                                </button>
                            </div>
                        </>
                    )}
                </div>
            </div>
            <div className={`${!fromProfile&&'p-16'}`}>
                <div className="px-1 bg-white shadow mt-24">

                    {OpenPage==='subscription'&&(
                        <SubscriptionOrphan orders={orphanData.subscription?.orders} symbol={orphanData.symbol}/>
                    )}
                    {OpenPage==='other_pay'&&(
                        <OtherPayOrphan other_pay={orphanData.other_pay} symbol={orphanData.symbol}/>
                    )}
                    {OpenPage==='report'&&(
                        <ReportOrphan report={orphanData.report} symbol={orphanData.symbol}/>
                    )}
                </div>
            </div>
            {openGiftDrop&&(
                <GiftPopup changeGiftDropStatus={changeGiftDropStatus} orphanId={orphanData.id}/>
            )}
        </>
    );

};
export default OrphanDetails;