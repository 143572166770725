import React, {useRef} from "react";
import ReactPlayer from "react-player";

const VideoPopup = ({
show,
openPopUp,
closePopUp,
path,
})=>{
    const handleLosePopUp = (e) => {
        if (e.target.id === 'ModelContainer') {
            closePopUp();
        }
    };

    const handleCloseModal = () => {
        closePopUp();
    };
    const playerRef = useRef(null);

    if (!openPopUp) return null;
    return (
        <div
            id='ModelContainer'
            onClick={handleLosePopUp}
            className='fixed inset-0  flex justify-center items-center  backdrop-blur-sm z-30'
        >
            <div className=' bg-white w-10/12 md:w-1/2 lg:w-1/3 shadow-inner border-e-emerald-600 rounded-lg p-0'>
                <div className='w-full flex flex-col items-start bg-black'>
                    {/* Close button */}
                    <button onClick={handleCloseModal}
                            className=" text-gray-500 hover:text-gray-700 focus:outline-none">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24"
                             stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                  d="M6 18L18 6M6 6l12 12"/>
                        </svg>
                    </button>

                    <h2 className='flex items-center w-full'>
                        <ReactPlayer ref={playerRef} url={path} playing={show} controls={true} width={'100%'} />
                    </h2>
                </div>
            </div>
        </div>
    )
}
export default VideoPopup;