import React, {useState} from 'react'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import {CartIcon, HeartIcon} from '../../Images/svgs';
import {useDispatch} from "react-redux";
import {fetchCreateCart,fetchGetData} from "../Cart/Cart"
import PopupCopmponent from "../PaymentSteps/PopupCopmponent";
import {toast} from "react-toastify";
import LocalCart from "../Cart/LocalCart";
import axiosClient, {getToken, truncateText} from "../../axios-client";
import {withToken} from "../../MiddelwareToken";
import {Fade} from "react-awesome-reveal";
import {Link} from "react-router-dom";
import {LazyLoadImage} from "react-lazy-load-image-component";

import TranslateComponent, { translateText } from '../TranslateComponent';
import { useMediaQuery } from 'react-responsive'
import { useSelector } from 'react-redux';

const colorClasses = [
    'text-pink-800 bg-red-100 border-pink-800',
    'text-blue-800 bg-blue-100 border-blue-800',
    'text-green-800 bg-green-100 border-green-800',
    'text-yellow-800 bg-yellow-100 border-yellow-800',
];

const getRandomColorClass = () => {
    const randomIndex = Math.floor(Math.random() * colorClasses.length);
    return `relative px-2.5 py-1 rounded-xl border-solid badge ${colorClasses[randomIndex]}`;
};
function ProjectCard({ project, index ,handelWishListEvent ,handelGetWish}) {
    const translations = useSelector((state) => state.Storetranslate.translations);
    const {createCart} = LocalCart();
    const dispatch = useDispatch();

    const isTabletScreen = useMediaQuery({ query: '(min-width: 600px) and (max-width: 786px)' });

    const handelCreateCart = async (type,cart_id,price) =>{
        if(price){
                await dispatch(fetchCreateCart(type, cart_id, price));
                await dispatch(fetchGetData());
        }else{
            toast.error(translateText('Enter the amount to donate',translations))
        }
    }
    const handelCreateCartLocal = (type,cart_id,price,symbol,image)=>{
        if(price){
            createCart(type,cart_id,price,symbol,image);
        }else{
            toast.error(translateText('Enter the amount to donate',translations))
        }
    }
    const [price ,setPrice]=useState('');
    const [showPopup , setShowPopup] = useState(false);

    const handelChangePrice = (e)=>{
        const {value} = e.target;

        if (value > 0){
            setPrice(value);
            setShowPopup(true);
            setType('project');
            localStorage.setItem('price',value);
            dispatchEvent(new Event('priceChanged'));
        }

    }
    const [pricePay,setPricePay]=useState('');
    const [type,setType]=useState('');
    const forPay=(pricePay)=>{
        // eslint-disable-next-line no-mixed-operators
        if(getToken() && getToken()!=='' || getToken() !==null){
            showPopup&&(  document.getElementById('my_modal_4_project').showModal() )
            if(pricePay){
                setPricePay(pricePay)
            }else{
                toast.error(translateText('Please enter the donation amount',translations));
            }
        }else{
            toast.info(translateText('You must be logged in',translations))
        }
    }
    window.addEventListener('removeForPayDirect',()=>{
        setPrice('');
    })
    const [wishList,setWishList]=useState(project.wishlist);
    const wishlist =(project_id,wishListStatus) =>{
        const url = wishListStatus?'delete':'create';
        const requestvariable = wishListStatus?"id":"project_id";
        axiosClient.post(`/wishlist/${url}`,{
            [requestvariable]: project_id
        }).then(response=>{
            if(url==='delete'){
                if(handelGetWish){
                    handelGetWish()
                }
                setWishList(false)
            }else{
                setWishList(true)
            }


        })
        .catch(error=>{
            console.log(error)
        })
    }
    const handelAndCheckWishlist = withToken(wishlist)
    return (
        <>
            <Fade>
            <div key={index} className="max-w-sm transform transition duration-300 hover:scale-105 border border-gray-200 rounded-lg shadow bg-white  dark:border-gray-200 mk-rtl" >
                <div className="p-1 space-y-4 ">
                    {project?.gallery && (

                        <Carousel
                            additionalTransfrom={0}
                            sw
                            arrows={false}
                            autoPlaySpeed={3000}
                            centerMode={false}
                            className="mb-3 h-[15vh] md:h-auto  shadow-lg shadow-gray-400/50 rounded"
                            containerClass="container "
                            dotListClass=""
                            draggable={false}
                            focusOnSelect={false}
                            infinite={false}
                            itemClass=""
                            keyBoardControl
                            minimumTouchDrag={80}
                            pauseOnHover
                            renderArrowsWhenDisabled={false}
                            renderButtonGroupOutside={false}
                            renderDotsOutside={false}
                            responsive={{
                                desktop: {
                                    breakpoint: {
                                        max: 3000,
                                        min: 1024
                                    },
                                    items: 1
                                },
                                mobile: {
                                    breakpoint: {
                                        max: 464,
                                        min: 0
                                    },
                                    items: 1
                                },
                                tablet: {
                                    breakpoint: {
                                        max: 1024,
                                        min: 464
                                    },
                                    items: 1
                                }
                            }}
                            rewind={false}
                            rewindWithAnimation={false}
                            rtl={true}
                            shouldResetAutoplay
                            showDots
                            sliderClass=""
                            slidesToSlide={1}
                            swipeable={false}>
                            {project.gallery.map((item, index) => (
                                <div key={index}
                                     className="flex   overflow-hidden relative flex-col justify-between self-stretch px-3 pt-3 pb-12 text-sm font-medium tracking-normal leading-3 text-center text-emerald-900 uppercase whitespace-nowrap rounded-md aspect-[1.26]">
                                    <LazyLoadImage
                                        src={item.image}
                                        className="object-cover absolute inset-0 size-full"
                                        alt={`Image ${index}`}
                                    />
                                   <div className="flex relative gap-5 justify-between">
                                                          <span className={getRandomColorClass()}>
                                        {project.category}
                                    </span>
                                        <button
                                            onClick={() => handelAndCheckWishlist(project.id, project.wishlist || wishList)}>
                                            <HeartIcon fill={project.wishlist || wishList ? 'red' : 'black'}
                                                       className="w-6 aspect-square"/>
                                        </button>
                                    </div>
                                </div>
                            ))
                            }
                        </Carousel>
                    )}
                    <div className='px-2 space-y-4 text-start'>
                        <h5 className="text-2xl font-bold  dark:text-black text-black ">
                        <Link to={`/project/${project.slug}`}>
                            {project?.name?.length&&(
                                isTabletScreen ? (
                                     truncateText(project.name, project.name.length>10 ?10 : project.name.length)
                                ):(
                                    truncateText(project.name, project.name.length > 20 ?20 : project.name.length)
                                )
                            )}
                        </Link>
                        </h5>
                        <span
                            className="font-normal text-black dark:text-black  "><TranslateComponent word={'Last donation before'}/>{project.created_at}</span>
                        <div className="relative w-237 h-2">
                            <progress className="progress progress-primary  w-70 " value={project.percentage} max="100"></progress>

                        </div>
                        <div className="flex flex-row justify-between px-1">
                          <span className=' font-bol text-black'>{project.project_value} {project.currency}</span>
                            <span className='font-medium text-black'>{Math.trunc(project.current_value)}</span>
                        </div>
                    </div>
                    <div className="flex flex-col">
                        <div className="flex flex-row items-center gap-1 mx-auto my-auto w-full sm:w-full md:w-full">
                            <div className={' rounded-lg border-2 flex'}>
                            <input
                                className=" w-full outline-none text-lg indent-1 m-1"

                                value={price} min={0}
                                type="number" placeholder={translateText('Price',translations)} onChange={handelChangePrice}/>



                                <span className="flex items-center rounded rounded-l-none border-0 p-1 font-bold text-grey-100">
                                    <button
                                        className="bg-blue-700 text-white text-sm py-2 px-4 rounded"
                                        onClick={() =>forPay(price)} >{translateText('Donation',translations)}
                                    </button>
                                </span>
                        </div>

                            <span className='py-3 px-3 rounded-lg border-2' role="button"
                                  onClick={() => {
                                      if (getToken()) {
                                          handelCreateCart('project', project.id, price);
                                      } else {
                                          handelCreateCartLocal('project', project.id, price,project.symbol,project.gallery[0].image);
                                      }
                                  }}
                            >
                               <CartIcon/>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            </Fade>
            {showPopup&&(
                <PopupCopmponent type={type} setShowPopup={setShowPopup} projectId={project.id} price={pricePay} symbol={project.symbol} handelChangePrice={handelChangePrice}/>
            )}

        </>
    )
}

export default ProjectCard