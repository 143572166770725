import React, {useCallback, useState} from "react";
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import RangePrice from "../RangePrice";
import Country from "./Country";
import Category from "./Category";
import {feacthFilter} from "../../Managment/Projects";
import {useDispatch} from "react-redux";
import TranslateComponent, { translateText } from "../TranslateComponent";
import {useSelector} from "react-redux";
import { CloseIcon } from "../../Images/svgs";
const PopupFilter = ({
url='',
getProjects,
from='',
toggleShowList,
maxValue,
startValue,
endValue
}) =>{
    console.log(url);
    const translations = useSelector((state) => state.Storetranslate.translations);
    const [selectedCountryChose, setSelectedCountryChose] = useState({
        name: translateText('Select country name',translations),
        image: null,
        id: null
    });

    const [value, setValue] = React.useState({start:startValue , end: endValue});

    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState([]);
    const price =[value];
    const dispatch =useDispatch();
    const handelFilter = ()=>{
        if(from==='projectSecound'){
            getProjects(url)
            toggleShowList();

        }else{
            dispatch(feacthFilter(price,selectedCountryChose,selectedCategory));
            toggleShowList();
        }
    }

    return (
        <div className="modal-box md:w-[40%] max-w-5xl text-center">
            <div className="grid grid-cols-1 lg:grid-cols-5">
                <div className="modal-action col-span-1 lg:col-span-1 absolute top-[-24px] left-0">
                    <button onClick={toggleShowList} className="btn rounded-l-none rounded-t-none border-white btn-outline">
                        <CloseIcon/>
                    </button>
                </div>
            </div>
            <RangePrice
                startValue={startValue}
                endValue={endValue}
                maxValue={maxValue}
                setValue={setValue}
                value={value}
            />
            <hr className="my-5"/>
            <div className="grid grid-cols-2">
                <div className="col-span-1">
                    <Country
                        selectedCountryChose={selectedCountryChose}
                        setSelectedCountryChose={setSelectedCountryChose}
                    />
                </div>
                <div className="col-span-1">
                    {selectedCountryChose.name}
                </div>
            </div>
            <hr className="my-5"/>
            <Category
                categories={categories}
                setCategories={setCategories}
                selectedCategory={selectedCategory}
                setSelectedCategory={setSelectedCategory}
            />
            <hr className="my-5"/>
            <button className="btn bg-blue-600 text-white hover:bg-[#FFCD50] hover:text-black border-0 " onClick={handelFilter}>
                <TranslateComponent word={'Confirm'}/>
            </button>
        </div>
    );
}
export default PopupFilter;