import React, {useEffect, useState} from "react";
import axiosClient, {getCurrencyLocal} from "../../axios-client";
import ProjectStudebtCard from "./ProjectsSecondCard";
import Pagination from "../Pagination";
import {useLocation} from "react-router-dom";
import TranslateComponent, {translateText} from "../TranslateComponent";
import ProjectCard from "../Partials/ProjectCard";
import {useSelector} from "react-redux";
import PopupFilter from "../PopusFolder/PopupFilter";
import { Arrow, FilterIcon } from "../../Images/svgs";
import ProjectsSecondCard from "./ProjectsSecondCard";

const ProjectsSecond = ({url}) =>{

    const translations = useSelector((state) => state.Storetranslate.translations);
    const [projectData, setprojectData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pageData, setPageData] = useState('');
    const [current_page, setCurrent_page] = useState('');
    const [lastPage, setLastpage] = useState('');
    const [showList, setShowList] = useState(false);
    const lastPathSegment = window.location.pathname.split('/').pop();


    const getProjects = (page) => {
        setLoading(true);
        axiosClient.get(`/${url}`, {
            params: {
                limit: 8,
                page: page,
                currency: getCurrencyLocal().id
            }
        })
            .then(response => {
                setLoading(false);
                setprojectData(response.data.data.data);
                setPageData(response.data.data.pagination)
                setCurrent_page(response.data.data.pagination.current_page)
                setLastpage(response.data.data.pagination.total_pages)
            })
            .catch(error => {
                setLoading(false);
                console.error('Error:', error);
            });
    };
    useEffect(() => {
        getProjects();
    }, []);
    const handelWishListEvent = () => {
        getProjects();
    }
    const handelToLastPage = () => {
        getProjects(lastPage)
    }
    const handelToNextPage = () => {
        const next = current_page + 1;
        getProjects(next)
    }
    const handelBackToPage = () => {
        const next = current_page - 1;
        getProjects(next)
    }
    const handelBackToStar = () => {
        getProjects(1)
    }
    const toggleShowList = () => {
        setShowList(!showList);
    };
    const maxValue = projectData.reduce((max, project) => {
        return project.project_value > max ? project.project_value : max;
    }, -Infinity);
    const startValue= maxValue * 0.25;
    const endValue= maxValue * 0.75;
    return (
        <>
            {loading ? (
                <div className="container max-w-7xl mx-auto px-2">
                    <span className="text-black loading loading-dots loading-lg"></span>
                </div>
            ) : (
                <>
                    {projectData.length > 0 && (
                        <>
                            <div className={'container max-w-7xl mx-auto px-2'}>
                            <div className="col-span-1 text-end flex-0">
                                <button onClick={toggleShowList}
                                        className="flex-row gap-2 text-black border-2 font-medium md:font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center outline-none">
                                    <FilterIcon/>
                                        <TranslateComponent word={'Filter New'}/>
                                    <Arrow/>
                                </button>
                                {showList && (
                                    <div className="relative">
                                        <div className="fixed inset-0 z-50 flex items-center justify-center">
                                            <div className="absolute inset-0 bg-gray-900 opacity-50"></div>
                                                <PopupFilter
                                                    url={url}
                                                    getProjects={getProjects}
                                                    from={'projectSecound'}
                                                    startValue={startValue}
                                                    endValue={endValue}
                                                    maxValue={maxValue}
                                                    toggleShowList={toggleShowList}
                                                />
                                        </div>

                                    </div>
                                )}
                            </div>

                            <div
                                className={`grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-4 gap-3 container mx-auto px-2`}>
                                {projectData.map((project, index) => (
                                    project?.gallery ? (
                                        <ProjectCard handelWishListEvent={handelWishListEvent} project={project} key={`project_${index}`}/>
                                    ):(
                                        <ProjectsSecondCard handelWishListEvent={handelWishListEvent} project={project} key={`project_${index}`}/>
                                    )
                                ))}
                            </div>

                            </div>
                        </>
                    )}
                </>

            )}
        </>
    );
}
export default ProjectsSecond;