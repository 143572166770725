import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import PaymentsComponents from "../Payment/PaymentsComponents";
import FormCard from "../FormCard";
import axiosClient, { getCurrencyLocal, getToken,formatDate } from "../../axios-client";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import ValidateMsg from "../ValidateMsg";
import {useSelector} from "react-redux";
import PopUpDate from "./PopUpDate";
import $ from "jquery";
import {CloseIcon} from "../../Images/svgs";
import {translateText} from "../TranslateComponent";
const PopupComponent = ({ projectId, symbol, setShowPopup,type, subscription = true,price,stepP,zaka_type=null,is_zakat=0 }) => {

    console.log(type,'type')

    const translations = useSelector((state) => state.Storetranslate.translations);
    const [loader, setLoader] = useState(false);
    const [timeDesc, setTimeDesc] = useState(!subscription?'one_time':'');
    const [steps, setStep] = useState(stepP&&stepP?stepP:subscription ? 1 : 3);
    const [newPrice, setNewPrice] = useState(price);
    const [methodType, setMethodType] = useState('');
    const [methodName, setMethodName] = useState('');
    const [methodId, setMethodId] = useState('');
    const [countries, setCountries] = useState([]);
    const [filteredCountries, setFilteredCountries] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState({
        num_code: "+966",
        image: 'https://project.alkhair.weblayer.info/assets/flags/ar.svg',
        id: null
    });
    const [isOpenCountry, setIsOpenCountry] = useState(false);
    const [searchQueryCountry, setSearchQueryCountry] = useState('');
    const countryWrapperRef = useRef(null);
    const [inputs, setInputs] = useState({
        total: "",
        methods: "",
        email: "",
        phone: "",
    });
    const [errors, setErrors] = useState({
        total: "",
        methods: "",
        email: "",
        phone: "",
        cardNumber: "",
        cvv: "",
    });
    const [optionType, setOptionType] = useState(0);
    const [selectedFileShow, setSelectedFileShow] = useState();
    const [selectedFile, setSelectedFile] = useState(null);
    const [cardNumber, setCardNumber] = useState('');
    const [cvc, setCvc] = useState('');
    const [expiry, setExpiry] = useState('');
    const [urlPagePayment, setUrlPagePayment] = useState('');
    const [state, setState] = useState([
        {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection'
        }
    ]);
    const [selectedMonthCount, setSelectedMonthCount] = useState('');
    const [StartDate,setStartDate]=useState('');
    const ALLTimes=['one_time', 'daily', 'monthly'];
    const SomeTimes=[ 'daily', 'monthly'];
    const [forTimeMap,setForTimeMap]=useState([]);
    const [payNowStatus , setPayNowStatus]=useState(false);
    const [dropForDate,setDropForDate]=useState('');
    const [openDropDate,setOpenDropDate]=useState('');
    const DataAll =useSelector(state=>state.StoreCountries.countries);
    const navigate = useNavigate();
    const hendelSetFromPopup = (data)=>{
        if(dropForDate==='daily'){
            const startData = formatDate(data[0].startDate);
            const endData = formatDate(data[0].endDate);
            const FinishDate = [{
                startData: startData,
                endData: endData
            }];
            return FinishDate;
        } else {
            console.log('no date !');
            return [];
        }
    }
    useEffect(() => {
        setNewPrice(price)
        getCountries();
        if(type==='other'){
            setTimeDesc('one_time')
            setForTimeMap(SomeTimes)
        }else{
            setForTimeMap(ALLTimes)
        }
    }, []);
    useEffect(() => {
        const handelChangePrice=()=>{

            setNewPrice(localStorage.getItem('price'));
        }
        window.addEventListener('priceChanged', handelChangePrice)
        return ()=>{
            window.removeEventListener('priceChanged',handelChangePrice)
        }
    }, []);
    useEffect(() => {
        const handelPayNowEvent = ()=>{
            setPayNowStatus(true);
            setStep(3)
        }
        window.addEventListener('payNow',handelPayNowEvent);
        return ()=>{
            window.removeEventListener('payNow',handelPayNowEvent);
        }
    }, []);
    useEffect(() => {
        if (methodName === 'stripe') {
            setOptionType(1);
        } else if (methodType === 'online' && methodName !== 'stripe') {
            setOptionType(2);
        } else if (methodType === 'offline') {
            setOptionType(3);
        }
        $('.rdrDateInput.rdrDateDisplayItem input').attr('readonly', true);
    }, [steps, methodName, methodType,openDropDate]);
    const handleChangePrice = (e) => {
        setNewPrice(e.target.value);
    };
    const handleNextStep = () => {
        setOpenDropDate(false)
        if (steps < 3) {
            if (timeDesc) {
                setStep(prevStep => Math.min(prevStep + 1, 4));
            } else {
                toast.info(translateText('Select Time!',translations));
            }
        } else if (steps === 3) {
            if (methodType) {
                setStep(prevStep => Math.min(prevStep + 1, 4));
            } else {
                toast.info(translateText('Choose payment method!',translations));
            }
        }
    };
    const handleBackStep = () => {
        setStep(prevStep => Math.max(prevStep - 1, subscription ? 1 : 3));
    };
    const getMethod = (name, type, methodId) => {
        setMethodName(name);
        setMethodType(type);
        setMethodId(methodId);
        localStorage.setItem('methodName', name);
        localStorage.setItem('methodType', type);
        localStorage.setItem('methodId', methodId);
    };
    const handleChangeCvc = (e) => {
        const input = e.target.value;
        if (/^[0-9]*$/.test(input) && input.length <= 3) {
            setCvc(input);
        }
    };
    const handleChangeNumber = (e) => {
        const input = e.target.value;
        if (/^[0-9]*$/.test(input) && input.length <= 16) {
            setCardNumber(input);
        }
    };
    const handleChange = (e) => {
        const { name, value } = e.target;
        setInputs(prevState => ({ ...prevState, [name]: value }));
        if (value.trim()) {
            setErrors(prevErrors => ({ ...prevErrors, [name]: "" }));
        }
    };
    const getCountries = async () => {
        try {
            const data = DataAll;
            setCountries(data);
            setFilteredCountries(data);
            setSelectedCountry(data[0] || { name: 'No countries', num_code: "+966", image: 'https://project.alkhair.weblayer.info/assets/flags/ar.svg', id: null });
        } catch (error) {
            console.error("Error fetching countries:", error);
        }
    };
    const handleCountrySearch = (e) => {
        const query = e.target.value;
        setSearchQueryCountry(query);
        setFilteredCountries(countries.filter(country => country.name.toLowerCase().includes(query.toLowerCase())));
    };
    const toggleCountryDropdown = () => setIsOpenCountry(!isOpenCountry);
    const handleCountrySelection = (country) => {
        setSelectedCountry(country);
        setIsOpenCountry(false);
        setSearchQueryCountry('');
        setFilteredCountries(countries);
    };
    const handleChangeDate = (e) => {
        const input = e.target.value;
        if (/^[0-9/]*$/.test(input)) {
            if (input.length === 2 && expiry.length === 1 && input.charAt(1) !== '/') {
                setExpiry(input + '/');
            } else if (input.length <= 5) {
                setExpiry(input);
            }
        }
    };
    const handleFileChange = (file) => {
        setSelectedFileShow(URL.createObjectURL(file));
        setSelectedFile(file);
    };
    const removeForPayDirect = () => {
        setShowPopup(false);
        setStep(subscription ? 1 : 3);
        setNewPrice('');
        setOptionType(0);
        setTimeDesc('');
        setPayNowStatus(false);
        setOpenDropDate(false);
        setState([
            {
                startDate: new Date(),
                endDate: new Date(),
                key: 'selection'
            }
        ]);
        setDropForDate('')
        setOpenDropDate('')
        setSelectedMonthCount('1');
        localStorage.removeItem('methodType');
        localStorage.removeItem('methodName');
        localStorage.removeItem('methodId');
        localStorage.removeItem('price');
        dispatchEvent(new Event('removeForPayDirect'));
    };
    const handlePayment = async () => {
        setLoader(true);
        const phone  = selectedCountry+inputs.phone;
        const formData = new FormData();
        formData.append('payment_option', methodName);
        formData.append('payment_term', timeDesc);
        formData.append('price', newPrice);
        formData.append('type', type);
        formData.append('type_id',projectId);
        formData.append('currency_id', getCurrencyLocal().id);
        if(zaka_type){
            formData.append('zaka_type', zaka_type);
        }
        formData.append('is_zakat', is_zakat??0);

        if(dropForDate==='daily'){
            const Date =hendelSetFromPopup(state);
            formData.append('start_date', Date[0].startData);
            formData.append('end_date',  Date[0].endData);

        }else if(dropForDate==='monthly'){
            formData.append('start_date', StartDate);
            if(selectedMonthCount){
                formData.append('end_date',  selectedMonthCount);
            }
        }
        if (methodName === 'stripe') {
            const [card_exp_month, card_exp_year] = expiry.split('/');
            formData.append('card_number', cardNumber);
            formData.append('card_exp_month', card_exp_month);
            formData.append('card_exp_year', card_exp_year);
            formData.append('card_cvc', cvc);
            formData.append('phone', phone);
        }
        try {
            if (methodType === 'offline') {
                const token = getToken();
                const config = {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data',
                        'Accept': 'application/json'
                    }
                };
                formData.append('payment_offline_file', selectedFile);
                formData.append('payment_offline_id', methodId);

                await axios.post('https://project.alkhair.weblayer.info/api/payment/direct', formData, config)
                    .then(response=>{
                        removeForPayDirect();
                        navigate('/success/direct');
                    })
                    .catch(error=>{
                        toast.error(''+error?.response?.data?.message)
                })
            } else if (methodType === 'online') {
                await axiosClient.post('/payment/direct', formData)
                    .then(reposnse=>{
                        if (methodName === 'stripe') {

                            removeForPayDirect();

                            if (reposnse.data.data.url){
                                navigate('/success/direct');
                            }else {
                                navigate('/error/direct');
                            }

                        }else if(methodName==='paypal'||methodName==='iyzico'){
                            setUrlPagePayment(reposnse.data.data.url);

                        }else if(methodName==='binance'){
                            window.location.href=reposnse.data.data.url.checkoutUrl;
                        }
                    })
                    .catch(error=>{
                        toast.error(''+error?.response?.data?.message)
                        console.log(error)
                    })
            }
        } catch (error) {
            console.error("Payment error:", error);
            toast.error(error|| 'Payment error');
        } finally {
            setLoader(false);
        }
    };

    return (
        <dialog id={`my_modal_4_${type}`} className="modal">
            <div className="modal-box md:w-[40%] max-w-5xl">
            {getToken()?(
                <>
                        <div className="grid grid-cols-1 lg:grid-cols-5">
                            <div className="col-span-2 lg:col-span-4">
                                <ul className="steps w-full">
                                    {subscription === true ?
                                        <>
                                            <li data-content={`${steps === 4 ? '✓' : '4'}`}
                                                className={`step ${steps === 4 ? 'step-primary' : ''} `}></li>
                                            <li data-content={`${steps >= 3 ? '✓' : '3'}`}
                                                className={`step ${steps >= 3 ? 'step-primary' : ''} `}></li>
                                            <li data-content={`${steps >= 2 ? '✓' : '2'}`}
                                                className={`step ${steps >= 2 ? 'step-primary' : ''} `}></li>
                                            <li data-content="✓" className={`step step-primary`}></li>
                                        </>
                                        :
                                        <>
                                            <li data-content={`${steps === 4 ? '✓' : '4'}`}
                                                className="step step-primary"></li>
                                            <li data-content={`${steps >= 3 ? '✓' : '3'}`}
                                                className="step step-primary"></li>
                                        </>
                                    }
                                </ul>
                            </div>
                            <div className="modal-action col-span-1 lg:col-span-1 absolute top-[-24px] left-0">
                                <button onClick={removeForPayDirect} className="btn rounded-l-none rounded-t-none border-white btn-outline">
                                    <CloseIcon/>
                                </button>
                            </div>
                        </div>
                        {urlPagePayment ? (
                            <iframe src={urlPagePayment} width="100%" height="600px" title="Content Frame"
                                    style={{border: 'none'}}></iframe>
                        ) : (
                            <>
                            {subscription && steps === 1 && (
                                <>
                                <h2 className={'text-center'}>{translateText('Donation options',translations)}</h2>
                                    <br/>
                                    <p className={'text-center'}>{translateText('Donate an amount either once or automatically paid periodically',translations)}</p>
                                <div className="menu menu-horizontal justify-center rounded-box w-[100%]"
                                role={'group '}>

                            {type && type === 'gift' ? (
                                <div key={0} className="py-2 px-3 text-lg" role={'group '}>
                                    <button
                                        onClick={() => setTimeDesc('one_time')}
                                        className={`border-2 border-neutral-700 ${timeDesc === 'one_time' ? 'bg-blue-600' : ''} rounded-lg px-4 py-2 hover:bg-neutral-700`}
                                    >
                                        {type === 'gift' && translateText('One Time',translations)}
                                    </button>
                                </div>
                            ) : (

                                forTimeMap.map((desc, index) => (
                                    <div key={index} className="py-2 px-3 text-lg" role={'group '}>
                                        <button
                                            onClick={() => {
                                                setTimeDesc(desc);
                                                if (desc === 'one_time') {
                                                    setOpenDropDate(false)
                                                }
                                                if (desc === 'daily') {
                                                    setOpenDropDate(true)
                                                    setDropForDate('daily');
                                                } else if (desc === 'monthly') {
                                                    setOpenDropDate(true)
                                                    setDropForDate('monthly');
                                                }
                                            }}
                                            className={`border-2 border-blue-600 ${timeDesc === desc ? 'bg-blue-600 text-white' : ''} rounded-lg px-4 py-2 hover:bg-blue-700 hover:text-white`}
                                        >
                                            {desc === 'one_time' ? translateText('One Time',translations) : desc === 'daily' ? translateText('Daily',translations) : translateText('Monthly',translations)}
                                        </button>
                                    </div>
                                ))
                            )}

                            </div>
                                </>
                            )}
                    {openDropDate&&(
                                <PopUpDate
                                    setSelectedMonthCount={setSelectedMonthCount}
                                    selectedMonthCount={selectedMonthCount}
                                    setState={setState}
                                    state={state}
                                    type={dropForDate}
                                    StartDate={StartDate}
                                    setStartDate={setStartDate}
                                />
                                )}
                                {subscription && timeDesc && steps === 2 && (
                                    <div className=" flex flex-col items-center">
                                        <div className="card w-100 bg-base-100">
                                            <div className="card-body p-0 m-0">
                                                <h2 className="card-title">{translateText('Total',translations)}</h2>
                                                <p>{translateText('You can modify the amount from here...',translations)}</p>
                                                <div className="card-actions">
                                                    <div className="grid grid-cols-3 gap-2">
                                                        <div className="col-span-2 flex items-center">
                                                            <input
                                                                min={0}
                                                                type="number"
                                                                placeholder={translateText('Enter the amount here',translations)}
                                                                value={newPrice}
                                                                onChange={handleChangePrice}
                                                                className="border p-2 w-full rounded text-black"
                                                            />
                                                        </div>
                                                        <div className="col-span-1 flex items-center">
                                                            <span className=" bg-blue-700 rounded text-white p-2">{getCurrencyLocal()?.name} </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {steps === 3 && (
                                    <PaymentsComponents type={type} timeDesc={timeDesc} newPriceUpdate={newPrice} symbol={symbol}
                                                        getMethod={getMethod}/>
                                )}
                                {methodType && steps === 4 && (
                                    <FormCard
                                        newPrice={newPrice}
                                        selectedFile={selectedFile}
                                        selectedFileShow={selectedFileShow}
                                        handleFileChange={handleFileChange}
                                        phone={inputs.phone}
                                        phoneValidate={errors.phone}
                                        countryimage={selectedCountry ? selectedCountry.image : "https://cdn.builder.io/api/v1/image/assets/TEMP/61e2a4d30838f1fac5f941a5632e6308df0a39d7ecad0cf5773ed52cf94ac455?"}
                                        countryWrapperRef={countryWrapperRef}
                                        toggleCountryDropdown={toggleCountryDropdown}
                                        numCode={selectedCountry.num_code}
                                        isOpenCountry={isOpenCountry}
                                        searchQueryCountry={searchQueryCountry}
                                        handleCountrySearch={handleCountrySearch}
                                        filteredCountries={filteredCountries}
                                        handleCountrySelection={handleCountrySelection}
                                        email={inputs.email}
                                        emailValidate={errors.email}
                                        cardNumber={cardNumber}
                                        cardValidate={errors.cardNumber}
                                        expiry={expiry}
                                        hadelChangeCvc={handleChangeCvc}
                                        cvc={cvc}
                                        validateCvv={errors.cvv}
                                        methodType={methodType}
                                        methodName={methodName}
                                        methodId={methodId}
                                        optionType={optionType}
                                        handleChange={handleChange}
                                        handelChangeNumber={handleChangeNumber}
                                        Changedate={handleChangeDate}
                                    />
                                )}
                                <div className="flex justify-center items-center">
                                    {loader ? (
                                        <div className="flex justify-center items-center w-[50%] py-2 mt-6 text-sm font-semibold leading-5 text-white bg-blue-600 rounded-lg border border-blue-500 shadow-sm">
                                            <span className="loading loading-dots loading-lg"></span>
                                        </div>
                                    ) : (
                                        <>
                                            {steps > (subscription ? 1 : 3) ? (
                                                <>
                                                    {steps === 4 ? (
                                                        <div
                                                            role="button"
                                                            onClick={handlePayment}
                                                            className="flex justify-center items-center w-[25%] mx-3 py-2 mt-6 text-sm font-semibold leading-5 text-white bg-blue-600 rounded-lg border border-blue-500 shadow-sm

                                                            hover:bg-[#FCD34D]  hover:text-black   hover:border-0
                                                            "
                                                        >
                                                            {translateText('Complete',translations)}
                                                        </div>
                                                    ) : (
                                                        <div
                                                            role="button"
                                                            onClick={handleNextStep}
                                                            className="flex justify-center items-center w-[25%] mx-3 py-2 mt-6 text-sm font-semibold leading-5 text-white bg-blue-600 rounded-lg border border-blue-500 shadow-sm         hover:bg-[#FCD34D]  hover:text-black   hover:border-0"
                                                        >
                                                            {translateText('Next',translations)}
                                                        </div>
                                                    )}
                                                    {payNowStatus?(''): (
                                                        <div
                                                            role="button"
                                                            onClick={handleBackStep}
                                                            className="flex justify-center items-center w-[25%] mx-3 py-2 mt-6 text-sm font-semibold leading-5 text-white bg-blue-600 rounded-lg border border-blue-500 shadow-sm         hover:bg-[#FCD34D]  hover:text-black   hover:border-0"
                                                        >
                                                            {translateText('Back',translations)}
                                                        </div>
                                                    )}
                                                </>
                                            ) : (
                                                <div
                                                    role="button"
                                                    onClick={handleNextStep}
                                                    className="flex justify-center items-center w-[50%] py-2 mt-6 text-sm font-semibold leading-5 text-white bg-blue-600 rounded-lg border border-blue-500 shadow-sm         hover:bg-[#FCD34D]  hover:text-black   hover:border-0"
                                                >
                                                    {translateText('Next',translations)}
                                                </div>
                                            )}
                                        </>
                                    )}
                                </div>
                            </>
                        )}
                </>
            ) : (
                <ValidateMsg msg={'NO Ue'}/>
            )}
            </div>
        </dialog>
    );
};

export default PopupComponent;
