// src/components/DropdownMenu.jsx
import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

const DropdownMenu = ({ menu }) => {
    return (
        <nav className="relative inline-block">
            {menu && menu.map((item, index) => (
                <MenuItem key={item.text} item={item} index={index} />
            ))}
        </nav>
    );
};

const MenuItem = ({ item, index }) => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className="relative group mb-1" ref={dropdownRef}>
            <Link
                to={item.page ? `/${item.page.slug}` : item.href || "#"}
                target={item.target || "_self"}
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                onClick={(e) => {
                    if (item.children) {
                        e.preventDefault();
                        setIsOpen(!isOpen);
                    }
                }}
            >
                {item.page ? item.page.name : item.text}
            </Link>

            {item.children && isOpen && (
                <ul className={`absolute top-0  z-10 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5`}>
                    {item.children.map((child) => (
                        <MenuItem key={child.text} item={child} />
                    ))}
                </ul>
            )}
        </div>
    );
};

export default DropdownMenu;
