import React from 'react';
import NewsTabs from "../NewsTabs";
import TranslateComponent from '../TranslateComponent';

const News = () => {
    return (
        <section className="projects" id="projects">
            <div className="container mx-auto px-2">
                <div className="col-lg-12">
                    <div className="section-header">
                        <h2 className="title"> <TranslateComponent word={'Orphans news'}/>
                        </h2>
                        <h3 className="sub-title"> <TranslateComponent word={'Everything related to orphans, their news and the latest developments'}/>
                        </h3>
                        <hr/>
                    </div>
                </div>
                <NewsTabs/>
                <div className="flex justify-center row mt-5">
                    <div className="col-lg-12 text-right">
                        <div className="text-end">
                            <button className="border rounded-lg px-5 py-3 mx-4 bg-[#FAF7F7FF] hover:bg-accent/0">
                                <TranslateComponent word={'See more'}/>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        </section>
    );
}

export default News;
