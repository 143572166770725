import React, { useEffect, useState } from 'react';
import { useMatch, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedValue, toggleDropdown } from './Slice';
import { getCartStatus, getCurrencyLocal, getLocalLang } from '../axios-client';
import { Arrow } from '../Images/svgs';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { toast } from "react-toastify";

function ButtonLangCurrency() {

    const navigate = useNavigate();
    const isHomePage = useMatch('/');
    const dispatch = useDispatch();
    const selectedValues = useSelector(state => state.dropdown.selectedValues);
    const selectedCurrency = useSelector(state => state.dropdown.selectedCurrency);
    const language = useSelector(state => state.Storelang.langs);
    const currencies = useSelector(state => state.StoreCurrency.currencies);

    const handleSelect = (value, index, type, code_id, LangIcon_CurValue) => {
        dispatch(setSelectedValue({ index, value, type, code_id, LangIcon_CurValue }));
        window.location.reload();
    };

    const scrollToDiv = () => {
        navigate('/');
        const timeout = isHomePage ? 0 : 1000;
        setTimeout(() => {
            const targetDiv = document.getElementById('targetDiv');
            if (targetDiv) {
                targetDiv.scrollIntoView({ behavior: 'smooth' });
            }
        }, timeout);
    };

    const IconData = useSelector(state => state.Storelang.icon);
    const [icon, setIcon] = useState(getLocalLang() && getLocalLang()?.icon ? getLocalLang()?.icon : IconData);
    const [symbol, setSymbol] = useState();
    const LocalCurrency = getCurrencyLocal();

    const handleCurrencyChange = (e) => {
        const selectedOption = e.target.selectedOptions[0];
        handleSelect(selectedOption.value, 0, 'currency', selectedOption.dataset.codeId, selectedOption.dataset.curValue);
    };

    const handleLangChange = (e) => {
        const selectedOption = e.target.selectedOptions[0];
        handleSelect(selectedOption.value, 1, 'lang', selectedOption.dataset.lang, selectedOption.dataset.icon);
        setIcon(selectedOption.dataset.icon);
    };

    useEffect(() => {
        setSymbol(LocalCurrency ? LocalCurrency.symbol : selectedCurrency);
    }, [LocalCurrency, selectedCurrency]);

    return (
        <div className="grid grid-cols-1 items-center gap-3">
                <select
                    className="appearance-none bg-transparent outline-none cursor-pointer relative  gap-2 items-center text-black px-1 py-2 border border-gray-200 rounded-md font-medium"
                    onChange={handleCurrencyChange}
                    value={LocalCurrency ? LocalCurrency.code : selectedCurrency}
                >
                    {currencies.map((currency, index) => (
                        <option
                            key={index}
                            value={currency.code}
                            data-code-id={currency.code_id}
                            data-cur-value={currency.name}
                        >
                            {currency.symbol} {currency.name}
                        </option>
                    ))}
                </select>

                <select
                    className="relative text-black px-4 py-2 border border-gray-200 rounded-md gap-2 flex flex-row items-center"
                    onChange={handleLangChange}
                    value={selectedValues}
                >
                    {language.map((lang, index) => (
                        <option
                            key={index}
                            value={lang.value}
                            data-lang={lang.lang}
                            data-icon={lang.icon}
                        >
                            {lang.name}
                        </option>
                    ))}
                </select>
        </div>
    );
}

export default ButtonLangCurrency;
