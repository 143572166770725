import { createSlice } from "@reduxjs/toolkit";
import axiosClient from "../axios-client";

const Header=createSlice({
  name:'StoreHeader',
  initialState:{
    Headers:[],
  },
  reducers:{
    updateHeaderData:(state,action)=>{
      state.Headers=action.payload;
    }
  }
})
export const {updateHeaderData}=Header.actions;

export const featchHeaders=(dispatch)=>{
  axiosClient.get(`/header`)
  .then(response => {
      const headersData = response.data.data;
      dispatch(updateHeaderData(headersData));
  })
  .catch(error => {
      console.error('Error:', error);
  });
}
export default Header.reducer;