import {Link} from "react-router-dom";
import TranslateComponent from "../TranslateComponent";
import SEOComponent from "../SEOComponent";

const SuccessResultDirect = () =>{

    return(
        <>
            <SEOComponent
                title={'Success Redirect'}
            />
            <div className="flex flex-col items-center justify-center login-y">
                <div className="flex flex-col p-16 mx-auto w-full bg-white rounded-3xl border border-solid border-neutral-200 max-w-[480px] max-md:px-5">
                    <div className="flex flex-col justify-center px-10 text-sm text-black bg-white rounded-xl max-w-[486px] max-md:px-5">
                        <img
                            loading="lazy"
                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/c06b15788f9867dc649c228bcfd944446fbb79491fd5485852bdfe310b6bbcec?"
                            className="self-center w-32 max-w-full aspect-square"
                            alt="Success Rsult Direct"
                        />
                        <div className="flex flex-col">
                            <div className="mt-6 text-2xl font-bold text-center">
                                <TranslateComponent word={'Payment Successfully'}/>
                            </div>
                            <div className="mt-2 font-light text-center">
                             <TranslateComponent word={'Purchase completed successfully'}/>
                            </div>

                                <a href="/home">
                                    <div className="flex flex-col justify-center items-center px-3.5 py-2 mt-6 font-semibold text-white bg-blue-600 rounded-lg border border-blue-500 border-solid shadow-sm leading-[143%] max-md:px-5">
                                        {'Back to main page'}
                                    </div>
                                </a>

                        </div>
                    </div>
                </div>
            </div>
        </>
)
}
export default SuccessResultDirect;