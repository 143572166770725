import * as React from "react";
import {getCurrencyLocal, getToken} from "../../axios-client";
import {useEffect, useState} from "react";
import "react-datepicker/dist/react-datepicker.css";
import InputValidetion from "../InputValidetion";
import FileUploader from "../FileUploader";
import axios from "axios";
import {toast} from "react-toastify";
import {useDispatch, useSelector} from "react-redux";
import {feachDeleteAllCarts, fetchGetData} from "../Cart/Cart";
import {useNavigate} from "react-router-dom";
import GetMethods from "../GetMethods";
import AfterPayThanksMsg from "../AfterPayThanksMsg";
import TranslateComponent, { translateText } from "../TranslateComponent";
import ContactDetails from "../ContactDetails";
import SEOComponent from "../SEOComponent";
const PaymentComponent = () =>{
    const translations = useSelector((state) => state.Storetranslate.translations);
    const data = useSelector(state=>state.StoreCart.carts);
    const dispatch = useDispatch();
    const [loader , setLoader]=useState(false);
    const {getOnlineMethods} = GetMethods();
    const {getOfflineMethods} = GetMethods();

    const offlineMethods= getOfflineMethods();
    const methods =getOnlineMethods();
    const total = data && data.total ?data.total: "";
    const [inputs, setInputs] = useState({
        total:"",
        methods:"",
    });
    const [errors, setErrors] = useState({
        total:"",
        methods:"",
    })
    const [optionType,setOptionType] = useState(0);
    const [selectedFileShow, setSelectedFileShow] = useState();
    const [selectedFile, setSelectedFile] = useState(null);
    const [cardNumber , setCardNumber]=useState('');
    const [methodName , setMethodName] =useState('')
    const [methodId , setMethodId] =useState('')
    const [cvc ,setCvc]=useState('')
    const hadelChangeCvc=(e)=>{
        const inputNumber = e.target.value;

        const onlyNumbers = /^[0-9]*$/;
        if (onlyNumbers.test(inputNumber) && inputNumber.length <= 3) {
            setCvc(inputNumber);
            setCvcError('');
        }

    }
    const [methodType , setMethodType]  = useState('');
    const [paymentUrlpage , setPaymentUrlPage] = useState('');
    const handelChangeNumber = (e) =>{
        const inputNumber = e.target.value;
        const onlyNumbers = /^[0-9]*$/;
        if (onlyNumbers.test(inputNumber) && inputNumber.length <= 16) {
            setCardNumber(inputNumber);
            setCardNumberError('')
        }
    }
    const handleChange = (e)=>{
        const {name,value}=e.target;
        setInputs(prevState=>({
            ...prevState,
            [name]:value
        }));

        if (value.trim()) {
            setErrors(prevErrors => ({
                ...prevErrors,
                [name]: ""
            }));
        }
    }
    const [cVcError , setCvcError] = useState('')
    const [cardNumberError , setCardNumberError] = useState('')
    const [DateError , setDateError] = useState('')
    const [expiry, setExpiry] = useState("");
    const [PaySuccess,setPaySuccess]=useState(false);
    const runForOnline =(method_name)=>{
        setInputs({total: inputs.total?inputs.total:total});
        setMethodName(method_name);
        setMethodType('online');
        if(method_name === 'stripe'){
            setOptionType(1)
        }else{
            setOptionType(2)
        }
    }
    const runForOffline =()=>{
        setMethodType('offline');
        setInputs({total: inputs.total?inputs.total:total});
        setOptionType(3)
    }
    const Changedate = (e) => {
        const inputExpiry = e.target.value;
        const isValidInput = /^[0-9/]*$/.test(inputExpiry);

        if (isValidInput) {
            if (inputExpiry.length === 2 && expiry.length === 1 && inputExpiry.charAt(1) !== '/') {
                setExpiry(inputExpiry + '/');
            }
            else if (inputExpiry.length <= 5) {
                setExpiry(inputExpiry);
            }

            setDateError('')
        }
    };
    const requiredMsg=translateText('Field is required',translations);
    const navigate = useNavigate();
    const hendelSubmit  =() =>{
        if(methodName==='stripe'){
            if(expiry ==='' &&cardNumber === ''&&cvc ===''){
                setDateError()
                setCardNumberError(requiredMsg)
                setCvcError(requiredMsg)
                return;
            }
            if(cardNumber === ''){
                setDateError(requiredMsg)
                setCardNumberError(requiredMsg)
                setCvcError(requiredMsg)
                return;

            }
            if(cvc ===''){
                setDateError(requiredMsg)
                setCardNumberError(requiredMsg)
                setCvcError(requiredMsg)
                return;
            }
        }
        if(methodType === 'offline') {
            if (!selectedFile) {
                toast.error(translateText('Plase Add The fail',translations));
                return;
            }
        }
        if(Object.values(inputs).every(input => input !== "") ){
            setLoader(true);

            const parts = expiry.split('/');
            const card_exp_month = parts[0];
            const card_exp_year = parts[1];
            setLoader(false);
            const formData = new FormData();
            const token = getToken();
            formData.append('payment_option',inputs.methods);

            if(methodName==='stripe'){
                formData.append('card_number',cardNumber);
                formData.append('card_cvc',cvc);
                formData.append('card_exp_month',card_exp_month);
                formData.append('card_exp_year',card_exp_year);
            }
            if(methodType === 'offline') {
                formData.set('payment_option','offline');
                formData.append('payment_offline_file',selectedFile);
                formData.append('payment_offline_id',methodId);

            }
            const config = {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data',
                    'Accept': 'application/json'
                }
            };
            axios.post('https://project.alkhair.weblayer.info/api/order/checkout',formData,config)
                .then(response=>{
                    setLoader(false)
                    if(methodType === 'online'){
                        if(methodName==='stripe'){
                            // dispatch(feachDeleteAllCarts());
                            setPaySuccess(true);
                            // navigate('/success/direct');
                        }else if(methodName==='paypal'||methodName==='iyzico'){
                            setPaymentUrlPage(response.data.data.url)
                        }else if(methodName==='binance'){
                            window.location.href=response.data.data.url.checkoutUrl;
                            // console.log(response.data.data.url.checkoutUrl)
                            // setPaymentUrlPage(response.data.data.url.checkoutUrl)
                        }
                    }
                    else{
                        // dispatch(feachDeleteAllCarts());
                        toast.success('success');
                        setPaySuccess(true);
                        // navigate('/success/direct');
                    }
                })
                .catch(error =>{
                    console.log(error)
                    toast.error(''+error?.response?.data?.message)
                    setLoader(false);
                })
        }else{

            setLoader(false);
            const newErrors = {};
            Object.entries(inputs).forEach(([name, value]) => {
                if (!value.trim()) {
                    newErrors[name] = requiredMsg;
                } else {
                    newErrors[name] = "";
                }
            });

            setErrors(newErrors);
        }
    }
    const handleFileChange = (fileUploaded) => {
        if(fileUploaded){
            setSelectedFileShow(URL.createObjectURL(fileUploaded));
            setSelectedFile(fileUploaded);
        }
    };
    useEffect(() => {
        dispatch(fetchGetData());
    }, []);

    return (
        <>
            <SEOComponent
                title={'Payment'}
            />
            <div className="relative">
                {PaySuccess && (
                    <div className="fixed inset-0 flex justify-center items-center z-50 px-16 py-20 backdrop-blur-sm bg-zinc-800 bg-opacity-70">
                        <AfterPayThanksMsg />
                    </div>
                )}
                <div className={`grid grid-cols-1 lg:grid-cols-3 ${PaySuccess ? 'opacity-50' : ''}`}>
                    <div className="lg:col-span-2 px-8 py-8 bg-white rounded-2xl border border-solid border-black border-opacity-20 max-w-[825px] max-md:px-5 mt-[4.25rem] mb-[5rem] w-full">
                        {paymentUrlpage ? (
                            <>
                                <button className="my-[20px]" onClick={() => setPaymentUrlPage('')}><TranslateComponent word={'back'}/></button>
                                <iframe
                                    src={paymentUrlpage}
                                    width="100%"
                                    height="1000px"
                                    title="Content Frame"
                                    style={{ border: 'none' }}
                                ></iframe>
                            </>
                        ) : (
                            <div>
                                <div className="text-5xl font-bold tracking-wide leading-10 text-start text-zinc-800 max-md:max-w-full">
                                <TranslateComponent word={'Donate now'}/>
                                </div>
                                <div className="self-end mt-5 text-base font-medium text-slate-700">
                                <TranslateComponent word={'Enter the donation amount'}/>
                                </div>
                                <div className="flex gap-2 justify-between px-3.5 py-2.5 mt-1.5 text-sm text-start bg-white rounded-lg border border-gray-300 border-solid shadow-sm max-md:flex-wrap max-md:max-w-full">
                                    <input
                                        name="total"
                                        onChange={handleChange}
                                        value={inputs.total}
                                        className="text-neutral-900"
                                        placeholder="00"
                                    />
                                    <InputValidetion msg={errors.total} />
                                    <div className="text-zinc-800">{getCurrencyLocal().name}</div>
                                </div>
                                <div className="self-end mt-12 text-xl font-bold tracking-wide leading-4 text-start text-zinc-800 max-md:mt-10">

                                <TranslateComponent word={'payment methods'}/>
                                </div>
                                {methods?.map((item, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className="flex gap-2.5 justify-between px-4 py-2.5 mt-6 text-sm font-medium text-start bg-white rounded-lg border border-solid border-stone-300 text-zinc-800 max-md:flex-wrap max-md:pr-5"
                                        >
                                            <div className="flex gap-2.5 max-md:flex-wrap">
                                                <input
                                                    onChange={handleChange}
                                                    onClick={() => runForOnline(item.name)}
                                                    value={item.name}
                                                    id="sms"
                                                    type="radio"
                                                    name="methods"
                                                    className="radio"
                                                />
                                                <img
                                                    loading="lazy"
                                                    src={item.image}
                                                    className="shrink-0 my-auto w-9 aspect-[1.49]"
                                                    alt="Pay"
                                                />
                                                <div className="flex-1 max-md:max-w-full">{item.name}</div>
                                            </div>
                                        </div>
                                    );
                                })}
                                {offlineMethods?.map((item, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className="flex gap-2.5 justify-between px-4 py-2.5 mt-4 text-sm font-medium text-start bg-white rounded-lg border border-solid border-stone-300 text-zinc-800 max-md:flex-wrap max-md:pr-5"
                                        >
                                            <div className="flex gap-2.5 max-md:flex-wrap">
                                                <input
                                                    onChange={handleChange}
                                                    onClick={() => {
                                                        runForOffline();
                                                        setMethodId(item.id);
                                                    }}
                                                    value={item.name}
                                                    id="sms"
                                                    type="radio"
                                                    name="methods"
                                                    className="radio"
                                                />
                                                <img
                                                    loading="lazy"
                                                    src={item.image}
                                                    className="shrink-0 my-auto w-9 aspect-[1.49]"
                                                    alt="Pay"
                                                />
                                                <div className="flex-1 max-md:max-w-full">{item.name}</div>
                                            </div>
                                        </div>
                                    );
                                })}
                                <InputValidetion msg={errors.methods} />
                                {optionType !== 2 && (
                                    <div className="self-end mt-12 text-xl font-bold tracking-wide leading-4 text-start text-zinc-800 max-md:mt-10">
                                    <TranslateComponent word={'Payment details'}/>
                                    </div>
                                )}
                                {optionType === 1 && (
                                    <div>
                                        <div className="self-end mt-4 text-base font-medium text-slate-700">

                                    <TranslateComponent word={'card number'}/>

                                        </div>
                                        <div className="justify-center px-3.5 py-2.5 mt-1.5 text-sm leading-6 text-start text-gray-500 bg-white rounded-lg border border-gray-300 border-solid shadow-sm max-md:max-w-full">
                                            <input
                                                type="number"
                                                onChange={handelChangeNumber}
                                                value={cardNumber}
                                                name="cardNumber"
                                                placeholder="211 456 478 222"
                                            />
                                            <InputValidetion msg={cardNumberError} />
                                        </div>
                                        <div className="flex gap-4 mt-4 max-md:flex-wrap">
                                            <div className="flex flex-col flex-1">
                                                <div className="text-base font-medium text-slate-700">

                                                    <TranslateComponent word={'Expiry date'}/>
                                                </div>
                                                <div className="justify-center px-3.5 py-2.5 mt-1.5 text-sm leading-6 text-start text-gray-500 whitespace-nowrap bg-white rounded-lg border border-gray-300 border-solid shadow-sm">
                                                    <input onChange={Changedate} value={expiry} />
                                                    <InputValidetion msg={DateError} />
                                                </div>
                                            </div>
                                            <div className="flex flex-col flex-1 whitespace-nowrap">
                                                <div className="text-base font-medium text-slate-700">CVC</div>
                                                <div className="justify-center px-3.5 py-2.5 mt-1.5 text-sm leading-6 text-start text-gray-500 bg-white rounded-lg border border-gray-300 border-solid shadow-sm">
                                                    <input onChange={hadelChangeCvc} value={cvc} placeholder="***" />
                                                    <InputValidetion msg={cVcError} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="self-end mt-12 text-xl font-bold tracking-wide leading-4 text-start text-zinc-800 max-md:mt-10">

                                            <TranslateComponent word={'Donation details'}/>
                                        </div>
                                        <div className="flex flex-col justify-center py-6 mt-4 text-base tracking-wide leading-4 rounded-lg border-2 border-solid bg-blue-900 bg-opacity-10 border-blue-900 border-opacity-50 max-md:max-w-full">
                                            <div className="flex gap-4 px-4 mt-3 max-md:flex-wrap">
                                                <div className="flex-1 font-medium text-start text-zinc-800"><TranslateComponent word={'Total amount'}/></div>
                                                <div className="font-bold text-zinc-600">
                                                    {inputs.total ? (
                                                        <>
                                                            {inputs.total}
                                                            {getCurrencyLocal().name}
                                                        </>
                                                    ) : (
                                                        <>
                                                            {data?.total}
                                                            {data?.symbol}
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {optionType === 3 && (
                                    <div className="mt-5">
                                        <div>
                                            {offlineMethods.map((item, index) => {
                                                return (
                                                    <div key={index} className="card bg-white shadow-md rounded-md p-6">
                                                        <div className="flex items-center justify-between">
                                                            <img
                                                                className="w-32 h-32 rounded-md object-cover"
                                                                src={item.image}
                                                                alt="Album"
                                                            />
                                                            <div className="ml-4">
                                                                <h2 className="text-xl font-bold text-gray-800">{item.name}</h2>
                                                                <div className="mt-2">
                                                                    <h3 className="text-gray-600 font-semibold">
                                                                        <TranslateComponent word={'bank_name'}/>
                                                                        :
                                                                        <span className="text-gray-800">{item.derails[0].bank_name}</span>
                                                                    </h3>
                                                                    <h3 className="text-gray-600 font-semibold">

                                                                        <TranslateComponent word={'account_name'}/>
                                                                        : <span className="text-gray-800">{item.derails[0].account_name}</span>
                                                                    </h3>
                                                                    <h3 className="text-gray-600 font-semibold">
                                                                        <TranslateComponent word={'account_number'}/>
                                                                        : <span className="text-gray-800">{item.derails[0].account_number}</span>
                                                                    </h3>
                                                                    <h3 className="text-gray-600 font-semibold">
                                                                        <TranslateComponent word={'routing_number'}/>
                                                                        : <span className="text-gray-800">{item.derails[0].routing_number}</span>
                                                                    </h3>
                                                                    <h3 className="text-gray-600 font-semibold">
                                                                            <TranslateComponent word={'iban_number'}/>
                                                                        : <span className="text-gray-800">{item.derails[0].iban}</span>
                                                                    </h3>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                        <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
                                            <div className="col-span-1 lg:col-span-1 pt-8">
                                                <div className="max-w-[9rem] justify-center px-3.5 py-2 mt-3 rounded-lg border border-gray-200 border-solid">
                                                    <FileUploader handleFile={handleFileChange} text={translateText('upload_a_new_file',translations)} />
                                                </div>
                                            </div>
                                            <div className="col-span-1 lg:col-span-1 pt-8">
                                                {selectedFile && selectedFile.type === 'application/pdf' ? (
                                                    <object
                                                        data={selectedFileShow}
                                                        type="application/pdf"
                                                        width="100%"
                                                        height="500px"
                                                        aria-label={translateText("preview_pdf_file",translations)}
                                                    >
                                                        <p><TranslateComponent word={'the_file_cannot_be_displayed_download_it_to_view'}/></p>
                                                    </object>
                                                ) : (
                                                    <img
                                                        src={
                                                            selectedFileShow ??
                                                            'https://cdn.icon-icons.com/icons2/3001/PNG/512/default_filetype_file_empty_document_icon_187718.png'
                                                        }
                                                        alt={translateText("image_preview",translations)}
                                                        className="object-cover w-[50%] h-[50%] rounded-lg shadow-md"
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {loader ? (
                                    <div className="flex justify-center items-center px-3.5 py-2 text-sm font-medium leading-5 bg-amber-300 rounded-lg shadow-sm text-neutral-900 max-md:px-5 max-md:max-w-full">
                                        <span className="loading loading-dots loading-lg"></span>
                                    </div>
                                ) : (
                                    <div
                                        role="button"
                                        onClick={hendelSubmit}
                                        className="flex justify-center items-center px-3.5 mt-4 py-2 text-sm font-medium leading-5 bg-amber-300 rounded-lg shadow-sm text-neutral-900 max-md:px-5 max-md:max-w-full"
                                    >
                                    <TranslateComponent word={'Donate now'}/>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                    <div className="lg:col-span-1 mt-[4.25rem]">
                        <ContactDetails/>
                    </div>
                </div>
            </div>
        </>
    );

}
export default PaymentComponent