import React, {useEffect, useRef} from 'react';
import { toggleDropdown } from '../../Managment/Slice';
import { useDispatch } from 'react-redux';
import { useStateContext } from '../../Providers/ContextProvider';
import {LazyLoadImage} from "react-lazy-load-image-component";

function DropDown({ links, isOpen, dropdownId, onSelect ,setIcon}) {
    const dropLang=useRef(null);
    useEffect(() => {
        const handleClickOutside=(event)=>{
            if(dropLang.current && !dropLang.current.contains(event.target)){
                dispatch(toggleDropdown({ dropdownId }));
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, []);
    const { setSelectedItems } = useStateContext();
    const dispatch = useDispatch();
    const handleClick = (name,lang,icon) => {
        setSelectedItems(prevItems => {
            const newItems = [...prevItems];
            newItems[dropdownId] = name;
            return newItems;
        });
        onSelect(name,lang,icon)
        dispatch(toggleDropdown({ dropdownId }));
    };

    if (!isOpen) return null;

    return (
        <>
            <>
                <div ref={dropLang} id={`dropdownHover${dropdownId}`} className="text-right right-0 absolute top-12 z-10 bg-white dark:bg-white dark:border-gray-400 divide-y divide-gray-100 rounded-lg shadow w-44  max-h-60 overflow-y-scroll">

                    <ul className="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby={`dropdownHoverButton${dropdownId}`}>
                        {links?.map((link, index) => (
                            <li key={index}>

                                <a
                                    onClick={() => { handleClick(link.name ,link.lang,link.icon); setIcon(link.icon); }}
                                    className="flex cursor-pointer  px-4 py-2 hover:bg-gray-600 dark:hover:bg-gray-600 text-black dark:text-black dark:hover:text-white hover:text-white"
                                >
                                    <LazyLoadImage width="30" height="30" className={'mask mask-circle '} src={link.icon} alt={link.name}/>
                                    {link.name}
                                </a>

                        </li>

                        ))}
                    </ul>
                </div>
            </>
        </>
    );
}

export default DropDown;
