import React from "react";
import Galleries from "./Galleries";
import GalleriesForSingle from "./GalleriesForSingle";

const GalleryDeatailsPopup =({
loader,
gallery,
ChangePopupStatus,
})=>{
    const handleLosePopUp = (e) => {
        if (e.target.id === 'ModelContainer') {
            ChangePopupStatus();
        }
    };
    const handleCloseModal = () => {
        ChangePopupStatus();
    };
    return(
        <div
            id='ModelContainer'
            onClick={handleLosePopUp}
            className='fixed inset-0  flex justify-center items-center  backdrop-blur-sm z-30'
        >
            <div className=' w-[90%] h-[50%] flex justify-center p-0 '>
                <div className='w-full flex flex-col items-start bg-plur'>
                    {/* Close button */}
                    <button onClick={handleCloseModal}
                            className=" text-gray-500 hover:text-gray-50 focus:outline-none">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24"
                             stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                  d="M6 18L18 6M6 6l12 12"/>
                        </svg>
                    </button>

                    <GalleriesForSingle loader={loader} gallery={gallery}/>

                </div>
            </div>

        </div>

    )
}
export default GalleryDeatailsPopup;