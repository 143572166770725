import React from 'react';
import { translateText } from '../TranslateComponent';
import { useSelector } from 'react-redux';
function FamilyGuaranteeCard({ title = translateText('Sponsoring an orphan`s family') }) {
        const translations = useSelector((state) => state.Storetranslate.translations);
    const description =`Sponsoring an orphan's family is an opportunity to become a hero in the life of someone in need and change their fate. When you make your contribution, you give hope and support to a family whose members lack care and care. You can be the reason these children's dreams come true and enable them to succeed in their lives.

    Not only does your donation meet basic needs like food and shelter, it gives them opportunities for education and personal growth. Your contribution leads to positive change in society and promotes solidarity and compassion. You will feel proud and happy when you see your positive impact on these people's lives.

    Let us be part of this humanitarian campaign and provide a helping hand to the families of orphans. A small contribution can make a big difference in their lives and give them hope and the opportunity to build towards a better future.`;

    return (
        <div className="container mx-auto py-5 px-2">
            <div className="justify-start px-12 bg-blue-900 rounded-2xl max-md:px-5 mt-10">
                <div className="flex gap-5 max-md:flex-col max-md:gap-0">
                    <div className="flex flex-col justify-center items-start my-auto text-lg font-medium py-2 max-md:mt-10">
                        <h1 className="text-3xl font-bold text-right text-white max-md:w-full">
                            {title}
                        </h1>
                        <p className="mt-5 leading-8 text-right text-slate-100 max-md:w-full">
                            {translateText(description,translations)}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FamilyGuaranteeCard;
