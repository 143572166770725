import React from 'react';

import {Arrow} from "../../Images/svgs";

const DropDown2 = ({ name,path, links }) => {
    if (!links || links.length === 0) {
        return (
            <li>
                <a href={path} className="relative flex flex-row gap-2 items-center">{name}</a>
            </li>
        );
    }
    return (
        <li>
            <a href="#" className="relative flex flex-row gap-2 items-center justify-between z-50">{name} <Arrow/></a>
            <ul className=" relative flex flex-row gap-2 items-center text-black px-4 py-2 border z-50 border-gray-200 rounded-md font-medium  text-sm  dark:text-gray-200">
                {links.map((child, index) => (
                    <DropDown2 key={index} name={child.page?child.page.name:child.text} path={`/${child.page?child.page.slug:child.href}`} links={child.children} />
                ))}
            </ul>
        </li>
    );
};

export default DropDown2;