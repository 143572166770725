import PublicProjectOrphans from "./PublicProjectOrphans";
import Slider from "./Slider";
import React, {useEffect} from "react";

import JoinUsSection from "./JoinUsSection";
import AytemComponent from "./AytemComponent";
import Campaign from "../campagins/Campaign";
import VideoSection from "./VideoSection";
import ProjectsSection from "./ProjectsSection";
import News from "./News";
import AboutCampagin from "../AboutUsComponents/AboutUs/AboutCampagin";
import PublicProjectOrphansPhone from "./PublicProjectOrphansPhone";
import FamilyGuaranteeCard from "./FamilyGuaranteeCard";
import SEOComponent from "../SEOComponent";

const IndexComponent = ()=>{
    useEffect(() => {
        const containerWidth = () => {
            const navContainers = document.querySelectorAll('.nav-container');
            navContainers.forEach((navContainer) => {
                navContainer.classList.remove('container', 'max-w-7xl', 'mx-auto','px-2');
            });
        };
        containerWidth();
    }, []);
    return(
        <>
            <SEOComponent
                title={'Orphans'}
            />
            <div className="container mx-auto py-5 px-2">
                <Slider/>
                <div className={'web'}>
                    <PublicProjectOrphans/>
                </div>

                <div className={'phone'}>
                    <PublicProjectOrphansPhone/>
                </div>

                <FamilyGuaranteeCard/>
                <JoinUsSection/>
                <AytemComponent/>
                <Campaign id={1}/>
                <ProjectsSection/>
                <VideoSection/>
                <News/>
                <AboutCampagin/>
            </div>
        </>
    )
}

export default IndexComponent;