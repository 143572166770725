import React, { useState, useEffect } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { GreenTrue, CartPlus } from '../../Images/svgs';
import axiosClient, {getCurrencyLocal, getToken, truncateText} from "../../axios-client";
import {Oval} from "react-loader-spinner";
import PopupCopmponent from "../PaymentSteps/PopupCopmponent";
import {fetchCreateCart, fetchGetData} from "../Cart/Cart";
import {toast} from "react-toastify";
import LocalCart from "../Cart/LocalCart";
import {useDispatch} from "react-redux";
import {Link} from "react-router-dom";
import TranslateComponent, { translateText } from '../TranslateComponent';
import { useSelector } from 'react-redux';
const Campaign = ({ id }) => {
        const translations = useSelector((state) => state.Storetranslate.translations);
    const [campaignData, setCampaignData] = useState(null);
    const [loading, setLoading] = useState(true);


    const dispatch = useDispatch();

    const [validationMessage, setValidationMessage] = useState('');
    const [isMsgOpen, setIsMsgOpen] = useState(false);
    const {createCart} = LocalCart();


    const handelCreateCart = async (type,cart_id,price) =>{
        if(price){
            await dispatch(fetchCreateCart(type, cart_id, price));
            await dispatch(fetchGetData());
        }else{
            toast.error(translateText('Enter the amount to donate',translations))
        }
    }
    const handelCreateCartLocal = (type,cart_id,price,symbol,image)=>{
        if(price){
            createCart(type,cart_id,price,symbol,image);
        }else{
            toast.error(translateText('Enter the amount to donate',translations))
        }
    }
    const [price ,setPrice]=useState('');
    const [showPopup , setShowPopup] = useState(false);

    const handelChangePrice = (e)=>{
        const {value} = e.target;
        if (/^\d*\.?\d*$/.test(value)) {

            if (value === '') {
                setValidationMessage('Please enter a number.');
                setIsMsgOpen(true);
            } else {
                const num = parseFloat(value);
                if (isNaN(num)) {
                    setValidationMessage('Please enter a valid number.');
                    setIsMsgOpen(true);
                } else if (num <= 0) {
                    setValidationMessage('The number must be greater than zero.');
                    setIsMsgOpen(true);
                } else {
                    setValidationMessage('');
                    setIsMsgOpen(false);

                    setPrice(value);
                    setShowPopup(true);

                    localStorage.setItem('price',value);
                    dispatchEvent(new Event('priceChanged'));
                }
            }



        } else {
            setValidationMessage('Please enter a valid number.');
            setIsMsgOpen(true);
        }
    }
    const [pricePay,setPricePay]=useState('');
    const forPay=(pricePay)=>{
        if(getToken() && getToken()!=='' || getToken() !==null){
            showPopup&&(  document.getElementById('my_modal_4_campaign').showModal() );
            if(pricePay){
                setPricePay(pricePay)
            }else{
                toast.error(translateText('Enter the amount to donate',translations));
            }
        }else{
            toast.info(translateText('You must be logged in',translations));
        }
    }
    window.addEventListener('removeForPayDirect',()=>{
        setPrice('');
    })

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axiosClient.get(`/campaign/${id.campaign??id}`); // Make a GET request to your API endpoint with the ID
                setCampaignData(response.data.data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching campaign data:', error);
            }
        };

        fetchData();

    }, [id]);

    if (loading) {
        return <div className={'flex justify-center p-5 my-10'}>
            <Oval />
        </div> ;
    }

    if (!campaignData) {
        return <div>Campaign not found</div>;
    }

    return (
        <>
            <div className="z-0 flex-col gap-5 justify-between items-end pt-12 mt-[2rem] pr-16 pl-4 w-full min-h-[320px] max-md:flex-wrap max-md:pr-5 max-md:max-w-full flex overflow-hidden relative">
                <LazyLoadImage
                    loading="lazy"
                    src={id?.upload??campaignData.image}
                    className="object-cover absolute inset-0 size-full rounded-lg"
                    alt={'campaign'}
                />
                <div className="flex relative flex-col p-2 mt-14 mr-auto mb-auto bg-white rounded-lg border border-solid border-zinc-300 max-md:mt-10 w-[100%] md:w-[30%]">
                    <div className="flex flex-col p-2">
                        <div
                            className="flex gap-3 justify-between text-xl font-bold  whitespace-nowrap text-zinc-900">
                            <div className="my-auto w-6 aspect-square">
                                <GreenTrue/>
                            </div>

                            <a href={`/campaign/${campaignData.slug}`} className={'grow'}>
                                {campaignData?.name?.length && (
                                    truncateText(campaignData.name, campaignData.name.length > 20 ? 20 : campaignData.name.length)
                                )}
                            </a>
                        </div>
                        <div className='px-2 space-y-4 text-start'>

                            <span
                                className="font-normal text-black dark:text-black  "><TranslateComponent word={'Last donation before'}/>{campaignData.date}</span>
                            <div className="relative w-237 h-2">
                                <progress className="progress progress-primary  w-70 " value={campaignData.percentage}
                                          max="100"></progress>

                            </div>
                            <div className="flex flex-row justify-between px-1">
                                <span className=' font-bol text-black'>{campaignData.target} {campaignData.currency}</span>
                                <span className='font-medium text-black'>{Math.trunc(campaignData.current_value)} {campaignData.symbol}</span>
                            </div>
                        </div>
                    </div>
                    <div className="flex gap-2 justify-between mt-1 ">
                        <div
                            className="grow flex gap-5 justify-between p-1 whitespace-nowrap bg-white rounded-lg border border-solid border-[color:var(--Gray-300,#D0D5DD)]">
                            <input className="my-auto  text-base  text-gray-500 w-[100%] "
                                   value={price}
                                   min={1}
                                   type="number" placeholder={translateText('value',translations)} onChange={handelChangePrice}
                            />
                            <div role="button" onClick={() => forPay(price)}
                                 className="text-center py-2 text-sm font-semibold leading-5 text-white bg-blue-600 rounded-lg border border-blue-500 border-solid shadow-sm  w-[50%]">
                                <TranslateComponent word={'donation'}/>
                            </div>
                        </div>
                        <div
                            className="flex justify-center items-center px-3.5 py-3 bg-white rounded-lg border border-solid shadow-sm   border-[color:var(--Gray-300,#D0D5DD)]">
                            <div className=" w-[21px]" role="button"
                                 onClick={() => {
                                     if (getToken()) {
                                         handelCreateCart('campaign', campaignData.value, price);
                                     } else {
                                         handelCreateCartLocal('campaign', campaignData.value, price,getCurrencyLocal().name,'');
                                     }
                                 }}>
                                <CartPlus />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {showPopup && (
                <PopupCopmponent type={'campaign'} setShowPopup={setShowPopup} projectId={campaignData.value}
                                 symbol={getCurrencyLocal().name}/>
            )}
        </>
    );
};

export default Campaign;
