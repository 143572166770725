import axios from "axios";

const axiosClient = axios.create({
    baseURL:"https://project.alkhair.weblayer.info/api"
})

export const getUrl = ()=>{
    return "https://project.alkhair.weblayer.info/api";
}

export const getCurrentMonth = () => {
    const date = new Date();
    const month = date.toLocaleString(getLocalLang()?getLocalLang().code:'default', { month: 'long' });
    // أو يمكنك استخدام const month = date.getMonth() + 1; لإعادة رقم الشهر (يناير = 1)
    return month;
};
export const getNextMonth = () => {
    const currentDate = new Date();
    const nextMonthDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1);
    const month = nextMonthDate.toLocaleString(getLocalLang()?getLocalLang().code:'default', { month: 'long' });
    return month;
};
export const getDaysInCurrentMonth = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth();

    const nextMonth = new Date(year, month + 1, 0);
    return nextMonth.getDate();
};
export const getNextMonthDayOfMonth=(currentDayOfMonth)=> {
    const today = new Date();
    const nextMonth = new Date(today);
    nextMonth.setMonth(nextMonth.getMonth() + 1);
    nextMonth.setDate(currentDayOfMonth);
    const dayOfMonthNextMonth = nextMonth.getDate();
    return dayOfMonthNextMonth;
}
export const formatDate = (date) => {
    if (!date) return "تاريخ غير صالح";
    const d = new Date(date);
    if (isNaN(d)) return "تاريخ غير صالح";
    const day = (`0${d.getDate()}`).slice(-2);
    const month = (`0${d.getMonth() + 1}`).slice(-2);
    const year = d.getFullYear();
    return `${day}-${month}-${year}`;
};
export const truncateText = (text,length) => {
    if (!text) {
        return '';
    }
    return text.length > length ? text.substring(0, length) + '...' : text;
};
export const getToken = ()=>{
    const token = localStorage.getItem('AccessToken');
    return JSON.parse(token) ;
}
export const getCurrencyLocal = () =>{
    const currency = localStorage.getItem('currency');
    return JSON.parse(currency);
}
export const saveCurrencyLocal=(currency)=>{
    localStorage.setItem('currency', JSON.stringify(currency));
    dispatchEvent(new Event('changedCurrency'));
}
export const savelangLocal=(lang)=>{
    localStorage.setItem('lang', JSON.stringify(lang));
    dispatchEvent(new Event('LangChanged'));
}
export const getLocalLang = ()=>{
    const lang =  localStorage.getItem('lang');
    return lang ? JSON.parse(lang):lang;
}
export const getUser = ()=>{
   const uesrData =  localStorage.getItem('user');
    return JSON.parse(uesrData);
}
export const getCartStatus = () =>{
    const cartData = localStorage.getItem('haveCart');
    return JSON.parse(cartData) ;
}

export const saveUser=(user)=>{
    localStorage.setItem('user',JSON.stringify(user));
}
export const SaveToken=(token,user)=>{
    localStorage.setItem('AccessToken',JSON.stringify(token));
    localStorage.setItem('user',JSON.stringify(user));
}

export const Logout =()=>{
    localStorage.removeItem('AccessToken');
    localStorage.removeItem("user");
}
axiosClient.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('AccessToken');
        const useToken = token ? JSON.parse(token) : '';
        if (useToken) {
            config.headers.Authorization = `Bearer ${useToken}`;
        }
        config.headers['Content-Type'] = 'application/json';
        config.headers['Accept'] = 'application/json';

        if (!config.params) {
            config.params = {};
        }

        const localLang = getLocalLang();
        const localCuurncy = getCurrencyLocal();
        if (localLang && localLang.code) {
            config.params['lang'] = localLang.code;

        }
        if (localCuurncy && localCuurncy.id) {
            config.params['currency'] = localCuurncy.id;
        }


        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);


axiosClient.interceptors.response.use((response) => {

    return response;
}, (error) => {
    const { response } = error;
    if (response.status === 401) {
        localStorage.removeItem('AccessToken')
    }

    throw error;

})

export default axiosClient;