import {Oval} from "react-loader-spinner";

import React, {useEffect} from 'react';

const Loader = () => {
    return (
        <>
            <style>{`body { background-color: #102D71; }`}</style>
            <div className='w-full bg-[#ffffff] h-screen flex items-center justify-center'>
                <Oval
                    visible={true}
                    height="60"
                    width="60"
                    color="#2563eb"
                    ariaLabel="oval-loading"
                    secondaryColor="#2563eb"
                    wrapperStyle={{
                        backgroundImage: 'url(\'https://project.alkhair.weblayer.info/assets/logo/logo-black.png\')',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        width: '260px',
                        height: 'auto',
                        padding: '10px 0',
                    }}
                    wrapperClass="justify-center "
                />
            </div>
        </>
    )
}

export default Loader