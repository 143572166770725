import TranslateComponent from "../../TranslateComponent";

const SubscriptionOrphan =({orders,symbol})=>{
  return(
    <>
      <h1><TranslateComponent word={'SubscriptionOrphan'} /></h1>
     <div className="overflow-x-scroll rounded-xl hide-scroll">
     <table className="w-full whitespace-nowrap ">
          <thead>
              <tr className={'px-5 py-5 font-bold bg-gray-200 rounded-xl border border-solid backdrop-blur-[10px] border-zinc-300 w-full'}>
                  <th className="px-2 py-5 text-start"><TranslateComponent word={'amount'}/></th>
                  <th className="px-2 text-start"><TranslateComponent word={'status'}/></th>
                  <th className="px-2 text-start">ا<TranslateComponent word={'date'}/></th>
              </tr>
          </thead>
          <tbody className={''}>
            {orders?.map((item,index)=>(
              <tr key={index} className={'p-5 mt-2.5 rounded-xl border border-solid backdrop-blur-[10px] bg-neutral-100 border-zinc-300 w-full'}>
                <td className={'px-2 py-5  text-start'}>{item.price}{symbol}</td>
                <td className={`px-2 py-5 `}>
                  <div className={`badge
                    ${item.orderStatus==='SUCCESS'&&'bg-success'}
                    ${item.orderStatus==='WAITING'&&'bg-yellow-500'}
                    ${item.orderStatus==='PENDING'&&'bg-yellow-500'}
                      text-white`}>
                  {item.orderStatus}

                  </div>

                  </td>
                <td className={'px-2 text-start'}>{item.time}</td>
              </tr>
            ))}
          </tbody>
      </table>
     </div>
    </>
  )

  }
  export default SubscriptionOrphan;