import React from 'react';
import ProjectsSecond from "../Projects/ProjectsSecond";

import TranslateComponent from '../TranslateComponent';

const ProjectsSection = () => {
    return (
        <section className="projects" id="projects">
            <div className="container mx-auto px-2">
            <div className="col-lg-12">
                <div className="section-header">
                    <h2 className="title"> <TranslateComponent word={'Our projects for orphans'} /></h2>
                    <h3 className="sub-title"><TranslateComponent word={'You can help a lot of people by donating a little'}/></h3>
                    <hr />
                </div>
            </div>
            <ProjectsSecond url={'orphans/special-project'}/>
            </div>
        </section>
    );
}

export default ProjectsSection;
