import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { translateText } from "../TranslateComponent";
import { useSelector } from 'react-redux';
const LocalCart = () => {
    const [localId, setLocalId] = useState(() => {
        const storedId = localStorage.getItem('idForCart');
        return storedId ? Number(storedId) : 1;
    });
                const translations = useSelector((state) => state.Storetranslate.translations);
    useEffect(() => {
        localStorage.setItem('idForCart', localId.toString());
    }, [localId]);

    const getCart = () => {
        return JSON.parse(localStorage.getItem('carts')) || [];
    }

    const createCart = (type, cart_id, price, symbol, image) => {
        const existingCarts = JSON.parse(localStorage.getItem('carts')) || [];
        const cartIndex = existingCarts.findIndex(cart => cart.type === type && cart.cart_id === cart_id);

        if (cartIndex !== -1) {
            // Cart exists, update the price
            existingCarts[cartIndex].price += price;
            toast.success(translateText('The cart has been updated successfully',translations));
        } else {
            // Cart does not exist, create new
            const newId = localId + 1;
            setLocalId(newId);
            const newCartData = {
                id: newId,
                type: type,
                cart_id: cart_id,
                price: price,
                symbol: symbol,
                image: image
            };

            existingCarts.push(newCartData);
            toast.success(translateText('Added successfully',translations));
        }

        localStorage.setItem('carts', JSON.stringify(existingCarts));
        dispatchEvent(new Event('cartUpdated'));

        try {
            localStorage.setItem('haveCart', '1');
            dispatchEvent(new Event('getCarts'));

        } catch (error) {
            console.error(error);
            toast.error(translateText('Unexpected error, try again and check the data',translations));
        }
    };

    const deleteCart = (id) => {
        const existingCarts = JSON.parse(localStorage.getItem('carts'));
        const updatedCarts = existingCarts.filter(cart => cart.id !== id);
        localStorage.setItem('carts', JSON.stringify(updatedCarts));

        if (updatedCarts.length === 0) {
            localStorage.removeItem('idForCart');
            localStorage.removeItem('carts');
            localStorage.removeItem('haveCart');
            dispatchEvent(new Event('dontHaveCart'));
        }
        dispatchEvent(new Event('deleted'));

        toast.success(translateText('Deleted successfully',translations));
    };

    const deleteAllCart = () => {
        localStorage.removeItem('carts');
        localStorage.removeItem('idForCart');
        localStorage.removeItem('haveCart');
        toast.info(translateText('All items have been removed from the cart',translations));
    }

    return { createCart, getCart, deleteCart, deleteAllCart };
}

export default LocalCart;
