import React, { useState, useEffect } from 'react';
import axiosClient from "../axios-client";
import BlogCard from "./BlogCard";
import TranslateComponent, { translateText } from './TranslateComponent';
import {useSelector} from "react-redux";
function NewsTabs({ categories = [] }) {
    const translations = useSelector((state) => state.Storetranslate.translations);
    const [selectedCategory, setSelectedCategory] = useState(categories[0]?.value || null);
    const [items, setItems] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [loading, setLoading] = useState(false);

    // Function to handle category click and data fetch
    const handleCategoryClick = async (category) => {
        setSelectedCategory(category.value);
        setItems([]);
        setCurrentPage(1);
        await fetchCategoryData(category.value, 1);
    };

    // Function to fetch data based on category value and page
    const fetchCategoryData = async (categoryValue, page) => {
        try {
            setLoading(true);
            // Fetch posts or news items based on the category value and page
            const response = await axiosClient.get(`/posts/${categoryValue}`,{
                params:{
                    limit:5,
                    page:page
                }
            });
            // Update items state with the fetched data
            setItems((prevItems) => [...prevItems, ...response.data.data.data]);
            setTotalPages(response.data.data.pagination.total_pages);
        } catch (error) {
            console.error('Error fetching news:', error);
            setItems([]); // Clear items on error
        } finally {
            setLoading(false);
        }
    };

    // Fetch data for the initial category on component mount
    useEffect(() => {
        if (selectedCategory) {
            fetchCategoryData(selectedCategory, currentPage);
        }
    }, [ currentPage]);

    // Function to load more items
    const loadMoreItems = () => {
        if (currentPage < totalPages) {
            setCurrentPage((prevPage) => prevPage + 1);
        }
    };
    const pathSegments = window.location.pathname.split('/').pop();

    return (
        <div className={`container max-w-7xl mx-auto px-2 ${pathSegments==='orphan'?'':''} `}>
            <div className="grid grid-cols-1 lg:grid-cols-5 l  mt-10 gap-1">

                {pathSegments!=='orphan'&& (
                    <ul className="lg:col-span-1  items-center text-center px-5 py-7 text-lg font-medium bg-white rounded-2xl border border-gray-300 shadow-sm  h-fit cursor-pointer">
                        {categories.map((category, index) => (
                            <li
                                key={index}
                                onClick={() => handleCategoryClick(category)}
                                className={` ${selectedCategory === category.value ? 'bg-blue-500 text-white rounded-lg border border-blue-800' : 'text-black'} ${index !== 0 ? "mt-3" : ""}`}
                            >
                                <TranslateComponent word={category.label}/>
                            </li>
                        ))}
                    </ul>
                )}

                <div className="w-full bg-white rounded-2xl border-2 lg:col-span-4 col-span-1">
                    {items.length > 0 ? (
                        <>
                            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-4 p-5">
                                {items.map((item, index) => (
                                    <BlogCard blog={item} key={`BlogCard_${index}`}/>
                                ))}
                            </div>
                            {currentPage < totalPages && (
                                <div className='flex items-center flex-col mb-5'>
                                    <button
                                        onClick={loadMoreItems}
                                        className='border-2 border-gray-300 rounded-lg px-2 py-1'
                                        disabled={loading}
                                    >
                                        {loading ? translateText('Loading',translations) : translateText('Loade more',translations)}
                                    </button>
                                </div>
                            )}
                        </>
                    ) : (
                        <div className="p-5 text-center text-lg">
                           <TranslateComponent word={'No items found.'}/>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default NewsTabs;
