import React,{useState} from 'react'
import 'react-multi-carousel/lib/styles.css';
import TranslateComponent, { translateText } from './TranslateComponent';
import {useSelector} from "react-redux";
import { getToken } from '../axios-client';
import { toast } from 'react-toastify';
import PopupComponent from './PaymentSteps/PopupCopmponent';
function Campaigns({ project, index }) {
        const translations = useSelector((state) => state.Storetranslate.translations);
        const [price ,setPrice]=useState('');
        const [showPopup , setShowPopup] = useState(false);

        const handelChangePrice = (e)=>{
            const {value} = e.target;

            if (value > 0){
                setPrice(value);
                setShowPopup(true);
                setType('campaign');
                localStorage.setItem('price',value);
                dispatchEvent(new Event('priceChanged'));
            }

        }
        const [pricePay,setPricePay]=useState('');
        const [type,setType]=useState('');
        const forPay=(pricePay)=>{
            // eslint-disable-next-line no-mixed-operators
            if(getToken() && getToken()!=='' || getToken() !==null){
                showPopup&&(  document.getElementById('my_modal_4_campaign').showModal() )
                if(pricePay){
                    setPricePay(pricePay)
                }else{
                    toast.error(translateText('Please enter the donation amount',translations));
                }
            }else{
                toast.info(translateText('You must be logged in',translations))
            }
        }
        window.addEventListener('removeForPayDirect',()=>{
            setPrice('');
        })
    return (

        <>
            <div
                key={index}
                className="col-span-1 overflow-x-auto  p-5 max-w-sm rounded-2xl bg-neutral-50 mb-5">
                <div className="self-center text-sm text-center text-blue-900">
                    {project.country}
                </div>
                <h3 className="mt-5  font-bold text-center text-neutral-900">
                    {project.name}
                </h3>
                <h6 className="mt-5  font-bold text-center text-neutral-900">
                    {project.description}
                </h6>

                <img
                    loading="lazy"
                    srcSet={project.main_image}
                    className="mt-5 w-full aspect-[1.37]"
                    alt={project.name}
                />
                <div className="relative w-237 h-2">
                    <progress className="progress progress-primary  w-70 " value={project.percentage} max="100"></progress>

                </div>
                <h3 className="mt-5  font-bold text-center text-neutral-900">
                    <TranslateComponent word={'Residual'}/> {project.stayValue} {project.symbol}
                </h3>


                <div className="flex gap-2 mt-3.5">
                    <div
                        className="flex flex-1 gap-2 justify-between py-1 pr-2 pl-1 whitespace-nowrap bg-white rounded-lg border border-gray-300 border-solid">
                        <div
                            role="button"
                            onClick={() => {
                                forPay(price)
                            }}
                            className="justify-center px-3.5 py-2 text-sm font-semibold leading-5 text-white bg-blue-600 rounded-lg border border-blue-500 border-solid shadow-sm">

                            <TranslateComponent word={'donation'}/>
                        </div>
                        <div className="my-auto text-base leading-6 text-right text-gray-500">
                            <input
                                className=" w-full outline-none text-lg indent-1 m-1"
                                value={price}
                                onChange={handelChangePrice}
                                type="text" placeholder={translateText("the value",translations)}/>
                        </div>
                    </div>
                </div>

            </div>
            {showPopup&&(
                <PopupComponent type={type} setShowPopup={setShowPopup} projectId={project.id} price={pricePay} symbol={project.symbol} handelChangePrice={handelChangePrice}/>
            )}


        </>
    )
}

export default Campaigns