import React, {useEffect, useState} from 'react'
import { Link, useMatch, useNavigate } from 'react-router-dom'
import {WhatsAppIcon, Arrow, Calculator, GiftIcon, CartIcon, NotificationIcon} from '../../Images/svgs'
import DropDown from '../Partials/DropDown'
import CurrencyDropDown from '../Partials/CurrencyDropDown'
import { useDispatch, useSelector } from 'react-redux';
import { toggleDropdown, setSelectedValue } from '../../Managment/Slice';
import {getCartStatus, getCurrencyLocal, getLocalLang, getToken, getUser} from "../../axios-client";
import {toast} from "react-toastify";
import defult_user from "../../Images/defult_user.png" ;
import $ from 'jquery'
import {LazyLoadImage} from "react-lazy-load-image-component";
import NotificationComponent from "../other/NotificationComponent";
import TranslateComponent, { translateText } from '../TranslateComponent'
function Navbar() {
    const translations = useSelector((state) => state.Storetranslate.translations);
    const Headers = useSelector(state=> state.StoreHeader.Headers);
    const navigate = useNavigate();
    const isHomePage = useMatch('/');
    const dispatch = useDispatch();
    const selectedValues = useSelector(state => state.dropdown.selectedValues);
    const selectedCurrency = useSelector(state => state.dropdown.selectedCurrency);
    const language = useSelector(state => state.Storelang.langs);
    const currencies = useSelector(state=>state.StoreCurrency.currencies);
    const handleSelect = (value, index ,type,code_id,LangIcon_CurValue,code) => {
        dispatch(setSelectedValue({ index, value,type,code_id,LangIcon_CurValue,code }));
        window.location.reload();
    };
    const showlists = useSelector(state => state.dropdown.showlists);
    const toggleDropDown = (index) => {
        dispatch(toggleDropdown({ index }));
    };
    const scrollToDiv = () => {
        navigate('/');
        const timeout = isHomePage ? 0 : 1000;
        setTimeout(() => {
            const targetDiv = document.getElementById('targetDiv');
            if (targetDiv) {
                targetDiv.scrollIntoView({ behavior: 'smooth' });
            }
        }, timeout);
    };
    const IconData = useSelector(state=>state.Storelang.icon)
    const [icon , setIcon] =useState(getLocalLang() && getLocalLang()?.icon?getLocalLang()?.icon:IconData)
    const [symbol,setSymbol] = useState();
    const LocalCurrency = getCurrencyLocal();
    const [cartStatus , setCartStatus]=useState(getCartStatus());
    window.addEventListener('CreateCart',()=>{
        setCartStatus(getCartStatus());
    })
    window.addEventListener('getCarts',()=>{
        setCartStatus(getCartStatus());
    })
    const feachgetCartStatus =() =>{
        setCartStatus(getCartStatus());
    }
    const handelmsg = () =>{
        toast.info(translateText('Add to cart first!',translations));
    }
    useEffect(() => {
        feachgetCartStatus()
    }, []);
    const checkForMiddleware = () => {
        const cartStatus = getCartStatus();
        if (cartStatus === null) {
            handelmsg();
        } else {
            navigate("/cart");
        }
    };
    return (
        <div className='container max-w-7xl mx-auto '>
            <>
                {/* Navigation for medium and larger screens */}
                <div className="lg:flex hidden justify-between mx-auto max-w-screen-xl items-center py-5">
                    {/* First div */}
                    <div>
                        <ul className="flex flex-row gap-2">
                            <WhatsAppIcon />
                            <li><a href="#" className="text-black font-bold">{Headers.phone}</a></li>
                        </ul>
                    </div>
                    {/* Second div */}
                    <div>
                        <ul className=" flex flex-row items-center gap-3">
                            <li className="relative flex flex-row  gap-2  items-center text-black px-4 py-2 border border-gray-200 rounded-md font-medium"
                                onClick={() => toggleDropDown(0)}
                            >
                                <a href="#">{symbol} {LocalCurrency?LocalCurrency.name:selectedCurrency}</a>
                                <Arrow />
                                <CurrencyDropDown setSymbol={setSymbol} links={currencies} isOpen={showlists[0]} onSelect={(value,code_id,Curvalue,code) => handleSelect(value, 0,'currency',code_id,Curvalue,code)} dropdownId={0} />
                            </li>
                            <li onClick={() => toggleDropDown(1)}>
                                <div href="#" className="relative text-black px-4 py-2 border border-gray-200 rounded-md gap-2 flex flex-row items-center">
                                    {/*<LangIcon />*/}
                                    <LazyLoadImage width="30" height="30" className={'mask mask-circle '} src={icon} alt={selectedValues}/>
                                   <a href="#"> <span className='font-medium'>{selectedValues} </span></a>
                                    <Arrow />
                                    <DropDown setIcon={setIcon} links={language} isOpen={showlists[1]} onSelect={(value,lang,icon) => handleSelect(value, 0,'lang',lang,icon)} dropdownId={0} />
                                </div>
                            </li>
                        </ul>
                    </div>
                    {/* Third div */}
                    <div>
                        <ul className="flex flex-row justify-center gap-2">
                            <Link to='/zaka-calculator' className="calc-btn text-black px-4 py-2 border border-black rounded-md flex flex-row items-center hover:bg-black hover:text-white">
                                <Calculator />
                                <span className='font-semibold'><TranslateComponent word={'Zakat calculator'}/></span>
                            </Link>
                            <Link to='/gift' className='flex flex-row justify-center items-center bg-blue-500 rounded-md px-4'>
                                <GiftIcon />
                            </Link>
                            <Link onClick={scrollToDiv} className="text-black px-4 py-2 bg-yellow-400 rounded-md font-semibold">

                                <TranslateComponent word={'Donate now'}/>
                            </Link>

                        </ul>
                    </div>
                    {/* Fourth div */}
                    <div>
                        <ul className="flex flex-row gap-2">

                            {getToken() ? (
                                <li className="text-black px-4 py-2 border border-gray-200 rounded-md flex flex-row items-center">
                                    <NotificationComponent/>
                                </li>

                            ):''}

                            <li className="text-black px-4 py-2 border border-gray-200 rounded-md flex flex-row items-center indicator">
                                {cartStatus ? (
                                    <Link to="/cart" onClick={checkForMiddleware}>
                                        <span class="indicator-item badge-cart badge-cart-notify"></span>
                                        <CartIcon/>
                                    </Link>
                                ) : (
                                    <button onClick={handelmsg}>
                                        <CartIcon/>
                                    </button>
                                )}
                            </li>
                            <li className="text-white px-4 py-2 bg-black rounded-md font-semibold"><Link to={'/volunteer-form'} ><TranslateComponent word={'join us'}/></Link></li>
                            {getToken()?(
                                <Link
                                    to={'/profile'}
                                    className="flex gap-2 px-1.5 py-1 text-sm font-semibold leading-5 text-right text-black rounded-lg border border-solid shadow-sm bg-zinc-100 border-zinc-400 max-md:pl-5">
                                    <img
                                        loading="lazy"
                                        srcSet={getUser().avatar ?? defult_user}
                                        className="shrink-0 aspect-square  w-[30px] mask mask-squircle object-cover"
                                        alt={'avatar'}
                                        />
                                    <div className="my-auto text-ellipsis">{getUser().name}</div>

                                </Link>
                            ) : (
                                <li className="text-white px-4 py-2 bg-blue-700 rounded-md font-semibold">
                                    <Link to="/login">
                                        <button>
                                             <TranslateComponent word={'Log in'}/>
                                        </button>
                                    </Link>
                                </li>
                            )}
                            <a href="#"><TranslateComponent word={''}/></a>
                        </ul>
                    </div>
                </div>


            </>
        </div>

    )
}


export default Navbar
