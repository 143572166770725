import React, { useState } from 'react'
import axiosClient, { getLocalLang } from '../axios-client';
import 'reactjs-popup/dist/index.css';
import Galleries from "./Media/Galleries";
import Videos from "./Media/Videos";
import TranslateComponent from './TranslateComponent';

function SliderShow() {
    const [galleries, setGallery] = useState([]);
    const [firstThree, setFirstThree] = useState([]);
    const [remaining, setRemaining] = useState([]);
    const [page, setPage] = useState(1);
    const [loadData, setLoadData] = useState(true);
    const [pageTotal, setPageTotal] = useState(1);
    const [loader,setLoader]=useState(false);
    const [showList,setShowList]=useState(false);
    const[videos,setVideos]=useState([])
    const [activeSection,setActiveSection]=useState('galleries');
    const [isPageHome,setIsPageHome]=useState(false);
    const [lastGalley,setLastGallry]=useState('');

    console.log(remaining);
    const getGalleries = (page = null) => {
        setLoader(true);
        axiosClient.get('galleries', {
            params: {
                limit: 7,
                page: page,
                lang: getLocalLang() && getLocalLang().code
            }
        })
            .then(response => {
                const allData = response.data.data.data;
                const currentPath = window.location.href;
                const parts = currentPath.split('/');
                const lastPart = parts[parts.length - 1];

                if (lastPart === '' || lastPart === 'home' && allData.length > 6) {
                    setGallery(allData.slice(0, 6));
                    setLastGallry(allData[6]);
                    setLoader(false);
                    setIsPageHome(false)
                }else{
                    setPageTotal(response.data.data.pagination.total_pages);
                    setGallery(prevData => [...prevData, ...allData]);
                    setLoader(false);
                    setIsPageHome(true)
                }

            })
            .catch(error => {
                console.log(error);
                setLoader(false);
            })
    }
    const getVideos = (page = null) => {
        setLoader(true);
        axiosClient.get('videos', {
            params: {
                limit: 7,
                page: page,
                lang: getLocalLang() && getLocalLang().code
            }
        })
            .then(response => {
                const allData = response.data.data;
                // setPageTotal(response.data.data.pagination.total_pages);
                setVideos(prevData => [...prevData, ...allData]);
                setLoader(false);

            })
            .catch(error => {
                console.log(error);
                setLoader(false);
            })
    }
    const handleLoadMore = () => {
        const nextPage = page + 1;
        setPage(nextPage);
        if(activeSection==='galleries') {
            getGalleries(nextPage);
        }else{
            getVideos(nextPage)
        }
    }
    const ShowSome =()=>{
        setPage(1);
        if(activeSection==='galleries'){
            setGallery([]);
            getGalleries(1);
        }else{
            setVideos([]);
            getVideos(1)
        }
    }
    const handelActiveSection=(name)=>{
        setActiveSection(name);
    }
    const toggleShowList = () => {
        setShowList(!showList);
    };
    console.log(lastGalley);
    const PaginationStatus =activeSection==='galleries'?galleries && galleries.length>0 && page<pageTotal:videos && videos.length>0 && page<pageTotal;
    return (
        <div className='container max-w-7xl mx-auto mt-8'>
            <div className="flex flex-col px-5">
                <div className="flex flex-col">
                    <div className="self-start text-lg md:text-5xl font-bold   text-zinc-900">
                        <TranslateComponent word={'Media Center'}/>
                    </div>
                    <div className="flex gap-5 justify-between mt-8 w-full max-md:flex-wrap max-md:max-w-full">
                        {isPageHome &&(

                            <div className="flex gap-0 text-lg leading-6 text-right whitespace-nowrap rounded-2xl border border-gray-300 border-solid shadow-sm">
                                <div
                                    role={'button'}
                                    onClick={()=>handelActiveSection('galleries')}
                                    className={`flex-1 justify-center px-4 py-2.5 font-medium text-black rounded-2xl  border-r${activeSection==='galleries'&&'border-gray-300 bg-slate-200'}  border-solid max-md:px-5`}>
                                    <TranslateComponent word={'photo'}/>
                                </div>
                                <div
                                    role={'button'}
                                    onClick={()=>handelActiveSection('videos')}
                                    className={`flex-1 justify-center px-4 py-2.5 font-bold text-blue-900 rounded-2xl  border-r${activeSection==='videos'&&'border-gray-300 bg-slate-200'} border-solid  max-md:px-5`}>
                                    <TranslateComponent word={'video'}/>
                                </div>
                            </div>
                        )}
                        {/* <div
                            role={'button'}
                            onClick={toggleShowList}
                            className="flex gap-2 justify-center self-start px-3.5 py-2 mt-2.5 text-sm font-medium leading-5 bg-white rounded-lg border border-gray-300 border-solid shadow-sm text-neutral-900">
                                <button className="flex-row gap-2 text-black font-medium md:font-medium rounded-lg text-sm text-center inline-flex items-center outline-none">
                                    <FilterIcon/>

                                    <TranslateComponent word={'Classification by'}/>

                                    <Arrow/>
                                </button>
                                {showList && (
                                    <div className="relative">
                                        <div className="fixed inset-0 z-50 flex items-center justify-center">
                                            <div className="absolute inset-0 bg-gray-900 opacity-50"></div>
                                            <div className="z-50 bg-white rounded-lg p-8 max-w-xl w-[95%] max-h-[80vh] overflow-y-scroll">
                                                <PopupFilter
                                                    toggleShowList={toggleShowList}
                                                />
                                            </div>
                                        </div>

                                    </div>
                                )}
                        </div> */}
                    </div>
                </div>
                    {activeSection==='galleries'&&(
                        <Galleries
                            data={galleries}
                            firstThree={firstThree}
                            remaining={remaining}
                            loadData={loadData}
                            setFirstThree={setFirstThree}
                            setRemaining={setRemaining}
                            setLoadData={setLoadData}
                            setLoader={setLoader}
                            getGalleries={getGalleries}
                            activeSection={activeSection}
                            lastGalley={lastGalley}
                            isPageHome={isPageHome}
                        />
                    )}
                    {activeSection==='videos'&&(
                        <Videos
                        videos={videos}
                        loadData={loadData}
                        setVideos={setVideos}
                        setLoadData={setLoadData}
                        setLoader={setLoader}
                        getVideos={getVideos}
                        activeSection={activeSection}
                        />
                    )}
                    {/*for first*/}
                    {loader? (
                        <div className="flex justify-center items-center">
                            <span className="text-black loading loading-dots loading-lg"></span>
                        </div>
                    ): (
                        <div className="flex justify-center mt-5">
                            <div>
                                {PaginationStatus&& (
                                    <div className="text-center">
                                        <button className="border rounded-lg px-5 py-3 mx-4"
                                                onClick={handleLoadMore}>
                                        <TranslateComponent word={'Show more'}/>


                                        </button>
                                    </div>
                                )}
                            </div>
                            <div>
                                {page > 1 && (
                                    <div className="text-center">
                                        <button className="border rounded-lg px-5 py-3" onClick={ShowSome}>
                                            <TranslateComponent word={'Show a little'}/>
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
            </div>
        </div>

)
}

export default SliderShow