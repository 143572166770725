import React, { useState } from 'react';
import {RangeSlider, defaultTheme, Provider} from '@adobe/react-spectrum'

const Card=({ title, children }) =>{
    return (
        <div className="p-2">
            <div className="p-4  bg-white shadow-md rounded-md inline-block w-full">
                <div className="px-3 font-bold text-xl pb-4">{title}</div>
                {children}
            </div>
        </div>
    );
}
const Marker=({ value })=> {
    return (
        <div
            className="absolute grid place-items-center"
            style={{
                width: "24px",
                height: "24px",
                top: "-40px",
                right: `${value * 3}px`,
            }}
        >
            <div className="relative w-full h-full text-sm flex items-center ">
                <div
                    className="absolute grid place-items-center text-white font-semibold rounded-md"
                    style={{
                        left: "50%",
                        top: "50%",
                        transform: "translate(-50%, -50%)",
                        background: "#424e82",
                        width: "50px",
                        height: "30px",
                    }}
                >
                    ${value }
                </div>
                <div
                    className="absolute"
                    style={{
                        left: "50%",
                        top: "150%",
                        transform: "translate(-50%, -50%)",
                        width: "15px",
                        height: "30px",
                    }}
                >
                    <svg
                        className="w-full"
                        viewBox="0 0 22 30"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M12.874 26.6557C12.3017 28.5519 9.61685 28.5519 9.04458 26.6557L0.999992 0H20.9186L12.874 26.6557Z"
                            fill="#424e82"
                        />
                    </svg>
                </div>
            </div>
        </div>
    );
}
const RangePrice = ({
startValue,
endValue,
maxValue,
setValue,
value
}) => {

        return (
        <Provider theme={defaultTheme} colorScheme="light">
            <RangeSlider
                label="نطاق السعر"
                style={{width:"100%",marginTop:"50px"}}
                formatOptions={{style: 'currency', currency: 'USD'}}
                value={value}
                onChange={setValue}
                maxValue={maxValue}
                 />
        </Provider>

        );
};

export default RangePrice;
