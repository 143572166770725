import React, {useEffect, useRef, useState} from 'react';
import {Link, useLocation} from 'react-router-dom';
import {WebsiteLogo, SearchIcon, CartIcon, Calculator, Arrow} from '../../Images/svgs';
import axiosClient, {getToken, getUser, truncateText} from '../../axios-client';
import {useDispatch, useSelector} from 'react-redux';
import DropDown2 from "../Partials/DropDown2";
import {feachSerch, handelSerchStatus} from "../../Managment/SerchResult";
import defult_user from "../../Images/defult_user.png";
import CurrencyDropDown from "../Partials/CurrencyDropDown";
import {LazyLoadImage} from "react-lazy-load-image-component";
import DropDown from "../Partials/DropDown";
import ButtonLangCurrency from "../../Managment/ButtonLangCurrency";
import SearchComponent from "../other/SearchComponent";
import TranslateComponent from '../TranslateComponent';
import { updateLoaderPageDownload } from '../../Managment/LoaderPageDownload';
import SliderPhone from "./sliderPhone";

function SecondNavbar() {
    const Headers =useSelector(state=>state.StoreHeader.Headers);
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const location = useLocation();

    return (
        <>
            <nav
                className="relative z-1  block w-full px-4 py-2 text-white bg-white backdrop-blur-2xl backdrop-saturate-200 lg:px-8 lg:py-4 second-navbar navbar-full-width">
                <div className="container max-w-7xl mx-auto flex items-center justify-between  text-blue-gray-900">
                    <Link to="/">

                        <LazyLoadImage src={Headers.logo_header ?? 'https://alkhaircharity.org/img/logo.png'}
                                       className={'h-[60px] w-auto'} alt='logo'/>

                    </Link>
                    <div className="hidden lg:block main-menu">
                        <ul className="flex flex-col gap-2 mt-2 mb-4 lg:mb-0 lg:mt-0 lg:flex-row lg:items-center lg:gap-6">
                            {Headers?.header && Object.entries(Headers?.header).map(([key, value], index) => (
                                !Array.isArray(value?.children) ? (
                                    <li className={`flex items-center p-1 font-sans text-sm antialiased font-medium leading-normal gap-x-2 text-blue-gray-900 ${location.pathname === value.href ? 'active' : ''}`}
                                        key={index}>
                                        <div className="grow">
                                            <Link to={`/${value.page ? value.page.slug : value.href}`}>
                                                {value.page ? value.page.name : value.text}

                                            </Link>
                                        </div>
                                    </li>
                                ) : (
                                    <DropDown2 name={value.page ? value.page.name : value.text}
                                               path={value.page ? value.page.slug : value.href} links={value.children}
                                               key={index}/>
                                )
                            ))}
                        </ul>
                    </div>
                    <div className="items-center gap-x-1 hidden md:flex lg:flex xl:flex">
                        <SearchComponent/>
                    </div>

                    <button
                        className={`relative ${document.documentElement.getAttribute('dir') === 'ltr' ? 'ml-7' : 'mr-7'}  h-6 max-h-[40px] w-6 max-w-[40px] select-none rounded-lg text-end align-middle font-sans text-xs font-medium uppercase text-inherit transition-all hover:bg-transparent focus:bg-transparent active:bg-transparent disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none lg:hidden`}
                        type="button"
                        onClick={() => setSidebarOpen(true)}
                    >
                    <span className="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
                        <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none" stroke="currentColor"
                             strokeWidth="2">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16"></path>
                        </svg>
                    </span>
                    </button>
                </div>

                {/* Sidebar for mobile view */}

            </nav>

            <div className={`fixed inset-0 z-50 bg-black bg-opacity-50  transition-opacity ${sidebarOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}>
                <div
                    className={`fixed inset-y-0 left-0 w-[85%] bg-white shadow-lg   transform transition-transform ${sidebarOpen ? 'translate-x-0' : '-translate-x-full'}`}>
                    <div className="flex items-center justify-between p-4">
                        <LazyLoadImage src={Headers.logo_header} alt='logo' className={'h-[50px]'}/>
                        <button
                            className="text-gray-500"
                            onClick={() => setSidebarOpen(false)}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none"
                                 stroke="currentColor" strokeWidth="2">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12"></path>
                            </svg>
                        </button>

                    </div>
                    <hr/>
                    <div className={'grid grid-cols-1 overflow-y-scroll overflow-x-hidden h-[75vh]'}>
                        <SliderPhone header={Headers}/>
                    </div>
                </div>
            </div>

        </>
    );
}

export default SecondNavbar;
