import React from "react";
import {Link} from "react-router-dom";
import { Helmet } from "react-helmet";

const colorClasses = [
    'text-pink-800 bg-red-100 border-pink-800',
    'text-blue-800 bg-blue-100 border-blue-800',
    'text-green-800 bg-green-100 border-green-800',
    'text-yellow-800 bg-yellow-100 border-yellow-800',
];
const getRandomColorClass = () => {
    const randomIndex = Math.floor(Math.random() * colorClasses.length);
    return `relative px-2.5 py-1 rounded-xl border-solid badge ${colorClasses[randomIndex]}`;
};
const BlogCard= ({blog,index})=>{
    return (

        <>


            <Link to={`/blog/${blog.slug}`}
                key={blog.value}
                className="col-span-1 w-full ml-5 max-md:ml-0 max-md:w-full"
            >
                <div
                    className="flex grow gap-1 justify-between px-2.5 py-2 w-full bg-white rounded-2xl border border-solid border-zinc-300 max-md:mt-5">
                    <div
                        className="flex overflow-hidden relative flex-col items-start pt-5 pr-5 pb-12 pl-16 text-sm font-medium tracking-normal leading-3 text-center text-pink-800 uppercase whitespace-nowrap rounded-xl aspect-[1.22] w-[183px] max-md:pl-5">
                        <img
                            loading="lazy"
                            srcSet={blog.image}
                            alt={blog.label}
                            className="object-cover absolute inset-0 size-full"
                        />
                        <span className={getRandomColorClass()}>
                                            {blog.category}
                                        </span>
                    </div>
                    <div
                        className="flex flex-col flex-1 justify-center px-4 py-1.5  bg-white bg-opacity-70">
                        <h4 className=" font-bold text-zinc-900">
                            {blog.label}
                        </h4>
                        <h6 className="self-start mt-3 text-sm whitespace-nowrap text-stone-900">
                            {blog.date}
                        </h6>
                    </div>
                </div>
            </Link>
        </>
    )
}
export default BlogCard;