import React, { useEffect, useState } from 'react';
import axiosClient, { getCurrencyLocal, getToken } from "../../axios-client";
import { CartIcon, GreenTrue, MinusCircle, PlusCircle } from "../../Images/svgs";
// import PopupComponent from "../PaymentSteps/PopupComponent";
import { toast } from "react-toastify";
import { fetchCreateCart } from "../Cart/Cart";
import { useDispatch } from "react-redux";
import LocalCart from "../Cart/LocalCart";
import { LazyLoadImage } from "react-lazy-load-image-component";
import TranslateComponent, { translateText } from '../TranslateComponent';
import { useSelector } from 'react-redux';
const PublicProjectOrphans = () => {
        const translations = useSelector((state) => state.Storetranslate.translations);
    const [taps, setTaps] = useState([]);
    const [activeIndex, setActiveIndex] = useState(null);
    const [price, setPrice] = useState('');
    const [showPopup, setShowPopup] = useState(false);
    const [pricePay, setPricePay] = useState('');
    const [type, setType] = useState('');

    const dispatch = useDispatch();
    const { createCart } = LocalCart();

    const toggleAccordion = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    const getTaps = () => {
        axiosClient.get('/orphans/public-project')
            .then(response => {
                setTaps(response.data.data);
                setActiveIndex(null);
            })
            .catch(error => {
                console.log(error);
            });
    };

    useEffect(() => {
        getTaps();
    }, []);

    const handleChangePrice = (e) => {
        const { value } = e.target;
        setPrice(value);
        setShowPopup(true);
        setType('publicProjects');

        localStorage.setItem('price', value);
        dispatchEvent(new Event('priceChanged'));
    };

    const handelChangePrice = (e)=>{
        const {value} = e.target;
        setPrice(value);
        setShowPopup(true);
        setType('publicProjects');

        localStorage.setItem('price',value);
        dispatchEvent(new Event('priceChanged'));
    }
    const forPay = (pricePay) => {
        if (getToken()) {
            showPopup && document.getElementById('my_modal_4_publicProjects').showModal();
            if (pricePay) {
                setPricePay(pricePay);
            } else {
                toast.error(translateText('Please enter the donation amount',translations));
            }
        } else {
            toast.info(translateText('You must be logged in',translations));
        }
    };

    window.addEventListener('removeForPayDirect', () => {
        setPrice('');
    });

    const handelCreateCart = async (type,cart_id,price) =>{
        if(price){
            await dispatch(fetchCreateCart(type, cart_id, price));
        }else{
            toast.error(translateText('Enter the amount to donate',translations))
        }
    }
    const handelCreateCartLocal = (type,cart_id,price,symbol,image)=>{
        if(price){
            createCart(type,cart_id,price,symbol,image);
        }else{
            toast.error(translateText('Enter the amount to donate',translations))
        }
    }

    return (
        <div className="flex flex-col">
            <div className="w-full max-md:max-w-full">
                <div className="grid grid-cols-1 gap-5 max-md:flex-col max-md:gap-0">
                    <div className="cols-span max-md:ml-0 max-md:w-full">
                        <div className="flex flex-col grow text-right text-neutral-900 max-md:mt-6 max-md:max-w-full gap-5">
                            {taps.map((manualItem, index) => (
                                <div key={manualItem.id} className="border border-gray-300 rounded-lg ">
                                    <h2 className="accordion-header">
                                        <button
                                            className={`accordion-button flex justify-between w-full py-3 px-4 text-left text-lg font-semibold transition text-black rounded-t-lg ${activeIndex === index ? 'bg-blue-200 dark:bg-blue-200' : 'bg-white dark:bg-white collapsed rounded-b-lg'}`}
                                            type="button"
                                            aria-expanded={activeIndex === index}
                                            onClick={() => toggleAccordion(index)}
                                            aria-controls={manualItem.id}
                                        >
                                            <div  className={'flex'}>
                                                <LazyLoadImage src={manualItem.mainImage}  width={30} height={30} className={'object-cover'} alt={manualItem.name}/>
                                               <span className={'mx-2'}> {manualItem.name}</span>
                                            </div>
                                            {activeIndex === index ? <MinusCircle /> : <PlusCircle />}
                                        </button>
                                    </h2>
                                    <div
                                        id={manualItem.id}
                                        className={`accordion-collapse transition-max-height duration-300 text-black rounded-b-lg ${activeIndex === index ? 'bg-blue-200 dark:bg-blue-200 max-h-96' : 'bg-white dark:bg-white max-h-0 overflow-hidden'}`}
                                        data-bs-parent={`#${manualItem.id}`}
                                    >
                                        <div className="accordion-body p-4">
                                            <div dangerouslySetInnerHTML={{__html: manualItem.description}}/>


                                            <span
                                                className="font-normal text-black dark:text-black  "><TranslateComponent word={'Last donation before'} />{manualItem.created_at}</span>
                                            <div className="relative w-237 h-2 my-3">
                                                <progress className="progress progress-white  w-70 "
                                                          value={manualItem.percentage} max="100"></progress>

                                            </div>
                                            <div className="flex flex-row justify-between px-1">
                                                        <span
                                                            className=' font-bol text-black'>{manualItem.project_value} {manualItem.symbol}</span>
                                                <span
                                                    className='font-medium text-black'>{Math.trunc(manualItem.current_value)} {manualItem.symbol}</span>
                                            </div>

                                            <div className="flex flex-col bg-gray-50 rounded-lg p-2 mt-3">
                                                <div
                                                    className="flex flex-row items-center gap-1 mx-auto my-auto w-full sm:w-full md:w-full">
                                                    <div className={' rounded-lg border-2 flex'}>
                                                        <input
                                                            className=" w-full outline-none text-lg indent-1 m-1"
                                                            value={price}
                                                            type="number" placeholder={translateText('value',translations)}
                                                            onChange={handelChangePrice}/>


                                                        <span
                                                            className="flex items-center rounded rounded-l-none border-0 p-1 font-bold text-grey-100">
                                                        <button
                                                            className="bg-blue-700 text-white text-sm py-2 px-4 rounded"
                                                            onClick={() => forPay(price)}>
                                                                <TranslateComponent word={'donation'} />
                                                        </button>
                                                    </span>
                                                    </div>

                                                    <span className=' rounded-lg '
                                                          role="button"
                                                          onClick={() => {
                                                              if (getToken()) {
                                                                  handelCreateCart('publicProjects', manualItem?.id, price);
                                                              } else {
                                                                  handelCreateCartLocal('publicProjects', manualItem?.id, price, getCurrencyLocal().name, manualItem?.mainImage);
                                                              }
                                                          }}
                                                    >
                                                       <CartIcon/>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </div>
                                ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PublicProjectOrphans;
