import ContactDetails from "../ContactDetails";
import {useRef, useState} from "react";
import {getToken, getUrl} from "../../axios-client";
import {toast} from "react-toastify";
import InputValidetion from "../InputValidetion";
import * as React from "react";
import DatePicker from "react-datepicker";
import FileUploader from "../FileUploader";
import axios from "axios";
import { format  } from 'date-fns';
import TranslateComponent, { translateText } from "../TranslateComponent";
import { useSelector } from 'react-redux';
import SEOComponent from "../SEOComponent";
const PartnerForm = ()=>{
    const [date, setDate] = useState(new Date());
    const translations = useSelector((state) => state.Storetranslate.translations);

    const timeoutId = useRef(null);
    const [TrueEmailValidate,setTrueEmailValidate]=useState('');
    const [selectedFileShow, setSelectedFileShow] = useState();
    const [selectedFile, setSelectedFile] = useState(null);
    const handleFileChange = (fileUploaded) => {
        if(fileUploaded){
            setSelectedFileShow(URL.createObjectURL(fileUploaded));
            setSelectedFile(fileUploaded);
        }
    };
    const [inputs,setInputs]=useState({
        name:"",
        phone:"",
        email:"",
        address:"",
        postaCode:"",
        senderName:"",
        textContent:""
    });
    const [errors, setErrors] = useState({
        name:"",
        phone:"",
        email:"",
        address:"",
        postaCode:"",
        senderName:"",
        textContent:""
    });
    const [ApprovalCheck , setCheck]=useState(false);

    const hendelChangeCheckBox = (e)=>{
        const { name, value, type, checked } = e.target;
        setCheck(type === 'checkbox' ? checked : value);
    }
    const handelChange = (e) => {
        const { name, value } = e.target;
        if (timeoutId.current) {
            clearTimeout(timeoutId.current);
        }
        timeoutId.current = setTimeout(() => {
            if (name === 'email') {
                if (value === '') {
                    setTrueEmailValidate('');
                } else if (!value.includes('@')) {
                    setTrueEmailValidate(translateText('Complete the email correctly: @',translations));
                } else {
                    setTrueEmailValidate('');
                }
            }
        }, 500);

        setInputs(prevData => ({
            ...prevData,
            [name]: value
        }));
        if (value.trim()) {
            setErrors(prevErrors => ({
                ...prevErrors,
                [name]: ""
            }));
        }
    }

    const handelSubmit=()=>{

        if(Object.values(inputs).every(input => input !== "")) {
            if (inputs.email.includes('@')){
                const config = {
                    headers:{
                        'Authorization':getToken(),
                        'Content-Type':'multipart/form-data',
                        'Accept':'application/json'
                    }
                }
                const formattedDate = format(date, 'dd/MM/yyyy');

                const formData = new FormData();
                formData.append('name',inputs.name);
                formData.append('classification','No Data');
                formData.append('founding_date',formattedDate);
                formData.append('phone',inputs.phone);
                formData.append('email',inputs.email);
                formData.append('address',inputs.address);
                formData.append('postal_number',inputs.postaCode);
                formData.append('partnership',inputs.textContent);
                formData.append('file',selectedFile);
                formData.append('name_applicant',inputs.senderName);

                axios.post(getUrl()+'/partners',formData,config)
                    .then(response=>{
                        toast.success(translateText('Sent successfully',translations));
                    })
                    .catch(error=>{
                        console.log(error)
                        if(error?.response?.data?.message){
                            toast.error(translateText(error?.response?.data?.message,translations));
                        }
                    })
            }else {
                toast.error(translateText('please Inter Regit Email',translations))

            }
        }else{
            const newErrors = {};
            Object.entries(inputs).forEach(([name, value]) => {
                if (!value.trim()) {
                    newErrors[name] = translateText("Field is required",translations);
                } else {
                    newErrors[name] = "";
                }
            });
            setErrors(newErrors);
        }
    }
    return (

        <>

        <SEOComponent
            title={'Parnter Form'}
        />
        <div className="flex flex-col px-5 my-[5rem]">
            <div className="self-start text-5xl font-bold text-start text-zinc-900">
                <TranslateComponent word={'Become a partner'}/>{" "}
            </div>
            <div className="mt-5 w-full text-lg text-start text-zinc-900 max-md:max-w-full">
                <TranslateComponent word={'We invite organizations and institutions to join the Al-Khair Foundation as a partner in Achieving positive impact and providing support to those in need in our communities. Together, we can Making a real difference and building a better world for everyone.'}/>
            </div>
            <div className="mt-11 w-full max-md:mt-10 max-md:max-w-full">
                <div className="flex gap-5 max-md:flex-col max-md:gap-0">
                    <div className="flex flex-col ml-5 w-[58%] max-md:ml-0 max-md:w-full">
                        <div
                            className="flex flex-col grow self-stretch py-8 w-full bg-white rounded-lg border border-solid border-neutral-200 max-md:mt-5 max-md:max-w-full">
                            <div
                                className="flex flex-col items-start px-8 text-base font-medium text-slate-700 max-md:px-5 max-md:max-w-full">
                                <div className="text-2xl font-bold text-start text-black">

                                    <TranslateComponent word={'Join us as a partner'}/>
                                </div>
                                <div
                                    className="self-stretch mt-2 font-light text-start text-neutral-500 max-md:max-w-full">
                                        <TranslateComponent word={'In accordance with UK Charity Commission guidelines Related to partnerships, we need to know more about your organization And the proposed details of the partnership.'}/>
                                </div>
                                <div className="mt-10"><TranslateComponent word={'Company name'}/></div>
                                <input
                                    className="justify-center self-stretch px-3.5 py-2.5 mt-1.5 text-sm leading-6 text-start text-gray-500 bg-white rounded-lg border border-gray-300 border-solid shadow-sm max-md:max-w-full"
                                    name="name"
                                    value={inputs.name}
                                    onChange={handelChange}
                                    placeholder={translateText('Please write the name of the organization here',translations)}
                                />
                                <InputValidetion msg={errors.name}/>
                                <div className="mt-6"><TranslateComponent word={'Institution classification'}/></div>
                                <div
                                    className="flex gap-2 self-stretch px-3.5 py-2.5 mt-1.5 text-sm leading-6 text-start text-gray-500 bg-white rounded-lg border border-gray-300 border-solid shadow-sm max-md:flex-wrap">
                                    <div className="flex-1 max-md:max-w-full">
                                       <TranslateComponent word={'Please select an organization classification'}/>
                                    </div>
                                    <img
                                        loading="lazy"
                                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/4cd540013278e9508615c87f31c47d39b444cd1d16eae81e9a9b36845244e08e?apiKey=0ea99ad6805e4b00a629c3d17bcd7717&"
                                        className="shrink-0 my-auto w-5 aspect-square"
                                        alt={'partner'}
                                        />
                                </div>
                                <div className="mt-6"><TranslateComponent word={'Date of Establishment'}/></div>
                                <div
                                    className="flex flex-col justify-center self-stretch px-3.5 py-2.5 mt-1.5 text-sm leading-6 text-start text-gray-500 whitespace-nowrap bg-white rounded-lg border border-gray-300 border-solid shadow-sm max-md:max-w-full">
                                    <div className="flex gap-2 max-md:flex-wrap">
                                        <div className="flex-1 max-md:max-w-full">
                                            <DatePicker
                                                selected={date}
                                                dateFormat="dd/MM/yyyy"
                                                onChange={(date) => setDate(date)}/>
                                        </div>
                                        <img
                                            loading="lazy"
                                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/df5656ebae8aa022ae286701f85266fa2823893048ffd1f80c594360155af511?apiKey=0ea99ad6805e4b00a629c3d17bcd7717&"
                                            className="shrink-0 w-6 aspect-square"
                                            alt={'partner'}
/>
                                    </div>
                                </div>
                                <div className="mt-6"><TranslateComponent word={'phone number'}/></div>
                                <div className="flex gap-2 self-stretch px-3.5 py-2.5 mt-1.5 text-sm leading-6 text-start text-gray-500 bg-white rounded-lg border border-gray-300 border-solid shadow-sm max-md:flex-wrap">

                                    <input
                                        type="number"
                                        name="phone"
                                        value={inputs.phone}
                                        onChange={handelChange}
                                        className="flex-1 max-md:max-w-full"
                                        placeholder={translateText('Write your phone number here',translations)}
                                    />
                                    <img
                                        loading="lazy"
                                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/aa1062db4026b3413a62e8fcf071bee60d5880948733f8e245fddcc25f655dfe?apiKey=0ea99ad6805e4b00a629c3d17bcd7717&"
                                        className="shrink-0 w-6 aspect-square"
                                        alt={'partner'}
                                    />
                                </div>
                                <InputValidetion msg={errors.phone}/>
                                <div className="mt-6"><TranslateComponent word={'E-mail'}/></div>
                                <div
                                    className="flex flex-col justify-center self-stretch px-3.5 py-2.5 mt-1.5 text-sm leading-6 text-start text-gray-500 bg-white rounded-lg border border-gray-300 border-solid shadow-sm max-md:max-w-full">
                                    <div className="flex gap-2 px-px max-md:flex-wrap">
                                        <input
                                            name="email"
                                            value={inputs.email}
                                            onChange={handelChange}
                                            className="flex-1 max-md:max-w-full"
                                            placeholder={translateText('Please write your email here',translations)}
                                        />
                                        <img
                                            loading="lazy"
                                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/8051127896e8042e4b3b924dc06089860aae755377e1b575f0362fc4957ff07a?apiKey=0ea99ad6805e4b00a629c3d17bcd7717&"
                                            className="shrink-0 my-auto w-5 aspect-square"
                                            alt={'partner'}
                                            />

                                    </div>
                                </div>
                                <InputValidetion msg={errors.email}/>
                                <InputValidetion msg={TrueEmailValidate}/>
                                <div className="mt-6"><TranslateComponent word={'the full address'}/></div>
                                <input
                                    name={'address'}
                                    value={inputs.address}
                                    onChange={handelChange}
                                    className="justify-center self-stretch px-3.5 py-2.5 mt-1.5 text-sm leading-6 text-start text-gray-500 bg-white rounded-lg border border-gray-300 border-solid shadow-sm max-md:max-w-full"
                                    placeholder={translateText('Type address here',translations)}

                                />
                                <InputValidetion msg={errors.address}/>
                                <div className="mt-6"><TranslateComponent word={'Postal code'}/></div>
                                <input
                                    name={'postaCode'}
                                    value={inputs.postaCode}
                                    onChange={handelChange}
                                    className="justify-center self-stretch px-3.5 py-2.5 mt-1.5 text-sm leading-6 text-start text-gray-500 bg-white rounded-lg border border-gray-300 border-solid shadow-sm max-md:max-w-full"
                                    placeholder={translateText('Enter zip code',translations)}

                                />
                                <InputValidetion msg={errors.postaCode}/>
                                <div className="mt-6">{translateText('Type of proposed partnership',translations)}</div>
                                <textarea
                                    name="textContent"
                                    value={inputs.textContent}
                                    onChange={handelChange}
                                    className="justify-center self-stretch px-3.5 pt-2.5 pb-16 mt-1.5 text-sm leading-6 text-start text-gray-500 bg-white rounded-lg border border-gray-300 border-solid shadow-sm max-md:pb-10 max-md:max-w-full">

                                    {translateText('Explain here the ways of joint cooperation',translations)}
                                </textarea>
                                <div className="mt-6">
                                    {translateText('Identification papers',translations)}
                                </div>
                                <div
                                    style={{
                                        backgroundImage: selectedFileShow ? `url(${selectedFileShow})` : 'none',
                                        backgroundColor: selectedFileShow ? 'transparent' : '#f1f5f9'
                                    }}
                                    className="flex flex-col justify-center self-stretch px-3.5 py-2.5 mt-1.5 text-sm font-bold leading-6 text-start text-gray-500 rounded-lg border-2 border-dashed shadow-sm  border-slate-400 max-md:max-w-full">
                                    <div  className="flex justify-center items-center px-16 max-md:px-5 max-md:max-w-full">
                                        <div className="flex gap-2">
                                            <div>
                                                <FileUploader handleFile={handleFileChange} text={translateText('Upload a new file',translations)} />
                                            </div>
                                            <img
                                                loading="lazy"
                                                src="https://cdn.builder.io/api/v1/image/assets/TEMP/f1fd7e53dd4f5bd51bed1aef7945553f511f9e9e3af173d694babe99a6342704?apiKey=0ea99ad6805e4b00a629c3d17bcd7717&"
                                                className="shrink-0 w-6 aspect-square"
                                                alt={'partner'}
                                        />
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-6"><TranslateComponent word={'applicant`s name'}/></div>
                                <input
                                    name={'senderName'}
                                    value={inputs.senderName}
                                    onChange={handelChange}
                                    className="justify-center self-stretch px-3.5 py-2.5 mt-1.5 text-sm leading-6 text-start text-gray-500 bg-white rounded-lg border border-gray-300 border-solid shadow-sm max-md:max-w-full"
                                    placeholder={translateText('Enter zip code',translations)}
                                />
                                <InputValidetion msg={errors.senderName}/>
                                <div className="flex gap-1.5 mt-10">
                                    <input type="checkbox"
                                           name="checkbox"
                                           value={ApprovalCheck}
                                           onChange={hendelChangeCheckBox}
                                           className="shrink-0 self-start px-3.5 py-2.5 bg-white rounded border border-gray-300 border-solid shadow-sm h-[18px] w-[18px]"/>
                                    <div><TranslateComponent word={'Agree on the accuracy of the data provided'}/></div>
                                </div>
                            </div>
                            <div role={'button'}
                                 onClick={handelSubmit}
                                 className="justify-center items-center px-3.5 py-2 mx-8 mt-10 text-sm font-semibold leading-5 text-white whitespace-nowrap bg-blue-600 rounded-lg border border-blue-500 border-solid shadow-sm max-md:px-5 max-md:mr-2.5 max-md:max-w-full">
                                <TranslateComponent word={'Send'}/>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col w-[42%] max-md:ml-0 max-md:w-full">
                        <div className="flex flex-col grow self-stretch pb-20 max-md:mt-5 max-md:max-w-full">
                            <img
                                loading="lazy"
                                srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/500404e69715670eafcdfdbaea6f4c69b5533ee3676c0d150b2d04d7ab30c3aa?apiKey=0ea99ad6805e4b00a629c3d17bcd7717&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/500404e69715670eafcdfdbaea6f4c69b5533ee3676c0d150b2d04d7ab30c3aa?apiKey=0ea99ad6805e4b00a629c3d17bcd7717&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/500404e69715670eafcdfdbaea6f4c69b5533ee3676c0d150b2d04d7ab30c3aa?apiKey=0ea99ad6805e4b00a629c3d17bcd7717&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/500404e69715670eafcdfdbaea6f4c69b5533ee3676c0d150b2d04d7ab30c3aa?apiKey=0ea99ad6805e4b00a629c3d17bcd7717&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/500404e69715670eafcdfdbaea6f4c69b5533ee3676c0d150b2d04d7ab30c3aa?apiKey=0ea99ad6805e4b00a629c3d17bcd7717&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/500404e69715670eafcdfdbaea6f4c69b5533ee3676c0d150b2d04d7ab30c3aa?apiKey=0ea99ad6805e4b00a629c3d17bcd7717&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/500404e69715670eafcdfdbaea6f4c69b5533ee3676c0d150b2d04d7ab30c3aa?apiKey=0ea99ad6805e4b00a629c3d17bcd7717&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/500404e69715670eafcdfdbaea6f4c69b5533ee3676c0d150b2d04d7ab30c3aa?apiKey=0ea99ad6805e4b00a629c3d17bcd7717&"
                                className="w-full aspect-[0.87] max-md:max-w-full"
                                alt={'partner'}
                            />
                            <ContactDetails/>
                     </div>
                    </div>

                </div>
            </div>
        </div>
        </>
    )
}
export default PartnerForm;