import React from 'react'
import {LazyLoadImage} from 'react-lazy-load-image-component'
import {Link} from "react-router-dom";
import {
    AddIcon, AddSmallIcon,
    Arrow, Book,
    Calculator, CalculatorIcon, CalenderIcon,
    CartIcon, CartPlus, DeleteIcon, Eat, Education, EyeScan, FilterIcon,
    GiftIcon,
    GreenTrue,
    HandArrowIcon, HomeIcon, LangIcon, Mosque, RightArrow,
    SearchIcon, SearchIconC, Surgery,
    WebsiteLogo, WhatsAppIcon,

} from "../../../Images/svgs";

const icons = {
    WhatsAppIcon,
    LangIcon,
    Arrow,
    Calculator,
    GiftIcon,
    CartIcon,
    WebsiteLogo,
    SearchIcon,
    HandArrowIcon,
    GreenTrue,
    CartPlus,
    FilterIcon,
    HomeIcon,
    RightArrow,
    CalenderIcon,
    CalculatorIcon,
    DeleteIcon,
    AddIcon,
    SearchIconC,
    AddSmallIcon,
    Book,
    EyeScan,
    Mosque,
    Eat,
    Surgery,
    Education,
};

function AboutCampagin({icon, number, text}) {
    const IconComponent = icons[icon];

    return (
        <>
            <div className="grid grid-rows-1 justify-center p-6 border border-b-2">
                <div className={'mx-auto'}>
                    {IconComponent ? <IconComponent/> : null}
                </div>
                <div className="mt-4">
                    <div className="text-lg leading-7 text-zinc-600">{text}</div>
                    <div className="mt-2 text-3xl tracking-wide leading-7 text-zinc-800">{number}</div>
                </div>
            </div>
        </>
    )
}

export default AboutCampagin