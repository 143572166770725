import React, {useEffect, useState} from "react";
import { LazyLoadImage } from 'react-lazy-load-image-component'
import ReactPlayer from "react-player";

import VideoPopup from "./VideoPopup";
const Videos =  ({
    videos,
    loadData,
    setVideos,
    setLoadData,
    setLoader,
    getVideos,
    activeSection
})=>{

    useEffect(() => {
        setVideos([]);
        getVideos();
        setLoadData(false);
    }, [activeSection]);
    const [show, setShow] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [path,setPath]=useState('');
    const handelChangeStatus =(path)=>{
        if(path){
            setPath(path)
        }
        setShow(!show);
        setShowModal(!showModal);
    }

    const playerRef = React.useRef(null);

    return(
        <div className={'grid grid-cols-1 lg:grid-cols-3 gap-4 my-2'}>
            {videos && videos.map((item, index) => (
                <div key={index} className="col-span-1  h-[300px]"
                role="button"
                onClick={()=>handelChangeStatus(item.attachment)}>

                    <div
                        className="flex overflow-hidden relative flex-col grow justify-center items-center h-[300px] max-md:px-5 max-md:pt-10 max-md:mt-5">

                        <ReactPlayer ref={playerRef} url={item.attachment}  playing={false} controls={false} width={'full'}  className="object-cover absolute rounded-md  w-full inset-0"/>

                        <div
                            className={'mt-4 relative h-[100%] lg:w-[100%] w-[115%] text-white font-bold flex items-end justify-center pb-[30px] rounded-md bg-gradient-to-b from-transparent to-[#000000ab] hover:bg-gradient-to-b hover:from-transparent hover:to-[#051a46]  '}>
                        </div>
                    </div>
                </div>
            ))}

            {showModal &&(
                    <VideoPopup openPopUp={handelChangeStatus} closePopUp={handelChangeStatus} show={show} path={path}/>
             )}
        </div>
    )
}
export default Videos;