import {useNavigate} from "react-router-dom";
import TranslateComponent from "./TranslateComponent";

const AfterPayThanksMsg = () =>{
    const navigate = useNavigate();
    const To =()=>{
        navigate('/');
    }
    return (
            <div
                className="flex flex-col justify-center items-center pb-20 mt-44 max-w-full bg-white rounded-2xl w-[441px] max-md:mt-10">
                <img
                    loading="lazy"
                    srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/c9d515ac75ac81074004639120e6b3465ae73f22ba0948eeeb32a31c11ba79d3?apiKey=0ea99ad6805e4b00a629c3d17bcd7717&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/c9d515ac75ac81074004639120e6b3465ae73f22ba0948eeeb32a31c11ba79d3?apiKey=0ea99ad6805e4b00a629c3d17bcd7717&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/c9d515ac75ac81074004639120e6b3465ae73f22ba0948eeeb32a31c11ba79d3?apiKey=0ea99ad6805e4b00a629c3d17bcd7717&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/c9d515ac75ac81074004639120e6b3465ae73f22ba0948eeeb32a31c11ba79d3?apiKey=0ea99ad6805e4b00a629c3d17bcd7717&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/c9d515ac75ac81074004639120e6b3465ae73f22ba0948eeeb32a31c11ba79d3?apiKey=0ea99ad6805e4b00a629c3d17bcd7717&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/c9d515ac75ac81074004639120e6b3465ae73f22ba0948eeeb32a31c11ba79d3?apiKey=0ea99ad6805e4b00a629c3d17bcd7717&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/c9d515ac75ac81074004639120e6b3465ae73f22ba0948eeeb32a31c11ba79d3?apiKey=0ea99ad6805e4b00a629c3d17bcd7717&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/c9d515ac75ac81074004639120e6b3465ae73f22ba0948eeeb32a31c11ba79d3?apiKey=0ea99ad6805e4b00a629c3d17bcd7717&"
                    className="self-stretch w-full aspect-[1.12] max-md:max-w-full"
                    alt="afterPay"
                />
                <div className="mt-5 text-2xl font-bold text-center text-black">
                    <TranslateComponent word={'Thank you very much for your kind donation'}/>
                    <br/>
                    <span className=""><TranslateComponent word={'Your support is greatly appreciated and appreciated'}/></span>
                </div>
                <div
                    role="button"
                    onClick={To}
                    className="flex justify-center items-center px-3.5 py-2 mt-5 max-w-full text-sm font-semibold leading-5 text-white bg-blue-600 rounded-lg border border-blue-500 border-solid shadow-sm w-[358px] max-md:px-5">
                    <TranslateComponent word={'Browse projects'}/>
                </div>
            </div>
    )
}
export default AfterPayThanksMsg;