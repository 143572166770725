import {useEffect, useState} from "react";
import * as React from "react";
import GetMethods from "../GetMethods";
import {translateText} from "../TranslateComponent";

const PaymentsComponents =  ({timeDesc,newPriceUpdate,symbol,getMethod,type}) =>{

    const {getOnlineMethods,getOfflineMethods}=GetMethods();
    const methods= getOnlineMethods();
    const offlineMethods = getOfflineMethods();
    const [newPrice,setNewPrice]=useState('');
    const [active, setActive]=useState(localStorage.getItem('methodName')??'');
    window.addEventListener('priceChanged',()=>{
        setNewPrice(localStorage.getItem('price'));
    })


    useEffect(() => {

        getMethod(localStorage.getItem('methodName'),localStorage.getItem('methodType'),localStorage.getItem('methodId'))
        setNewPrice(newPriceUpdate!==''?newPriceUpdate:localStorage.getItem('price'))
    }, []);

    return(
        <div className="flex flex-col px-4 py-5 w-full  rounded-lg border border-solid border-black border-opacity-20 mt-2">
            <div className="flex text-3xl font-bold tracking-wide leading-10 text-center text-black">
                {translateText('Donation Details')}
            </div>
            <div className="flex flex-col justify-center p-5 mt-4 rounded-lg border border-solid border-neutral-200">
                <div className="flex gap-1.5 justify-between">
                    <div className="my-auto text-base font-medium text-black ">
                        {translateText('Totla')}
                    </div>

                    <div className="text-xl font-bold text-right text-blue-600">

                        {newPrice??'0'} {symbol?symbol:'$'}
                    </div>
                </div>
            </div>
            <div className="mt-5 text-lg font-medium tracking-wide leading-4 text-right text-black">
                {translateText('Payment Method')}
            </div>

            <div className={`grid  gap-2.5 grid-cols-2`}>
                {methods.map((item,index)=>{
                    return (
                        <>
                        {type==='orphan' ?(
                            item.name==='stripe' &&(

                                <>
                                    <div
                                        key={index}
                                        className="col-span-1  justify-between px-4 py-2.5 mt-4 text-sm font-medium text-white  rounded-lg border border-solid border-stone-300  max-md:flex-wrap max-md:pr-5">
                                        <div className="flex gap-2.5 max-md:flex-wrap">
                                            <input onChange={()=>getMethod(item.name,'online','')} onClick={()=>setActive(item.name)}  id="sms" type="radio" name="methods" className="radio" checked={active === item.name}
                                            />
                                            <img
                                                loading="lazy"
                                                src={item.image}
                                                className="shrink-0 my-auto w-9 aspect-[1.49]"
                                                alt="Pay"
                                            />
                                            <div className="flex-1 max-md:max-w-full  text-black">{item.name}</div>
                                        </div>
                                    </div>
                                </>
                            )


                        ):(
                            <div
                                key={index}
                                className="col-span-1  justify-between px-4 py-2.5 mt-4 text-sm font-medium text-white  rounded-lg border border-solid border-stone-300  max-md:flex-wrap max-md:pr-5">
                                <div className="flex gap-2.5 max-md:flex-wrap">
                                    <input onChange={()=>getMethod(item.name,'online','')} onClick={()=>setActive(item.name)}  id="sms" type="radio" name="methods" className="radio" checked={active === item.name}
                                    />
                                    <img
                                        loading="lazy"
                                        src={item.image}
                                        className="shrink-0 my-auto w-9 aspect-[1.49]"
                                        alt="Pay"
                                    />
                                    <div className="flex-1 max-md:max-w-full  text-black">{item.name}</div>
                                </div>
                            </div>
                        )}
                        </>

                    )
                })}
                {timeDesc==='one_time' && type!=='orphan' &&(
                    <>
                        {offlineMethods.map((item,index)=>{
                            return (
                                <div
                                    key={index}
                                    className="flex gap-2.5 justify-between px-4 py-2.5 mt-4 text-sm font-medium text-white rounded-lg border border-solid border-stone-300 max-md:flex-wrap max-md:pr-5">
                                    <div className="flex gap-2.5 max-md:flex-wrap">
                                        <input  onChange={()=>getMethod(item.name,'offline',item.id)} onClick={()=>setActive(item.name)} id="sms" type="radio" name="methods" className="radio" checked={active === item.name}/>
                                        <img
                                            loading="lazy"
                                            src={item.image}
                                            className="shrink-0 my-auto w-9 aspect-[1.49]"
                                            alt="Pay"
                                        />
                                        <div className="flex-1 max-md:max-w-full text-black">{item.name}</div>
                                    </div>
                                </div>
                            )
                        })}
                    </>
                )}
            </div>
        </div>
    )
}
export default PaymentsComponents