import React, {useRef, useState} from 'react';
import ReactPlayer from "react-player";
import TranslateComponent from '../TranslateComponent';

const VideoSection = ({link}) => {
    const [show, setShow] = useState(false);
    const playerRef = useRef(null);
    const path = "https://www.youtube.com/watch?v=ydMsnLgAkPw&ab_channel=%D8%A7%D9%84%D9%81%D9%86%D8%A7%D9%84%D8%A5%D9%84%D9%87%D9%8A";
    const showModal = () => {
        setShow(!show);
    };
    return (
        <>
            <section className="video">
                <div className="container mx-auto px-2">
                    <div className="row justify-content-center align-items-center gap-2">
                        <div className="col">
                            <div className="video-card">
                                <h2 className="title"><TranslateComponent word={'Watch our video'} /></h2>
                                <h3 className="sub-title"><TranslateComponent word={'The measure of life is not its duration but the amount donated'}/></h3>
                                <button onClick={showModal} className="btn-video">
                                    <i className="fas fa-play"></i>
                                </button>
                                {show && (
                                    // <VideoPopup openPopUp={showModal} closePopUp={showModal} show={show}/>
                                    <div className="overlay" onClick={showModal}>
                                        <div className="player-wrapper">
                                            <button
                                                className={'flex text-gray-400 self-start font-bold hover:text-white'}>X
                                            </button>
                                            <ReactPlayer ref={playerRef} url={link??path} playing={show} controls={true} width={'full'}/>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default VideoSection;
