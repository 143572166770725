import {CartIcon, CartPlus, GreenTrue} from "../../Images/svgs";
import React, { useState} from "react";
import {getCurrencyLocal, getToken} from "../../axios-client";
import {toast} from "react-toastify";
import LocalCart from "../Cart/LocalCart";
import {useDispatch} from "react-redux";
import {fetchCreateCart} from "../Cart/Cart";
import PopupCopmponent from "../PaymentSteps/PopupCopmponent";
import {LazyLoadImage} from "react-lazy-load-image-component";
import TranslateComponent, { translateText } from "../TranslateComponent";
import { useSelector } from 'react-redux';
/**
 * v0 by Vercel.
 * @see https://v0.dev/t/dC3US7g7Smc
 * Documentation: https://v0.dev/docs#integrating-generated-code-into-your-nextjs-app
 */
export default function Slider() {
        const translations = useSelector((state) => state.Storetranslate.translations);
    const [price ,setPrice]=useState('');
    const [showPopup , setShowPopup] = useState(false);
    const handelChangePrice = (e)=>{
        const {value} = e.target;
        setPrice(value);
        setShowPopup(true);
        setType('other');

        localStorage.setItem('price',value);
        dispatchEvent(new Event('priceChanged'));
    }
    const [pricePay,setPricePay]=useState('');
    const [type,setType]=useState('');
    const forPay=(pricePay)=>{
        // eslint-disable-next-line no-mixed-operators
        if(getToken() && getToken()!=='' || getToken() !==null){
            showPopup&&(  document.getElementById('my_modal_4_other').showModal() )
            if(pricePay){
                setPricePay(pricePay)
            }else{
                toast.error(translateText('Please enter the donation amount',translations));
            }
        }else{
            toast.info(translateText('You must be logged in',translations))
        }
    }
    window.addEventListener('removeForPayDirect',()=>{
        setPrice('');
    })
    const {createCart} = LocalCart();
    const dispatch = useDispatch();
    const handelCreateCart = async (type,cart_id,price) =>{
        if(price){
            await dispatch(fetchCreateCart(type, cart_id, price));
        }else{
            toast.error(translateText('Enter the amount to donate',translations))
        }
    }
    const handelCreateCartLocal = (type,cart_id,price,symbol,image)=>{
        if(price){
            createCart(type,cart_id,price,symbol,image);
        }else{
            toast.error(translateText('Enter the amount to donate',translations))
        }
    }
    return (
        <>
            <div className=" mt-10 flex-col gap-5 justify-between items-end  pr-16 pl-4 w-full min-h-[320px] max-md:flex-wrap max-md:pr-5 max-md:max-w-full flex overflow-hidden relative">
                <LazyLoadImage
                    loading="lazy"
                    src={'https://alkhaircharity.org/img/guarantee/page/655f281574e9d.png'}
                    className="object-cover absolute inset-0 w-full h-full rounded-lg "
                    alt="Slider"
                />
                <div className="relative p-2 mt-32 mb-3 md:mt-15  bg-white rounded-lg border border-solid border-zinc-300  w-full md:w-[400px] max-md:w-auto max-md:p-4">
                    <div className="grid grid-cols-1 gap-2 p-2 ">
                        <div
                            className="flex gap-3 justify-between text-xl font-bold text-right whitespace-nowrap text-zinc-900">
                            <div className="my-auto w-6 aspect-square">
                                <GreenTrue/>
                            </div>
                            <div className="grow"><TranslateComponent word={'He made an orphan happy by sponsoring him'} /></div>
                        </div>
                        <div className="relative h-2 my-5">
                            <progress className="progress progress-primary w-full " value="75" max="100"></progress>
                        </div>

                        <div className="flex flex-col">
                            <div
                                className="flex flex-row items-center gap-1 mx-auto my-auto w-full sm:w-full md:w-full">
                                <div className={' rounded-lg border-2 flex'}>
                                    <input
                                        className=" w-full outline-none text-lg indent-1 m-1"
                                        value={price}
                                        type="number" placeholder={translateText('value',translations)}onChange={handelChangePrice}/>


                                    <span
                                        className="flex items-center rounded rounded-l-none border-0 p-1 font-bold text-grey-100">
                                    <button
                                        className="bg-blue-700 text-white text-sm py-2 px-4 rounded"
                                        onClick={() => forPay(price)}>
                                            <TranslateComponent word={'donation'} />
                                    </button>
                                </span>
                                </div>

                                <span className='py-3 px-3 rounded-lg border-2' role="button"
                                      onClick={() => {
                                          if (getToken()) {
                                              handelCreateCart('other', 'orphan', price);
                                          } else {
                                              handelCreateCartLocal('other', 'orphan', price, getCurrencyLocal().name, '');
                                          }
                                      }}
                                >
                               <CartIcon/>
                            </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {showPopup && (
                <PopupCopmponent type={type} setShowPopup={setShowPopup}
                                 projectId={'orphan'} price={pricePay}
                                 symbol={getCurrencyLocal().name}
                                 handelChangePrice={handelChangePrice}/>
            )}
        </>);

}
