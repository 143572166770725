import {useEffect} from "react";
import GetMethods from "./Components/GetMethods";
import {useDispatch, useSelector} from "react-redux";
import {fetchCountries} from "./Managment/Countires";
import {setSelectedValue} from "./Managment/Slice";
import {getCurrencyLocal, getLocalLang} from "./axios-client";
import {fetchCurrency} from "./Managment/Currencies";
import {fetchLang} from "./Managment/Lang";
import { fetchTranslations } from "./Managment/Translate";
import { featchHeaders } from "./Managment/Header";

const FirstData = () =>{
    const { getMethods } = GetMethods();
    const dispatch = useDispatch();
    const LocalCurrency = getCurrencyLocal();
    const LocalLang = getLocalLang();

    const selectedValues = useSelector(state => state.dropdown.selectedValues);
    const language = useSelector(state => state.Storelang.langs);
    const currencies = useSelector(state=>state.StoreCurrency.currencies);

    useEffect(() => {
        dispatch(fetchTranslations());
        dispatch(featchHeaders)
        dispatch(fetchCountries());
        getMethods();
    }, []);

    useEffect(() => {
        if(!language.length > 0){
            dispatch(fetchLang)
        }
        if(!currencies.length > 0){
            dispatch(fetchCurrency);
        }
    }, [language,currencies]);

    useEffect(() => {
        if(!LocalCurrency){
            if (currencies.length > 0) {
                dispatch(setSelectedValue({ index:0, value:currencies[0]?.name, code_id:currencies[0]?.id, type:'currency',LangIcon_CurValue:currencies[0]?.value,code:currencies[0]?.code}));
            }else if (selectedValues.length === 0 && currencies.length === 0) {
                dispatch(setSelectedValue({ index:0, value:'USD' , code_id:'1', type:'currency',LangIcon_CurValue:'1',code:'USD'}));
            }
        }else{
            if ((getCurrencyLocal().name==='USD')) {
                dispatch(setSelectedValue({ index:0, value:currencies[0]?.name,code_id:currencies[0]?.id, type:'currency',LangIcon_CurValue:currencies[0]?.value,code:currencies[0]?.code}));
            }else{
                dispatch(setSelectedValue({ index:0, value:getCurrencyLocal().name , code_id:getCurrencyLocal().id, type:'currency' ,LangIcon_CurValue:getCurrencyLocal().value,code:getCurrencyLocal().code}));
            }
        }
    }, [currencies]);

    useEffect(()=>{
        if (!LocalLang) {
            console.log(language);
            if (language.length > 0) {
                dispatch(setSelectedValue({
                    index: 0,
                    value: language[0]?.name,
                    type: 'lang',
                    code_id: language[0]?.lang,
                    LangIcon_CurValue:language[0]?.icon
                }));
            } else if (selectedValues.length === 0 && language.length === 0) {
                dispatch(setSelectedValue({index: 0, value: 'عربي', type: 'lang', code_id: 'ar',LangIcon_CurValue:''}));
            }
        }else{
            if(getLocalLang()&& getLocalLang().name==='عربي'){
                dispatch(setSelectedValue({
                    index: 0,
                    value: language[0]?.name,
                    type: 'lang',
                    code_id: language[0]?.lang,
                    LangIcon_CurValue:language[0]?.icon
                }));
            }else{
                dispatch(setSelectedValue({index: 0, value: getLocalLang().name, type: 'lang', code_id: getLocalLang().code,LangIcon_CurValue:getLocalLang().icon}));
            }
            // if (language.length > 0) {
            //     dispatch(setSelectedValue({
            //         index: 0,
            //         value: language[0]?.name,
            //         type: 'lang',
            //         code_id: language[0]?.lang,
            //         LangIcon_CurValue:language[0]?.icon
            //     }));
            // }else{
            //    dispatch(setSelectedValue({index: 0, value: getLocalLang().name, type: 'lang', code_id: getLocalLang().code,LangIcon_CurValue:getLocalLang().icon}));
            // }
        }
    },[language])
}
export default FirstData;