import axiosClient from "../../axios-client";
import { useEffect, useState } from "react";

const Category = ({
      categories,
      setCategories,
      selectedCategory,
      setSelectedCategory,
  }) => {

    const [loader,setLoader]=useState(false);
    const getCategories = () => {
        axiosClient.get('projects-categories')
            .then(response => {
                setCategories(response.data.data);
                setLoader(false);
            })
            .catch(error => {
                console.log(error);
                setLoader(false);
            });
    };

    useEffect(() => {
        setLoader(true)
        getCategories();
    }, []);

    const handleSelected = (categoryId) => {
        setSelectedCategory(prevData => {
            if (prevData.includes(categoryId)) {
                return prevData.filter(item => item !== categoryId);
            } else {
                return [...prevData, categoryId];
            }
        });
    };

    return (
        <div role="group" className="grid grid-cols-1 h-[200px] overflow-auto">
            <div className="col-span-1">
                {loader?(
                    <div className="my-16">
                        <span className="loading loading-bars loading-lg"></span>
                    </div>
                ) : (
                    categories.map((item, index) => (
                        <button
                                className={`btn mx-2 my-2 ${selectedCategory.includes(item.id) ? 'bg-blue-600 text-white' : ''}`}
                                onClick={() => handleSelected(item.id)}
                                key={index}
                            >
                                {item.name}
                            </button>
                    ))
                )}
            </div>
        </div>
    );
};

export default Category;
