import React, {useEffect, useRef, useState} from "react";
import axiosClient from "../../axios-client";
import {toast} from "react-toastify";
import InputValidetion from "../InputValidetion";
import ContactDetails from "../ContactDetails";
import TranslateComponent, { translateText } from "../TranslateComponent";
import { useSelector } from 'react-redux';
import SEOComponent from "../SEOComponent";

const ContactForm = () =>{
    const translations = useSelector((state) => state.Storetranslate.translations);

    const timeoutId = useRef(null);
    const [TrueEmailValidate,setTrueEmailValidate]=useState('')

    const [inputs,setInputs]=useState({
        messageContent:"",
        name:"",
        email:""
    })
    const [errors, setErrors] = useState({
        messageContent:"",
        name:"",
        email:""
    });
    const handelChange = (e) => {
        const { name, value } = e.target;
        if (timeoutId.current) {
            clearTimeout(timeoutId.current);
        }
        timeoutId.current = setTimeout(() => {
            if (name === 'email') {
                if (value === '') {
                    setTrueEmailValidate('');
                } else if (!value.includes('@')) {
                    setTrueEmailValidate(translateText('Complete the email correctly: @',translations));
                } else {
                    setTrueEmailValidate('');
                }
            }
        }, 500);

        setInputs(prevData => ({
            ...prevData,
            [name]: value
        }));
        if (value.trim()) {
            setErrors(prevErrors => ({
                ...prevErrors,
                [name]: ""
            }));
        }
    }


    const containerWidth = () => {
        const navContainers = document.querySelectorAll('.nav-container');
        navContainers.forEach((navContainer) => {
            navContainer.classList.remove('container', 'max-w-7xl', 'mx-auto','px-2');
        });
    };

    useEffect(() => {
        containerWidth()
    },[])

    const handelSubmit=()=>{
        if(Object.values(inputs).every(input => input !== "")) {
            if (inputs.email.includes('@')){
                axiosClient.post('/message',{
                    name:inputs.name,
                    email:inputs.email,
                    message:inputs.messageContent
                })
                    .then(response=>{
                        toast.success(translateText('Sent successfully',translations));
                    })
                    .catch(error=>{
                        if(error?.response?.data?.message){
                            toast.error(translateText(error?.response?.data?.message,translations));
                        }
                        console.log(error);
                    })
            }else {
                toast.error(translateText('please Inter Regit Email',translations))

            }
        }else{
            const newErrors = {};
            Object.entries(inputs).forEach(([name, value]) => {
                if (!value.trim()) {
                    newErrors[name] = translateText("Field is required",translations);
                } else {
                    newErrors[name] = "";
                }
            });
            setErrors(newErrors);
        }
    }

    return (
        <>

            <SEOComponent
                title={'Conact Form'}
            />
        <div className=" container max-w-7xl mx-auto px-2 ">
            <div className="flex flex-col my-[5rem]">
            <div className="self-start text-2xl md:text-5xl font-bold text-start text-zinc-900">
               <TranslateComponent word={'Have a question?'}/>
            </div>
            <div className="mt-8 w-full max-md:max-w-full">
                <div className="flex gap-5 max-md:flex-col max-md:gap-0">
                    <div className="flex flex-col ml-5 w-[58%] max-md:ml-0 max-md:w-full">
                        <div
                            className="flex flex-col grow p-8 w-full text-base font-medium leading-5 text-black bg-white rounded-xl border border-solid border-neutral-200 max-md:px-5 max-md:mt-5 max-md:max-w-full">
                            <div className="text-xl font-bold text-start text-black max-md:max-w-full">
                               <TranslateComponent word={'Contact form'}/>
                            </div>
                            <div className="self-start mt-5">
                                <TranslateComponent word={'Message text'}/>
                                <span className="text-black"> *</span>
                            </div>
                            <textarea
                                name={'messageContent'}
                                onChange={handelChange}
                                value={inputs.messageContent}
                                required={true}
                                className="justify-center items-end px-3.5 pt-2.5 pb-56 mt-1.5 text-sm leading-5 text-start bg-white rounded-lg border border-gray-300 border-solid shadow-sm text-black max-md:pb-10 max-md:pl-5 max-md:max-w-full">
                            </textarea>
                            <InputValidetion msg={errors.messageContent}/>
                            <div className="self-start mt-5">
                                <TranslateComponent word={'name'}/>
                                <span className="text-black"> *</span>
                            </div>
                            <input
                                type={'text'}
                                placeholder={translateText('Enter name here',translations)}
                                name={'name'}
                                onChange={handelChange}
                                value={inputs.name}
                                className="justify-center items-end px-3.5 py-2.5 mt-1.5 text-sm leading-5 text-start bg-white rounded-lg border border-gray-300 border-solid shadow-sm text-black max-md:pl-5 max-md:max-w-full"/>
                            <InputValidetion msg={errors.name}/>
                            <div className="self-start mt-5">
                                <TranslateComponent word={'E-mail'}/>
                                <span className="text-black"> *</span>
                            </div>
                            <input
                                type={'email'}
                                name={'email'}
                                onChange={handelChange}
                                value={inputs.email}
                                required={true}
                                placeholder={translateText('Email here',translations)}
                                className="justify-center items-end px-3.5 py-2.5 mt-1.5 text-sm leading-5 text-start bg-white text-black rounded-lg border border-gray-300 border-solid shadow-sm  max-md:pl-5 max-md:max-w-full"/>
                            <InputValidetion msg={errors.email}/>
                            <InputValidetion msg={TrueEmailValidate}/>
                            <div
                                role="button"
                                onClick={handelSubmit}
                                className="justify-center self-start px-5 py-2.5 mt-5 text-base font-bold text-center text-white whitespace-nowrap bg-blue-900 rounded-lg shadow-sm">
                                <TranslateComponent word={'Send'}/>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col w-[42%] max-md:ml-0 max-md:w-full">
                        <div className="flex flex-col max-md:mt-5 max-md:max-w-full">
                            <ContactDetails/>
                        </div>
                    </div>

                </div>
            </div>


        </div>
        </div>
    <iframe style={{width: '100%', height: 400, marginTop: 30}}
            src="https://www.openstreetmap.org/export/embed.html?bbox=32.71179199218751%2C30.03105542654023%2C37.8533935546875%2C33.224903086263964&amp;layer=cyclosm"></iframe>
        </>
)
}
export default ContactForm;