import React, {useEffect, useRef, useState} from 'react';
import HamleComponent from "../HamleComponent";
import {Arrow} from "../../Images/svgs";
import axiosClient from "../../axios-client";
import {toast} from "react-toastify";
import TranslateComponent, { translateText } from "../TranslateComponent";
import { useSelector } from 'react-redux';
const AytemComponent = () => {
    const translations = useSelector((state) => state.Storetranslate.translations);
    const [countries, setCountries] = useState([]);
    const [filteredCountries, setFilteredCountries] = useState([]);
    const [selectedCountryChose, setSelectedCountryChose] = useState({
        name: translateText('select country',translations),
        image: null,
        id: null
    });
    const getCountries = async () => {
        try {
            const response = await axiosClient.get("/countries");
            const data = response.data.data;
            setCountries(data);
            setFilteredCountries(data);
        } catch (error) {
            console.error("Error fetching countries:", error);
        }
    };
    const [isOpenCountryChose, setIsOpenCountryChose] = useState(false);
    const [searchQueryCountry, setSearchQueryCountry] = useState('');
    const countryChoseWrapperRef = useRef(null);
    const[orphans , setOrohans]=useState([]);
    const [page,setpage]=useState(1);
    const [loading,setLoader]=useState(false);
    const [pageTotal , setPageTotal]=useState(0);
    const handleCountrySearch = (e) => {
        const query = e.target.value;
        setSearchQueryCountry(query);
        const filtered = countries.filter(country => country.name.toLowerCase().includes(query.toLowerCase()));
        setFilteredCountries(filtered);
    };
    const toggleCountryChoseDropdown = () => setIsOpenCountryChose(!isOpenCountryChose);
    useEffect(() => {
        getCountries();

    }, []);
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (countryChoseWrapperRef.current && !countryChoseWrapperRef.current.contains(event.target)) {
                setIsOpenCountryChose(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, []);
    const handleCountrySelectionChose = async (country) => {
        setSelectedCountryChose(country);
        setIsOpenCountryChose(false);
        setSearchQueryCountry('');
        setFilteredCountries(countries);
    };
    const [filterData , setFilterData]=useState({
        orphan: false,
        patients: false,
        holyQuran: false
    });
    const handelChange = (e) => {
        const { name, checked } = e.target;
        setFilterData(prevData => ({
            ...prevData,
            [name]: checked
        }));
    };
     const getOrphan = (countryId,holyQuran,orphan,patients,page=1)=>{
        setOrohans([]);
         setLoader(true);
        axiosClient.get('/orphans',{
            params:{
                page:page,
                country_id:countryId??'',
                quran:holyQuran??'',
                orphan:orphan??'',
                isDisabled:patients??'',
            }
        })
            .then(response=>{
                setLoader(false);
                setPageTotal(response.data.data.pagination.total_pages)
                setOrohans(prevData=>[...prevData,...response.data.data.data]);
            })
            .catch(error=>{
                if(error?.response?.data?.message){
                    toast.error(translateText(error?.response?.data?.message,translations))
                }
                setLoader(false);
            })
    }
    useEffect(()=>{
        setOrohans([]);
        getOrphan();
    },[])
    const handelFilter=(countryId,holyQuran,orphan,patients)=>{
        // console.log(countryId,holyQuran,orphan,patients);
        getOrphan(countryId,holyQuran,orphan,patients)
    }
    const handelShowMore=()=>{
         const nextPage =page+1
         setpage(nextPage);
        getOrphan(selectedCountryChose.id,filterData,nextPage)
    }

    const handelShowSome=()=>{
        const nextPage =1
        setpage(nextPage);
        getOrphan(selectedCountryChose.id,filterData,nextPage)
    }
    return (
        <section className="aytam-list mt-[5rem] container mx-auto py-5 px-2 mb-6 ">

            <div className={'grid grid-cols-1 '}>
                <div className={'card bg-white'}>
                    <div className="filters justify-center flex my-5">
                        <div className="form-group ">
                            <div className="form-check flex items-center">
                                <input
                                    className="checkbox"
                                    type="checkbox"
                                    checked={filterData.orphan}
                                    onChange={handelChange}
                                    name="orphan"
                                    id="orphan"
                                />
                                <label
                                    className="form-check-label"
                                    htmlFor="orphan"
                                ><TranslateComponent word={'Orphan of father and mother'}/></label>
                            </div>
                        </div>
                        <div className="form-group ">
                            <div className="form-check flex items-center">
                                <input
                                    className="checkbox"
                                    type="checkbox"
                                    checked={filterData.patients}
                                    onChange={handelChange}
                                    name="patients"
                                    id="patients"
                                />
                                <label className="form-check-label" htmlFor="patients">
                                    <TranslateComponent word={'People with special disabilities and patients'}/>
                                </label>
                            </div>
                        </div>
                        <div className="form-group ">
                            <div className="form-check flex items-center">
                                <input
                                    className="checkbox"
                                    type="checkbox"
                                    checked={filterData.holyQuran}
                                    onChange={handelChange}
                                    name="holyQuran"
                                    id="HolyQuran"
                                />
                                <label className="form-check-label" htmlFor="HolyQuran"><TranslateComponent word={'Hafiz of the Holy Quran'}/></label>
                            </div>
                        </div>
                        <div className="relative w-[25%]  " ref={countryChoseWrapperRef}>
                            <div
                                className="flex items-center select select-bordered w-full max-w-xs"
                                onClick={toggleCountryChoseDropdown}>
                                {selectedCountryChose.image ? (
                                    <img
                                        loading="lazy"
                                        src={selectedCountryChose.image}
                                        className="shrink-0 self-stretch aspect-square w-[30px] mx-2 object-contain mask mask-circle"
                                        alt={'aytam'}
                                    />
                                ) : ('')}

                                <span className='font-bold text-black pt-1'>{selectedCountryChose.name}</span>
                            </div>
                            {isOpenCountryChose && (
                                <div
                                    className="absolute mt-1 bg-white border rounded-lg shadow-sm max-h-44 z-30 w-full overflow-y-scroll overflow-x-hidden">
                                    <input
                                        type="text"
                                        value={searchQueryCountry}
                                        onChange={handleCountrySearch}
                                        placeholder="Search Country..."
                                        className="w-full p-2 border-b focus:outline-none"
                                    />
                                    {filteredCountries.map((country) => (
                                        <div key={country.id}
                                             className="flex gap-2 items-center p-2 hover:bg-gray-100 cursor-pointer"
                                             onClick={() => handleCountrySelectionChose(country)}
                                        >
                                            <img src={country.image} alt={country.name}
                                                 className="w-5 aspect-square mr-2 rounded-lg"/>
                                            <span className='font-bold text-black'>{country.name}</span>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                        <div className="form-group">
                            <button type="button" onClick={()=>handelFilter(selectedCountryChose.id,filterData.holyQuran,filterData.orphan,filterData.patients)}
                                    className="btn btn-submit bg-blue-500 text-white p-2 rounded-md flex items-center justify-center">
                                <span className="spinner-border spinner-border-sm hidden mr-2"><TranslateComponent word={''}/></span>
                                <span className="text pt-1 flex items-center">
                                    <svg width="20" height="20" viewBox="0 0 24 24" fill="none"
                                         xmlns="http://www.w3.org/2000/svg" className="mr-2">
                                        <path
                                            d="M21 11.5C21 13.3789 20.4428 15.2156 19.399 16.7779C18.3551 18.3402 16.8714 19.5578 15.1355 20.2769C13.3996 20.9959 11.4895 21.184 9.64665 20.8175C7.80383 20.4509 6.11109 19.5461 4.78249 18.2175C3.45389 16.8889 2.5491 15.1962 2.18254 13.3534C1.81598 11.5105 2.00412 9.60041 2.72315 7.86451C3.44218 6.12861 4.65982 4.64491 6.22209 3.60104C7.78435 2.55717 9.62108 2 11.5 2C14.0196 2 16.4359 3.00089 18.2175 4.78249C19.9991 6.56408 21 8.98044 21 11.5V11.5Z"
                                            stroke="#fff" strokeWidth="1.5" strokeLinecap="round"
                                            strokeLinejoin="round"><TranslateComponent word={''}/></path>
                                        <path d="M22 22L20 20" stroke="#fff" strokeWidth="1.5" strokeLinecap="round"
                                              strokeLinejoin="round"><TranslateComponent word={''}/></path>
                                    </svg>
                                   <TranslateComponent word={'Search now'}/>
                               </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>


            <div className=" grid grid-cols-1 lg:grid-cols-4 " id="aytam-list">
                {orphans && orphans.map((item, index) => (
                    <HamleComponent orphan={item} index={index}/>
                ))}
            </div>
            <div className="flex justify-center">
                {loading ? (
                    <div className="flex justify-center items-center">
                        <span className="text-black loading loading-dots loading-lg"><TranslateComponent word={''}/></span>
                    </div>
                ) : (
                    <>
                        <div>
                            {orphans && orphans.length > 0 && page < pageTotal && (
                                <div className="text-center">
                                    <button className="border rounded-lg px-5 py-3 mx-4"
                                            onClick={handelShowMore}>
                                       <TranslateComponent word={'Show more'}/>
                                    </button>
                                </div>
                            )}
                        </div>
                        <div>
                            {page > 1 && (
                                <div className="text-center">
                                    <button className="border rounded-lg px-5 py-3" onClick={handelShowSome}>
                                       <TranslateComponent word={'Show a little'}/>
                                    </button>
                                </div>
                            )}
                        </div>
                    </>


                )}
            </div>


        </section>
    );
}

export default AytemComponent;
