import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosClient from "../../axios-client";
import { NotificationIcon } from "../../Images/svgs";
import $ from "jquery";
import TranslateComponent from '../TranslateComponent';

function NotificationComponent() {
    const [notifications, setNotifications] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);
    const navigate = useNavigate();

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        if (isOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen]);

    useEffect(() => {
        axiosClient.get('/notifications')
            .then(response => {
                if (response.data.status) {
                    setNotifications(response.data.data);
                }
            })
            .catch(error => {
                console.error("Error fetching notifications:", error);
            });
    }, []);

    const handleNotificationClick = (notification) => {
        axiosClient.post('/notification/read', { id: notification.id })
            .then(response => {
                if (response.data.status) {

                    $(`#notification-${notification.i}`).remove()
                    setNotifications(notifications.map(n => n.id === notification.id ? { ...n, read_at: new Date() } : n));
                    navigate(notification.data.url);
                } else {

                }
            })
            .catch(error => {
                console.error("Error marking notification as read:", error);
            });
    };

    return (
        <div className="relative" ref={dropdownRef}>
            <button data-ripple-light="true" data-popover-target="menu" onClick={handleToggle}>
                <NotificationIcon />
            </button>

            {isOpen && (
                <ul role="menu" data-popover="menu" data-popover-placement="bottom"
                    className="absolute z-10 md:min-w-[300px] min-w-[180px] overflow-auto rounded-md border border-blue-gray-50 bg-white p-3 font-sans text-sm font-normal text-blue-gray-500 shadow-lg shadow-blue-gray-500/10 focus:outline-none"
                    style={{ top: '100%', left: 0 }}
                >
                    {notifications.length > 0 ? (
                        notifications.map(notification => (
                            <li key={notification.id} role="menuitem" id={`notification-${notification.i}`}
                                className="block w-full border-1 border my-1 shadow-lg cursor-pointer select-none rounded-md px-3 pt-[9px] pb-2 text-start leading-tight transition-all hover:bg-blue-gray-50 hover:bg-opacity-80 hover:text-blue-gray-900 focus:bg-blue-gray-50 focus:bg-opacity-80 focus:text-blue-gray-900 active:bg-blue-gray-50 active:bg-opacity-80 active:text-blue-gray-900"
                                onClick={() => handleNotificationClick(notification)}
                            >
                                {notification.data.message}
                            </li>
                        ))
                    ) : (
                        <li role="menuitem" className="text-center p-3 text-gray-500">
                           <TranslateComponent word={'Not Found'}/>
                        </li>
                    )}
                </ul>
            )}

        </div>
    );
}

export default NotificationComponent;
